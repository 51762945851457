import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { Text } from '@fielded/shared-ui'
import { Arrow } from '@fielded/shared-ui/src/icons'

import { formatDate } from '../../utils'
import {
  FINANCE_BASE_URL,
  FINANCE_BASE_URL_WITH_TRANSACTIONS_HISTORY,
  OLD_FINANCE_BASE_URL,
  PRICE_TEXT,
  PRICE_TEXT_TO_DISPLAY,
  TO_WALLET_NAVIGATION,
  linkCopy
} from './utils'

const NotificationItem = ({
  notification
}) => {
  const isRead = notification.readAt
  const dateCreated = formatDate(notification.createdAt, 'shortWithTimeInverted')
  let link = notification.action

  if (link === OLD_FINANCE_BASE_URL) {
    link = FINANCE_BASE_URL
  }

  if (notification.code === TO_WALLET_NAVIGATION.CASHBACK_EARNED) {
    link = `${FINANCE_BASE_URL_WITH_TRANSACTIONS_HISTORY}?id=${notification.event}`
  }

  if (notification.notificationText === PRICE_TEXT) {
    notification.notificationText = PRICE_TEXT_TO_DISPLAY
  }

  return (
    <div className={classnames(
      'vs-notification-item',
      {'vs-notification-item--read': isRead}
    )}>
      <Text bold>{notification.notificationText}</Text>
      {notification.action &&
        <Link
          to={link}
          className={classnames(
            'vs-u-link',
            'vs-u-link--bold',
            'vs-u-link--small',
            'vs-u-link--with-icon',
            {'vs-u-link--brand': !isRead},
            {'vs-u-link--dark': isRead}
          )}
        >
          {linkCopy(notification.code)} <Arrow direction='right' className='vs-u-link__icon' />
        </Link>
      }
      <Text
        className='vs-notification-item__date'
        uppercase
        size='xsmall'
      >
        {dateCreated}
      </Text>
    </div>
  )
}

export default NotificationItem
