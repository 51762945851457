const { PSM_WAREHOUSE_DEFINITIONS } = require('./order/tools/read/warehouse-code-to-virtual')
const get = require('lodash/get')
const groupBy = require('lodash/groupBy')

module.exports = async (state, { batchApi, funderApi, configurationApi, locationApi, funderId, programId }) => {
  const locationId = get(state.user, 'location.id', '')
  const warehouseDefinition = Object.values(PSM_WAREHOUSE_DEFINITIONS).find(w => w._id === locationId)
  let warehouseCode

  if (!warehouseDefinition) {
    const location = await locationApi.get(locationId)
    warehouseCode = get(location, 'additionalData.warehouseCode')
    if (!warehouseCode) {
      return {
        type: 'error',
        status: 404,
        message: 'Warehouse code not found'
      }
    }
  }

  const userFunders = get(state.user, 'funders', [])
  const userPrograms = get(state.user, 'programs', [])
  if (!userFunders.includes(funderId)) {
    return {
      type: 'error',
      status: 400,
      message: 'Selected funder not allocated to warehouse user'
    }
  }

  if (!userPrograms.includes(programId)) {
    return {
      type: 'error',
      status: 400,
      message: 'Selected program not allocated to warehouse user'
    }
  }

  let funder
  try {
    funder = await funderApi.get(funderId)
  } catch (e) {
    if (e.status === 404) {
      return {
        type: 'error',
        status: 404,
        message: 'Funder not found'
      }
    }
    throw e
  }

  const funderPrograms = get(funder, 'programs', {})
  if (!Object.keys(funderPrograms).includes(programId)) {
    return {
      type: 'error',
      status: 400,
      message: 'Selected program not assigned to given funder'
    }
  }
  const serviceId = `${programId}:service:warehouse`
  const configurationId = `configuration:${serviceId}`
  const [configDoc] = await configurationApi.getByIds([configurationId], { includeProducts: true })

  if (!configDoc) {
    return {
      type: 'error',
      status: 404,
      message: 'Configuration doc not found for warehouse service'
    }
  }

  const nonExpiredProductBatches = await batchApi.getNonExpired(configDoc.products.map(p => p._id))
  if (!nonExpiredProductBatches.length) {
    return {
      type: 'error',
      status: 404,
      message: 'No non expired product batches found for warehouse'
    }
  }

  const productsById = groupBy(configDoc.products, '_id')
  return {
    data: nonExpiredProductBatches.map(batch => {
      const productId = get(batch, '_id', '').split(':manufacturer')[0]
      return {
        productName: get(productsById[productId], `0.fullName`, ''),
        productId,
        batchId: batch._id,
        quantity: '',
        funderId,
        programId,
        warehouseCode: (warehouseDefinition && warehouseDefinition.warehouseCode) || warehouseCode
      }
    }),
    serviceId
  }
}
