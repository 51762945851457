const { bulkSnapshotsFromCsv } = require('./shipment-bulk-snapshot-from-allocation-sheet')
module.exports = shipmentUpload

async function shipmentUpload (state, {
  funderShipments,
  routes,
  routesApi,
  shipmentApi
}) {
  await routesApi.create({ routeData: routes })

  const allSnapshots = []
  for (const shipment of Object.values(funderShipments)) {
    const snapshots = await bulkSnapshotsFromCsv(state, shipment)
    const advanceSnapshots = await shipmentApi.bulkAdvanceSnapshots({ shipments: snapshots })
    allSnapshots.push(...advanceSnapshots)
  }
  return allSnapshots
}
