import React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'

import getApplicationConfig from '../../../common/config'

function withConfig (Component, hrefValue = window.location.href) {
  function Wrapper ({innerRef, ...props}, context) {
    return (
      <Component
        {...props}
        ref={innerRef}
        config={getApplicationConfig(hrefValue)}
      />
    )
  }
  Wrapper.displayName = `withConfig(${Component.displayName || Component.name})`
  Wrapper.WrappedComponent = Component
  return hoistNonReactStatics(Wrapper, Component)
}

export default withConfig
