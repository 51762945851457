const PGAdapter = require('../../common/pg-adapter')

const DATABASE = 'avocado.data_organisation'
const COLUMNS = [
  'id',
  'name',
  'phone_number',
  'email_addresses',
  'country',
  'state',
  'lga',
  'city',
  'street',
  'created_at',
  'updated_at'
]

class OrganisationPGAdapter extends PGAdapter {
  constructor (logger, pgConnection, username) {
    super(
      pgConnection,
      DATABASE,
      username,
      COLUMNS,
      'id',
      {},
      logger
    )
  }

  async getList () {
    const { results } = await super.getList()
    return results
  }
}

module.exports = OrganisationPGAdapter
