import React from 'react'

import { StatusCard, Text } from '@fielded/shared-ui'
import { SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS, SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS_FOR_DRIVER } from '@fielded/fs-api/lib/shipment/constants'

import { formatDate, pluralize } from '../../../../../../van-shared/utils/utils'

const PSMDeliveryCard = ({
  target,
  history,
  headerTitle,
  shipment,
  programName,
  statusText,
  children,
  isDriver = true
}) => {
  const {
    date,
    counts,
    status
  } = shipment

  const formattedDate = formatDate(date, 'long')
  const productCount = Object.keys(counts).length

  return (
    <section className='vs-u-align-center delivery-card__wrapper'>
      {children}

      <StatusCard
        className='shipment delivery-card__status-card'
        target={target}
        history={history}
      >
        <StatusCard.Label
          className={`delivery-card__status--${status}`}
          statusText={statusText || (isDriver ? SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS_FOR_DRIVER : SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS)[status]}
        />

        <StatusCard.Content className='delivery-card__header-box'>
          <Text size='large'>{formattedDate}</Text>
          <Text size='large' bold>{headerTitle}</Text>
        </StatusCard.Content>

        <StatusCard.Footer className='delivery-card__footer' boldLink>
          <section>
            <Text size='small'>Products:</Text>
            <Text size='large' bold>{productCount} {pluralize('product', productCount)}</Text>
            <Text size='small' bold>ID : {shipment.shipmentNo}</Text>
            {programName && <Text size='small' bold>Program : {programName}</Text>}
          </section>
        </StatusCard.Footer>
      </StatusCard>
    </section>
  )
}

export default PSMDeliveryCard
