const PGAdapter = require('../../common/pg-adapter')
const OrganisationPGAdapter = require('../../organisation/data-access/organisation-pg-adapter')
const FleetCategoryPGAdapter = require('./fleet-pg-adapter')
const OperationalStatePGAdapter = require('./operationalstate-pg-adapter')
const { DATABASE, COLUMNS, SQL } = require('./constants')

class CarrierPGAdapter extends PGAdapter {
  constructor (logger, pgConnection, username) {
    super(
      pgConnection,
      `${DATABASE.SCHEMA}.${DATABASE.TABLES.CARRIER}`,
      username,
      COLUMNS.carrier,
      'organisation_id',
      {},
      logger
    )
    this.logger = logger
    this.organisation = new OrganisationPGAdapter(logger, pgConnection, username)
    this.fleetCategory = new FleetCategoryPGAdapter(logger, pgConnection, username)
    this.operationalState = new OperationalStatePGAdapter(logger, pgConnection, username)
  }

  async create (data) {
    const client = await this.pgConnection
    try {
      await client.query('BEGIN')
      const { organisation, fleetCategories, operationalStates } = data

      // Create organisation
      const org = await this.organisation.create(organisation)
      const dates = {
        created_at: org.created_at,
        updated_at: org.updated_at
      }

      // Create carrier
      const carrier = await super.create({ organisation_id: org.id })

      // Create fleet categories
      if (fleetCategories && fleetCategories.length) {
        for (const fleet of fleetCategories) {
          await this.fleetCategory.create(carrier.organisation_id, fleet, dates, client)
        }
      }

      // Create operational state
      if (operationalStates && operationalStates.length) {
        for (const opState of operationalStates) {
          await this.operationalState.create(
            carrier.organisation_id,
            opState,
            dates,
            client
          )
        }
      }

      await client.query('COMMIT')
      return this.getOne(org.id)
    } catch (error) {
      await client.query('ROLLBACK')
      throw error
    }
  }

  async getOne (id) {
    const { rows } = await this.pgConnection.query(SQL.GET_CARRIER, [id])
    return rows[0] ? rows[0].data : null
  }

  async getList () {
    const { rows } = await this.pgConnection.query(SQL.LIST_CARRIER)
    const result = rows.map(row => row.data)
    return result[0]
  }

  async update (data) {
    const client = await this.pgConnection
    try {
      await client.query('BEGIN')
      const { organisation, fleetCategories, operationalStates } = data

      this.applyUpdateDefaults(organisation) // Mutation warning!!!!!

      const id = organisation.id
      const org = await this.organisation.update({
        ...organisation,
        email_addresses: JSON.stringify(organisation.email_addresses)
      })

      const dates = {
        created_at: org.created_at,
        updated_at: org.updated_at
      }

      // Update fleet categories
      if (fleetCategories && fleetCategories.length) {
        await this.fleetCategory.update(org.id, fleetCategories, dates, client)
      }

      // Update operational state
      if (operationalStates && operationalStates.length) {
        await this.operationalState.delete(org.id, client)
        for (const opState of operationalStates) {
          await this.operationalState.create(org.id, opState, dates, client)
        }
      }

      await client.query('COMMIT')
      return this.getOne(id)
    } catch (error) {
      await client.query('ROLLBACK')
      throw error
    }
  }
}

module.exports = CarrierPGAdapter
