export const COLUMNS = [
  {
    key: 'destinationWarehouseCode',
    header: 'destinationWarehouseCode',
    dataKey: 'destinationWarehouseCode',
    disableSort: true
  },
  {
    key: 'product',
    header: 'product',
    dataKey: 'product',
    disableSort: true
  },
  {
    key: 'quantity',
    header: 'quantity',
    dataKey: 'quantity',
    disableSort: true
  },
  {
    key: 'originWarehouseCode',
    header: 'originWarehouseCode',
    dataKey: 'originWarehouseCode',
    disableSort: true
  },
  {
    key: 'date',
    header: 'date',
    dataKey: 'date',
    disableSort: true
  },
  {
    key: 'lotNo',
    header: 'lotNo',
    dataKey: 'lotNo',
    disableSort: true
  },
  {
    key: 'manufacturer',
    header: 'manufacturer',
    dataKey: 'manufacturer',
    disableSort: true
  },
  {
    key: 'expiry',
    header: 'expiry',
    dataKey: 'expiry',
    disableSort: true
  },
  {
    key: 'externalId',
    header: 'external Id',
    dataKey: 'externalId',
    disableSort: true
  }
]

export const ROWS = {
  default: [
    {
      destinationWarehouseCode: '16020',
      product: 'epn-amo002',
      quantity: '300',
      date: '2024-01-01',
      lotNo: '324',
      manufacturer: 'axmed',
      expiry: '2040-01-01',
      originWarehouseCode: 'KE-EPN-CHAK',
      externalId: '001'
    },
    {
      destinationWarehouseCode: '15864',
      product: 'epn-amo002',
      quantity: '100',
      date: '2024-01-01',
      lotNo: '325',
      manufacturer: 'axmed',
      expiry: '2040-01-01',
      originWarehouseCode: 'KE-EPN-CHAK',
      externalId: '002'
    }
  ],
  EPN: [
    {
      destinationWarehouseCode: '16020',
      product: 'epn-amo001',
      quantity: '300',
      date: '2024-01-01',
      lotNo: '326',
      manufacturer: 'axmed',
      expiry: '2040-01-01',
      originWarehouseCode: 'KE-EPN-CHAK',
      externalId: '001'
    },
    {
      destinationWarehouseCode: '15864',
      product: 'epn-amo002',
      quantity: '100',
      date: '2024-01-01',
      lotNo: '327',
      manufacturer: 'axmed',
      expiry: '2040-01-01',
      originWarehouseCode: 'KE-EPN-CHAK',
      externalId: '002'
    }
  ]
}

export const getFormattedValue = (value) => new Intl.NumberFormat().format(value)

export const getErrorListItems = (errorList, numItems = 1) => {
  return errorList.slice(0, numItems)
}

export const transformErrorMessage = (message, reasons) => {
  if (reasons.length === 1) {
    return message
  }
  return `${message}. We have ${reasons.length} affected, only displaying 1 item.`
}
