const get = require('lodash/get')
const findLast = require('lodash/findLast')

const { locationIdToProperties } = require('../../tools')
const { parse } = require('../../tools/smart-id')

const getRegionLevelSubTiers = (location, searchQuery = null, config) => {
// Get location's country. We assume Nigeria if location indicates no country
  // just because we started in Nigeria so there was no need to indicate the location's country back then
  // For the same reason, we default to Nigeria administrative boundaries if they're not part of the configuration
  const { country = 'ng' } = locationIdToProperties(location)
  const hierarchy = get(config, `locationIdHierarchies[${country}]`) || ['national', 'zone', 'state', 'lga']

  const locationLevels = parse(location) // Parse location into an object
  const sortedLocationLevels = Object.keys(locationLevels)

  // Now let's find the highest level in the hierarchy present in sortedLocationLevels
  const currentLevel = findLast(hierarchy, level => sortedLocationLevels.includes(level))
  if (!currentLevel) {
    throw new Error(`Invalid location: No known hierarchy level found in "${location}"`)
  }

  const locationIndex = hierarchy.indexOf(currentLevel)

  // Determine sub-tiers based on the hierarchy
  const startIndex = locationIndex + (searchQuery ? 0 : 1)
  const subTiers = hierarchy.slice(startIndex, startIndex + 2)
  return subTiers
}

exports.getRegionLevelSubTiers = getRegionLevelSubTiers
