import React, { Component } from 'react'
import get from 'lodash/get'
import flowRight from 'lodash/flowRight'

import withMasterData from './../common/withMasterData'
import withShipment from './../common/WithShipmentWrapper'

import DeleteShipment from './DeleteShipment'

import withConfig from '../../../van-shared/hoc/withConfig'
import { withApi } from '../../../common/ApiProvider'
import { getSubPath } from './DeleteProductsConfirmContainer'

class DeleteShipmentContainer extends Component {
  state = {
    deleting: false
  }

  onConfirmDelete = () => {
    const {history, shipment, api, location} = this.props
    const redirectLink = get(location, 'state.redirectLink')
    const baseUrl = '/shipments'

    this.setState({deleting: true}, async () => {
      await api.shipment.markVoid(shipment.id)
      if (redirectLink) {
        const subpath = getSubPath(redirectLink)
        return history.push(`${baseUrl}/${subpath}`)
      }
      history.push(baseUrl)
    })
  }

  render () {
    const {
      shipment,
      history,
      location
    } = this.props
    const backToOverview = location.search.includes('backToOverview=true')
    const redirectLink = get(location, 'state.redirectLink')

    return (
      <DeleteShipment
        deleting={this.state.deleting}
        shipment={shipment}
        history={history}
        onConfirmDelete={this.onConfirmDelete}
        backToOverview={backToOverview}
        redirectLink={redirectLink}
      />
    )
  }
}

const withHOCs = flowRight(
  withApi,
  withConfig,
  withMasterData,
  withShipment
)

export default withHOCs(DeleteShipmentContainer)
