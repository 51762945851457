const get = require('lodash/get')
const isArray = require('lodash/isArray')

const createShipmentSnapshot = require('./shipment-create-snapshot')
const updateShipmentSnapshot = require('./shipment-update-snapshot')
const createFollowUpShipment = require('./shipment-create-follow-up')
const createBulkShipmentSnapshots = require('./shipment-bulk-create-snapshots')
const findShipment = require('./shipment-find')
const saveShipmentChanges = require('./shipment-save-changes')
const saveAdjustments = require('./shipment-save-adjustments')
const addShipmentComment = require('./shipment-add-comment')
const addProductToShipment = require('./shipment-add-product')
const removeProductFromShipment = require('./shipment-remove-product')
const markShipmentVoid = require('./shipment-mark-void')
const findAllSurveysForSnapshot = require('./survey-find-all-for-snapshot')
const findAllSurveysForShipment = require('./survey-find-all-for-shipment')
const updateCRQuantities = require('./update-cr-quantities')
const updateCRQuantitiesStats = require('./update-cr-quantities-stats')
const { exportShipmentsData, exportShipments } = require('./sl-shipment-export')
const { exportLMDShipments } = require('./psm-shipment-export')
const { exportShipmentsListData, exportShipmentsList } = require('./sl-shipment-list-export')
const findSurvey = require('./survey-find')
const findAdjustments = require('./shipment-find-adjustments')
const createSurvey = require('./survey-create')
const { bulkSnapshotsFromSheet } = require('./shipment-bulk-snapshot-from-allocation-sheet')
const validateSheetLocations = require('./validate-sheet-locations.js')
const { getImportTemplate } = require('./shipment-get-import-template')
const { importShipments, importShipmentsFromSheet } = require('./shipment-import')
const { reschedule } = require('./shipment-reschedule')
const setupShipmentApi = require('./setup')
const { EntityApi, ProxifiedEntityApi } = require('../common')
const wrap = require('../utils/wrap-api')
const ShipmentPouchAdapter = require('./data-access/shipment-pouch-adapter')
const DataShipmentAdapter = require('./data-access/data-shipment-adapter')
const getReturnsNote = require('./shipment-get-returns-note')
const { bulkTranslateShipmentProducts } = require('./tools/bulk-translate-shipment-products')
const { exportWareHouseFunderShipments } = require('./export-warehouse-funder-shipments')
const parseShipmentUploadData = require('./tools/parse-shipment-upload-data')
const psmShipmentUpload = require('./psm-shipment-upload')
const { fromRelationalModel } = require('./tools/from-relational-model')
const docsToShipmentRecords = require('./tools/docs-to-shipment-records')
const { calculateShipmentVolumeAndPrice } = require('./tools/calculate-shipment-volume-price')
const { calculateDistance, gpsValidation, addGpsValidationFailComment } = require('./shipment-gps-validation')
const bulkAdvanceSnapshots = require('./shipment-bulk-advance-snapshots')
const getExternalShipment = require('./get-external-shipment')
const { SHIPMENT_STATUS } = require('./constants')
const { OtpApi } = require('./otp.js')
const { mergeFilterObjects } = require('../location/tools/filters')

const rawShipmentMethods = {
  addComment: addShipmentComment,
  createSnapshot: createShipmentSnapshot,
  updateSnapshot: updateShipmentSnapshot,
  createFollowUpShipment,
  createBulkSnapshots: createBulkShipmentSnapshots,
  find: findShipment,
  findById: findShipment.findById,
  findByIds: findShipment.findByIds,
  saveChanges: saveShipmentChanges,
  reschedule: reschedule,
  addProduct: addProductToShipment,
  removeProduct: removeProductFromShipment,
  markVoid: markShipmentVoid,
  bulkSnapshotsFromSheet,
  validateSheetLocations,
  getImportTemplate,
  calculateDistance,
  gpsValidation,
  addGpsValidationFailComment,
  importShipmentsFromSheet,
  importShipments,
  slExport: exportShipments,
  slExportData: exportShipmentsData,
  exportLMDShipments: exportLMDShipments,
  slShipmentListExport: exportShipmentsList,
  slShipmentListExportData: exportShipmentsListData,
  getReturnsNote,
  updateCRQuantities,
  updateCRQuantitiesStats,
  bulkTranslateShipmentProducts,
  exportWareHouseFunderShipments,
  bulkAdvanceSnapshots,
  getExternalShipment
}

const rawAdjustmentMethods = {
  draft: saveShipmentChanges.draftAdjustments,
  submit: saveAdjustments,
  find: findAdjustments
}

const rawSurveyMethods = {
  create: createSurvey,
  findAllForSnapshot: findAllSurveysForSnapshot,
  find: findSurvey,
  findAllForShipment: findAllSurveysForShipment
}

const entityName = 'shipment'

const methodsNeedingProxy = [
  'listLastMileDeliveries',
  'getLastMileDeliveriesFilterValues',
  'updateRoute',
  // we choose create (aka. POST) for calculate volume because
  // with shipment ids we can easily exceed the URL parameter limit,
  {
    methodName: 'calculateVolume',
    restAction: 'create'
  }
]

class ShipmentApi extends ProxifiedEntityApi {
  constructor (state, agaveAdapter, pgConnection, logger) {
    const { user, shipmentsDb, mainApi } = state
    const username = user.name
    const adapter = new ShipmentPouchAdapter(user, shipmentsDb)
    super(entityName, methodsNeedingProxy, !pgConnection, adapter, agaveAdapter)

    if (pgConnection) {
      this.dataShipmentAdapter = new DataShipmentAdapter(pgConnection, username, logger)
    }

    // TODO: remove this when all raw methods have been ported
    wrap(rawShipmentMethods, state, setupShipmentApi, null, this)
    this.adjustment = {}
    wrap(rawAdjustmentMethods, state, setupShipmentApi, null, this.adjustment)

    this.otp = new OtpApi({ shipmentsDb, agaveAdapter, pgConnection })
    this.mainApi = mainApi
  }

  async updateRoute ({ shipmentIds, routeId }) {
    if (!routeId) {
      throw new Error(`Cannot move shipment to route: routeId is required parameter`)
    }
    if (!shipmentIds || !shipmentIds.length) {
      throw new Error(`Cannot move shipment to route: shipmentIds is required parameter or it can't be empty`)
    }

    return this.dataShipmentAdapter.updateWhere(
      { filter: { shipment_id: shipmentIds } },
      { route_id: routeId })
  }

  async findByRoutes ({ routeIds }) {
    const shipmentEntities = await this.adapter.find({selector: {routeId: {$in: routeIds}}})
    const shipmentSnapshots = shipmentEntities.map(doc => ({...doc, _id: doc.id}))
    const shipments = docsToShipmentRecords(undefined, shipmentSnapshots, {
      useLocationAsOrigin: true
    })
    return shipments
  }

  parseShipmentUploadData (shipmentRows, programId, funderId) {
    return parseShipmentUploadData(shipmentRows, this.mainApi.location, this.mainApi.configuration, programId, funderId)
  }

  uploadShipments (formData) {
    return this.agaveAdapter.create('shipment/upload', formData)
  }

  shipmentUpload (funderShipments, routes) {
    return psmShipmentUpload(this.mainApi.state, {
      funderShipments,
      routes,
      routesApi: this.mainApi.routes,
      shipmentApi: this.mainApi.shipment
    })
  }

  /**
   * Proxified method (see ProxifiedEntityApi) to list last mile deliveries.
   * @param {Object} params - Contains Filters to apply, pageNumber and seperateByCompletion which separates complete from incomplete.
   * @returns {Promise<Array>} List of last mile deliveries.
   */
  async listLastMileDeliveries ({
    filters = {},
    pageNumber = 1,
    separateByCompletion = false,
    addSnapshotsData = true, // Add not "synced to postgres" extra data directly from CouchDB snapshots
    addGroupOrderId = false, // Get related orders' groupOrder id
    onlyCount = false // Only return the count of the shipments matching the filter
  }) {
    // Manage the STATE property that is in fact part of the destination_id string. STATE meaning administrative boundary for a country
    const stateSQLExpression = 'split_part(destination_id, \':\', 4)'
    // clone filters but "state" property
    const processedFilters = {...filters}

    // create state filter based on destination_id field
    const stateFilter = filters.state
    if (stateFilter) {
      delete processedFilters.state
      processedFilters[stateSQLExpression] = stateFilter
    }

    // getList manages offsets separated from the rest of the filter
    const offset = filters.offset
    if (offset) {
      delete processedFilters.offset
    }

    // getList manages limits separated from the rest of the filter
    const limit = filters.limit
    if (limit) {
      delete processedFilters.limit
    }

    const mergedFilters = mergeFilterObjects(processedFilters, DataShipmentAdapter.lastMileDeliveryFilter)

    const result = await this.dataShipmentAdapter.getList({
      // We want to ensure Postgres will return the most recent shipments first and also will always stick to same ordering
      // when there are multiple shipments with the same created_at
      ordering: ['-created_at', 'shipment_id'],
      filter: mergedFilters,
      limit,
      offset,
      addGroupOrderId,
      ...(onlyCount
        ? {
          skipCount: false,
          skipData: true
        }
        : {}
      )
    })

    if (onlyCount) {
      return result.count
    }

    const rdsShipments = result.results.map(row => {
      const shipment = fromRelationalModel({ shipment: row })
      if (addGroupOrderId) { // add group id to the built entity
        shipment.orderGroupId = get(row, 'order[0].group_id')
      }
      return shipment
    })

    // TODO: This looks like it should be refactored so we sync snapshotDates, otp, driverName and history to RDS, then
    // there'll be no need to fetch snapshots from Couchdb
    if (addSnapshotsData) {
      // let's get additional data from CouchDB
      const shipmentSnapshotsPromises = result.results.map(row => this.mainApi.state.dal.shipment.getAllDocsWithPrefix(this.mainApi.state.shipmentsDb, row.shipment_id))
      const shipmentSnapshotsData = await Promise.all(shipmentSnapshotsPromises)

      // get a plain simple array of snapshots to feed docsToShipmentRecords
      const shipmentSnapshots = []
      shipmentSnapshotsData.forEach(item => {
        item.rows.forEach(row => {
          shipmentSnapshots.push(row.doc)
        })
      })

      const couchDBShipments = docsToShipmentRecords(undefined, shipmentSnapshots, {useLocationAsOrigin: true})
      // Now we must find the right record to update in the shipmentResults array
      rdsShipments.forEach(rdsShipment => {
        const couchDBShipment = couchDBShipments.find(shipmentResult => shipmentResult.id === rdsShipment.id)
        if (couchDBShipment) {
          rdsShipment.snapshotDates = couchDBShipment.snapshotDates
          rdsShipment.otp = couchDBShipment.otp
          rdsShipment.driverName = couchDBShipment.driverName
          rdsShipment.history = couchDBShipment.history
        }
      })
    }

    if (!separateByCompletion) {
      return { shipments: rdsShipments, total: result.count }
    } else {
      return rdsShipments.reduce((acc, shipment) => {
        if (shipment.status === SHIPMENT_STATUS.RECEIVED) {
          acc.complete.push(shipment)
        } else {
          acc.incomplete.push(shipment)
        }
        return acc
      }, {incomplete: [], complete: []})
    }
  }

  /**
   * Proxified method (see ProxifiedEntityApi) to get last mile deliveries filter values for a given date.
   * @param {Date} startDate - Date to use as a startint created_at date for the deliveries being taken into account.
   * @returns json object containing filtering values in the form:
   * {
   *    field1: [
   *       value1,
   *       value2,
   *       value3,
   *       ...
   *       ],
   *    field2: [
   *       value4,
   *       value5,
   *       value6,
   *       ...
   *       ],
   *    ...
   * }
   */
  async getLastMileDeliveriesFilterValues (params) {
    const {filters} = params
    const result = await this.dataShipmentAdapter.getLastMileDeliveriesFilterValues(filters)
    return get(result, 'rows[0].values', {})
  }

  /**
   * Proxified method (see ProxifiedEntityApi) to calculate shipment volume reading the data from Postgres.
   * @param {Object} params - Contains shipmentId.
   * @returns {Object} Object containing totalVolume and totalPrice.
   */
  async calculateVolume ({ shipmentIds } = {}) {
    if (!shipmentIds || !isArray(shipmentIds)) {
      throw new Error('shipmentIds required to get volume')
    }
    if (shipmentIds.length === 0) {
      return []
    }
    return this.dataShipmentAdapter.getVolume(shipmentIds)
  }

  async calculateVolumeAndPrice (params) {
    return calculateShipmentVolumeAndPrice(params, this.mainApi)
  }
}

class SurveyApi extends EntityApi {
  constructor (state, restAdapter) {
    const { user, shipmentsDb } = state
    // Heads up: this is the same adapter as shipments api
    const adapter = new ShipmentPouchAdapter(user, shipmentsDb)
    super(adapter)

    // TODO: remove this when all raw methods have been ported
    wrap(rawSurveyMethods, state, setupShipmentApi, null, this)
  }
}

module.exports.ShipmentApi = ShipmentApi
module.exports.SurveyApi = SurveyApi
