import { isLeadUser } from '../../../common/utils'

const OLD_FINANCE_BASE_URL = '/retailer/finances/invoices'
const FINANCE_BASE_URL = '/retailer/finances'
const FINANCE_BASE_URL_WITH_TRANSACTIONS_HISTORY = `${FINANCE_BASE_URL}/transactions-history`

const PRICE_TEXT = 'Price Update'
const PRICE_TEXT_TO_DISPLAY = 'Some products have new prices'

const TO_DELIVERY_NAVIGATION = {
  SHIPMENT_NEW: 'shipment-new',
  SHIPMENT_SENT: 'shipment-sent',
  PROVISIONAL_ORDER: 'provisional-order'
}

const TO_WALLET_NAVIGATION = {
  NEW_INVOICE: 'new-invoice',
  INVOICE_CONVERTED_TO_PP: 'invoice-converted-to-pp',
  INVOICE_CONVERTED_DUE_TOMORROW: 'invoice-is-due-tomorrow',
  CASHBACK_EARNED: 'cashback-earned'
}

const TO_PRODUCT_NAVIGATION = {
  PRICE_UPDATE: 'price-update'
}

const linkCopy = (code) => {
  if (Object.values(TO_DELIVERY_NAVIGATION).includes(code)) {
    return 'Go to delivery'
  }

  if (Object.values(TO_WALLET_NAVIGATION).includes(code)) {
    return 'Go to Wallet'
  }

  if (Object.values(TO_PRODUCT_NAVIGATION).includes(code)) {
    return 'See price updates'
  }

  return 'See details'
}

const getProcessedNotifications = (notifications, user, location) => {
  const isLead = isLeadUser(user, location)
  if (!isLead) {
    return notifications
  }

  return notifications.filter(notification => !(notification.notificationText === PRICE_TEXT))
}

export {
  FINANCE_BASE_URL,
  FINANCE_BASE_URL_WITH_TRANSACTIONS_HISTORY,
  OLD_FINANCE_BASE_URL,
  TO_WALLET_NAVIGATION,
  PRICE_TEXT,
  PRICE_TEXT_TO_DISPLAY,
  linkCopy,
  getProcessedNotifications
}
