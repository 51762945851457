import React from 'react'

import { Button, Card, OptionsGroup, SelectInput } from '@fielded/shared-ui'
import { CloseCircle } from '@fielded/shared-ui/src/icons'

const CarrierManagementServiceAreaItem = ({
  serviceArea,
  index,
  stateOptions,
  onHandleServiceAreaChange,
  onRemoveServiceArea,
  canBeRemoved,
  viewOnly
}) => {
  return (
    <Card
      rounded
      semitight
      key={index}
      className='carrier-management__service-card'
    >
      {canBeRemoved && (
        <Button
          icon={<CloseCircle />}
          size='large'
          className='carrier-management__remove-btn'
          onClick={() => onRemoveServiceArea(index)}
        />
      )}
      <SelectInput.Stateless
        clearable={false}
        value={serviceArea.state}
        options={stateOptions}
        onValueChange={(value) => onHandleServiceAreaChange(index, value, 'state')}
        disabled={viewOnly}
      />
      <OptionsGroup.Stateless
        label='Set priority to state'
        options={[
          {
            value: 'primary',
            label: 'Primary'
          },
          {
            value: 'secondary',
            label: 'Secondary'
          },
          {
            value: 'tertiary',
            label: 'Tertiary'
          }
        ]}
        value={serviceArea.priority}
        onValueChange={(value) => onHandleServiceAreaChange(index, value, 'priority')}
        disabled={viewOnly}
      />
    </Card>
  )
}

export default CarrierManagementServiceAreaItem
