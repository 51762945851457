
async function getProductNonExpiredBatches (state, productIds) {
  if (!productIds || !productIds.length) {
    throw new Error('Array of productIds required')
  }
  productIds.sort()
  const selector = {
    _id: {
      '$gt': `${productIds[0]}:manufacturer:`,
      '$lt': `${productIds[productIds.length - 1]}:manufacturer:\uffff`
    },
    expiry: {
      '$gt': new Date().toJSON()
    },
    productId: {
      '$in': productIds
    }
  }
  const { docs } = await state.masterDataDB.find({
    selector,
    limit: Number.MAX_SAFE_INTEGER,
    fields: ['_id']
  })
  return docs
}

module.exports = getProductNonExpiredBatches
