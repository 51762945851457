import React, { Fragment, useState } from 'react'

import { toast } from '@fielded/shared-ui/src/components/Page'
import { Text } from '@fielded/shared-ui'

import { withApi } from '../../../../../common/ApiProvider'
import { getErrorListItems, getFormattedValue, transformErrorMessage } from './utils'
import PSMShipmentExportImport from './PSMShipmentExportImport'

const CustomListWrapper = ({ errorMessage, children }) => (
  <Fragment>
    {errorMessage && <Text>{errorMessage}</Text>}
    <ul className='vs-u-padding'>
      {children}
    </ul>
  </Fragment>
)

const onHandleError = (err) => {
  const feedback = {
    title: 'File Upload Error',
    type: 'warning',
    children: null
  }

  // When the error is an object
  if (err.error || err.message) {
    const errorMessage = err.error || err.message
    feedback.children = <Text>{errorMessage}</Text>

    if (err.locationIds || err.productIds) {
      const errorReasons = err.locationIds || err.productIds
      if (errorReasons.length) {
        feedback.children = (
          <CustomListWrapper errorMessage={transformErrorMessage(errorMessage, errorReasons)}>
            {getErrorListItems(errorReasons).map(reason => <li key={reason}>{reason}</li>)}
          </CustomListWrapper>
        )
      }
    }

    if (err.locations && err.locations.length) {
      const errorReasons = err.locations
      feedback.children = (
        <CustomListWrapper errorMessage={transformErrorMessage(errorMessage, errorReasons)}>
          {getErrorListItems(errorReasons).map(reason => (
            <li key={reason.supplier}>
              {reason.supplier}
              <CustomListWrapper>
                {reason.locationIds.map(locationId => (
                  <li key={locationId}>{locationId}</li>
                ))}
              </CustomListWrapper>
            </li>
          ))}
        </CustomListWrapper>
      )
    }

    if (err.shipments && err.shipments.length) {
      const errorReasons = err.shipments
      feedback.children = (
        <CustomListWrapper errorMessage={transformErrorMessage(errorMessage, errorReasons)}>
          {getErrorListItems(errorReasons).map(reason => (
            <li key={reason.origin}>
              {reason.origin} <br />
              {reason.destination}
              <CustomListWrapper>
                {reason.externalIds.map(externalId => (
                  <li key={externalId}>{externalId}</li>
                ))}
              </CustomListWrapper>
            </li>
          ))}
        </CustomListWrapper>
      )
    }
  }

  // When the error is an array
  if (err.errors) {
    const errorInfoList = []

    for (const errorItem of err.errors) {
      const affectedRows = errorItem.rowsAffected
      const rowMessage = affectedRows.length > 5
        ? `${getErrorListItems(affectedRows, 5).join(', ')}... and ${getFormattedValue(affectedRows.length - 5)} more`
        : affectedRows.length

      const errorInfo = `${errorItem.error}. Affected rows: ${rowMessage}`
      errorInfoList.push(errorInfo)
    }

    feedback.children = (
      <CustomListWrapper>
        {errorInfoList.map(info => <li key={info}>{info}</li>)}
      </CustomListWrapper>
    )
  }

  toast(feedback)
}

const PSMShipmentExportImportContainer = ({ api }) => {
  const [loading, setLoading] = useState(false)

  const onImport = async (importFile) => {
    const formData = new window.FormData()
    formData.append(importFile.name, importFile)

    setLoading(true)
    try {
      await api.shipment.uploadShipments(formData)
      toast({
        title: 'File uploaded successfully',
        type: 'success'
      })
    } catch (err) {
      onHandleError(err)
    } finally {
      setLoading(false)
    }
  }

  const onError = (error) => {
    onHandleError(error)
  }

  return (
    <PSMShipmentExportImport
      loading={loading}
      onImport={onImport}
      onError={onError}
    />
  )
}

export default withApi(PSMShipmentExportImportContainer)
