const { listChildren } = require('./list-children')
const proposalApi = require('../../../proposal')
const tools = require('../../tools')
const { locationIdToProperties } = require('../../../tools')

exports.listWithProposals = async function (state, { startDate, endDate }) {
  const currentEntities = await listChildren(
    state, state.user.location.id, { deep: true, includeInactive: true }
  )

  const { country } = locationIdToProperties(state.user.location.id)
  const locationsWithProposals = await proposalApi.list(
    state,
    { entityType: 'location', currentEntities, startDate, endDate },
    { countryId: `country:${country}` }
  )
  // If it's a proposed entity it's still a location "doc", so make it an
  // entity
  return locationsWithProposals.map(entity => {
    // Hacky but we know programs is an object in the doc and an array in the entity.
    if (Array.isArray(entity.programs)) {
      return entity
    }

    return tools.docToEntity({doc: entity, filter: state.user.funders})
  })
}
