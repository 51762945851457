import React from 'react'
import { PriceDisplay, Table, Text } from '@fielded/shared-ui'

const PSMInboundShipmentDetailsTable = ({
  productList
}) => {
  const tableColumns = [
    {
      header: 'Product ID',
      key: 'id',
      dataKey: 'id',
      width: 180,
      cellRenderer: ({ cellData }) =>
        <Text>{cellData}</Text>
    },
    {
      header: 'Product name',
      key: 'name',
      dataKey: 'name',
      width: 360,
      cellRenderer: ({ cellData }) =>
        <Text>{cellData}</Text>
    },
    {
      header: 'Manufacturer',
      key: 'manufacturer',
      dataKey: 'manufacturer',
      width: 200,
      cellRenderer: ({ cellData }) =>
        <Text>{cellData}</Text>
    },
    {
      header: 'Batch No',
      key: 'batchNo',
      dataKey: 'batchNo',
      width: 200,
      cellRenderer: ({ cellData }) =>
        <Text>{cellData}</Text>
    },
    // TODO: We are not getting this value for now
    // {
    //   header: 'Expiry Date',
    //   key: 'expiryDate',
    //   dataKey: 'expiryDate',
    //   width: 200,
    //   // align: 'right',
    //   cellRenderer: ({ cellData }) =>
    //     <Text bold>
    //       {cellData}
    //     </Text>
    // }
    {
      header: 'Received Qty',
      key: 'quantity',
      dataKey: 'quantity',
      width: 200,
      cellRenderer: ({ cellData }) =>
        <Text>
          <PriceDisplay value={cellData} />
        </Text>
    }
  ]

  return (
    <Table
      entries={productList}
      columns={tableColumns}
    />
  )
}

export default PSMInboundShipmentDetailsTable
