import { hasFeature } from '../../../../van-shared/utils/features'
import { userIsAuthorised } from '../../../../van-shared/utils/auth'

import alertIndicatorsConfig from '../../../../common/alert-indicator-config'

import { shouldTrackBatches, applyCalculatedField } from '@fielded/fs-api/lib/tools'
import getDefaultDisabled from '@fielded/fs-api/lib/report/tools/get-default-disabled'
import get from 'lodash/get'

import { reset as resetOverview } from '../overview'
import { VAN_SERVICE } from '../../../../subapps/reports/common/program'

import {
  HAS_NO_DATA,
  IS_LOADING,
  LOADING_FAILED,
  LOADING_SUCCEEDED
} from '../utils'

const reducerName = 'reportEntry'

export const REQUEST_REPORT = `${reducerName}/REQUEST_REPORT`
export const RECEIVE_REPORT = `${reducerName}/RECEIVE_REPORT`
export const RECEIVE_ERROR = `${reducerName}/RECEIVE_ERROR`

export const USE_CACHE = `${reducerName}/USE_CACHE`

export const UPDATE_REPORT = `${reducerName}/UPDATE_REPORT`
export const UPDATE_ONGOING_ENTRY = `${reducerName}/UPDATE_ONGOING_ENTRY`

export const RESET_REPORT = `${reducerName}/RESET_REPORT`

export const RECEIVE_ALERTS = `${reducerName}/RECEIVE_ALERTS`
export const UPDATE_ALERT = `${reducerName}/UPDATE_ALERT`

export const REQUEST_DELETE_REPORT = `${reducerName}/REQUEST_DELETE_REPORT`
export const SUCCESS_DELETE_REPORT = `${reducerName}/SUCCESS_DELETE_REPORT`

export const RESET_PRODUCT_ENTRY = `${reducerName}/RESET_PRODUCT_ENTRY`
export const ALERT_INDICATORS_CONFIG = `${reducerName}/ALERT_INDICATORS_CONFIG`
export const REQUEST_RESTART_REPORT = `${reducerName}/REQUEST_RESTART_REPORT`
export const SUCCESS_RESTART_REPORT = `${reducerName}/SUCCESS_RESTART_REPORT`

export const defaultState = {
  status: HAS_NO_DATA,
  lastReportId: '',
  alertIndicatorsConfig: {},
  data: {
    report: {},
    ongoingEntry: {},
    tracksPartnerBalances: false
  },
  alerts: []
}

function updateOngoingEntry (state, action) {
  let calculatedFields = {}
  const tracksPartnerBalances = get(state, 'data.tracksPartnerBalances')
  const calculatedFieldOptions = { tracksPartnerBalances }

  if (action.fields) {
    const definitions = Object.values(action.fields.byId)
    // applyCalculatedField expects a stock count 'stock' shape
    const stockShape = { 'product:xx': { fields: action.data.fields } }
    const result = applyCalculatedField(
      stockShape,
      definitions,
      calculatedFieldOptions
    )

    calculatedFields = result['product:xx'].fields
  }

  const fields = {
    ...action.data.fields,
    ...calculatedFields
  }

  const nextState = {
    ...state,
    data: {
      ...state.data,
      ongoingEntry: {
        ...action.data,
        // only add this if fields are used, use undefined to
        // serialize to nothing for batched stock counts
        fields: Object.keys(fields).length ? fields : undefined
      }
    }
  }
  return nextState
}

export const useCache = () => ({ type: USE_CACHE })
export const requestReport = reportId => ({
  type: REQUEST_REPORT,
  reportId
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_REPORT: return {
      ...defaultState,
      status: IS_LOADING,
      lastReportId: action.reportId
    }
    case RECEIVE_REPORT:
      return receiveReport(state, action)
    case RECEIVE_ERROR: return {
      ...defaultState,
      status: LOADING_FAILED
    }
    case USE_CACHE: return {
      ...state,
      status: LOADING_SUCCEEDED
    }
    case UPDATE_REPORT: return {
      ...state,
      data: {
        ...state.data,
        report: action.report
      }
    }
    case UPDATE_ONGOING_ENTRY: return updateOngoingEntry(state, action)
    case RECEIVE_ALERTS: return {
      ...state,
      alerts: action.alerts
    }
    case UPDATE_ALERT: return {
      ...state,
      alerts: state.alerts.reduce((acc, a) => {
        if (action.alert.id === a.id) {
          acc.push(action.alert)
        } else {
          acc.push(a)
        }
        return acc
      }, [])
    }
    case RESET_REPORT:
      return defaultState
    case RESET_PRODUCT_ENTRY: return {
      ...state,
      data: {
        ...state.data,
        ongoingEntry: {
          ...action.data
        }
      }
    }
    case ALERT_INDICATORS_CONFIG: return {
      ...state,
      alertIndicatorsConfig: action.alertIndicatorsConfig
    }
    default:
      return state
  }
}

export const selectReportEntry = (state) => state.reportEntry

// TODO: move to API layer
const addProgress = (report, fieldsById) => {
  const serviceId = report.serviceId
  const stock = report.stock
  const total = Object.keys(stock).length
  let complete = 0

  const stockWithProgress = Object.keys(stock).reduce((withProgress, productId) => {
    const productStock = stock[productId]
    let productWithProgress
    const batches = get(productStock, 'batches')
    if (batches) {
      productWithProgress = addBatchesProgress(productStock, batches)
    } else {
      productWithProgress = addMultiFieldProgress(serviceId, productStock, fieldsById)
    }
    if (productWithProgress.progress.isComplete) {
      complete++
    }
    withProgress[productId] = productWithProgress
    return withProgress
  }, {})

  const reportWithProgress = {
    ...report,
    updatedAt: new Date().toISOString(),
    progress: {
      total,
      complete
    },
    stock: stockWithProgress
  }
  return reportWithProgress
}

const addBatchesProgress = (productStock, batches) => {
  let amount = productStock.amount
  const batchIds = Object.keys(batches)
  const isComplete = batchIds.length > 0 && batchIds.every(id => {
    const amount = get(batches, `${id}.fields.field:standard-physical-count.amount`, undefined)
    return (typeof amount !== 'undefined')
  })

  if (isNaN(amount)) {
    amount = batchIds.reduce((sum, batchId) => {
      const amount = get(batches, `${batchId}.fields.field:standard-physical-count.amount`, 0)
      return sum + amount
    }, 0)
  }
  return {
    ...productStock,
    amount,
    progress: {
      isComplete
    }
  }
}

const addMultiFieldProgress = (serviceId, productStock, fieldsById) => {
  let isComplete = false
  if (productStock && productStock.fields && Object.keys(productStock.fields).length) {
    const relevantFields = Object.keys(fieldsById).reduce((relevant, fieldId) => {
      const fieldDef = fieldsById[fieldId]
      if (fieldDef.fieldType === 'entry' && !fieldDef.optional) {
        relevant.push(fieldId)
      }
      return relevant
    }, [])

    const isFieldEmpty = field => {
      const amount = get(field, 'amount')
      return typeof amount === 'undefined'
    }

    const isAnyEmpty = relevantFields.some(fieldId => isFieldEmpty(productStock.fields[fieldId]))
    isComplete = !isAnyEmpty
  }
  const productWithProgress = {
    ...productStock,
    progress: {
      isComplete
    }
  }
  if (serviceId === VAN_SERVICE) {
    // VAN products can only have one field
    productWithProgress.amount = get(productStock, 'fields.field:standard-physical-count.amount')
  }
  return productWithProgress
}

const receiveReport = (state, { report, fieldsById, productsById, service, period, activePeriod, hasReadOnlyReports, tracksPartnerBalances }) => {
  // The API returns `report.stock[productId]: {}` for product for which there is no data.
  // In here we convert that to something like...:
  //  'product:ghsc-aid0019': {
  //    fields: {
  //      'field:standard-opening-balance': {},
  //      'field:standard-received': {},
  //      'field:standard-consumed': {},
  //      'field:standard-lost': {},
  //      'field:standard-adjusted': {},
  //      'field:standard-physical-count': {}
  //    }
  //  }
  //
  const defaultFields = Object.keys(fieldsById).reduce((productFields, fieldId) => {
    const field = fieldsById[fieldId]
    if (field.fieldType === 'entry') {
      productFields[fieldId] = {}
    }
    return productFields
  }, {})

  const stockWithFieldsForAllProducts = Object.keys(report.stock).reduce((stock, productId) => {
    const productStock = report.stock[productId] || {}
    const product = productsById[productId]
    if (!product) {
      return stock
    }
    if (shouldTrackBatches({ service: { id: service.id }, location: report.location })) {
      stock[productId] = { batches: {}, ...productStock }
    } else {
      stock[productId] = { fields: { ...defaultFields, ...productStock.fields }, ...productStock }
    }
    return stock
  }, {})

  const decoratedReport = {
    ...report,
    stock: stockWithFieldsForAllProducts
  }

  const reportWithProgress = addProgress(decoratedReport, fieldsById)
  return {
    ...state,
    status: LOADING_SUCCEEDED,
    data: {
      ...state.data,
      report: reportWithProgress,
      period,
      activePeriod,
      hasReadOnlyReports,
      tracksPartnerBalances
    }
  }
}

export const addAlertComment = (alertId, message, deviceId) => async (dispatch, getState, { user, api }) => {
  const userId = user.name
  const alert = await api.alert.addComment({ alertId, userId, deviceId, message })
  dispatch({ type: UPDATE_ALERT, alert })
}

export const setAlertStatus = (alertId, status, deviceId) => async (dispatch, getState, { user, api }) => {
  const userId = user.name
  const alert = await api.alert.setStatus({ alertId, userId, deviceId, status })
  dispatch({ type: UPDATE_ALERT, alert })
}

export const discardOngoingProductEntry = () => (dispatch, getState) => {
  const state = getState()
  const ongoingEntry = get(state, 'reportEntry.data.ongoingEntry')
  const productId = ongoingEntry.productId
  const previousRecord = get(state, `reportEntry.data.report.stock.${productId}`)
  const previousExpiry = get(state, `reportEntry.data.report.expiry.${productId}`)

  dispatch({
    type: RESET_PRODUCT_ENTRY,
    data: {
      ongoingEntry: {
        ...ongoingEntry,
        ...previousRecord,
        expiry: previousExpiry
      }
    }
  })
}

export const discardOngoingEntry = () => ({
  type: RESET_REPORT
})

export const deleteReportEntry = () => async (dispatch, getState, { api }) => {
  dispatch({
    type: REQUEST_DELETE_REPORT
  })
  const { _id } = getState().reportEntry.data.report
  await api.report.remove(_id)
  dispatch({
    type: SUCCESS_DELETE_REPORT
  })
  dispatch(discardOngoingEntry())
}

const updateStock = (stock, ongoingEntry) => {
  const {
    productId,
    fields,
    batches,
    commits,
    amount,
    disabled
  } = ongoingEntry

  stock = {
    ...stock
  }

  if (fields) {
    stock[productId].fields = fields
  }
  if (batches) {
    stock[productId].batches = batches
  }
  if (commits) {
    stock[productId].commits = commits
  }

  if (typeof disabled !== 'undefined') {
    stock[productId].disabled = disabled
  }

  stock[productId].amount = amount

  return stock
}

export const submitReport = (options = {}, service) => async (dispatch, getState, { api }) => {
  const state = getState()
  const {
    period,
    report: {
      _id: reportId,
      location: { id: locationId },
      partialCount
    }
  } = state.reportEntry.data

  dispatch(resetOverview())

  let submittedAt = new Date()
  // V2 Partial counts do not do the submittedAt dance
  // We need to have the actual date there
  if (submittedAt >= period.entryEndDate && !partialCount) {
    // Hack to treat submissions made after the end of the reporting period as if
    // they're for the given reporting period. The ledger expects the submittedAt date
    // to be inside the reporting period.
    submittedAt = period.entryEndDate
  }

  if (options.opensExistingReport && !partialCount) {
    // Shelf Life admins can update reports without changing submittedAt
    // on reports that have been submitted by others
    // https://github.com/fielded/van-orga/issues/2490
    const reportsInPeriod = await api.report.findForLocation({
      locationId,
      service,
      period
    })
    if (reportsInPeriod.length > 0) {
      submittedAt = reportsInPeriod[0].submittedAt
    }
  }
  await api.report.draft.submit({ reportId, locationId, service, period, submittedAt, signed: options.signed })
}

export const saveOngoingEntry = ({ saveAndExit } = {}, service, fieldsById) => async (dispatch, getState, { api }) => {
  const state = getState()
  const {
    report,
    ongoingEntry,
    period
  } = state.reportEntry.data
  // This updates the whole `stock` field (all products)
  // in every save. This is not strictly necessary for the API.
  // van-stock-counts-api can handle update of only a subset of products
  const stock = updateStock(report.stock, ongoingEntry)
  let expiry
  if (report.expiry) {
    expiry = {
      ...report.expiry,
      // Must pass in an empty object here to the API
      // even if the user does not enter anything
      // otherwise you can't for example intentionally
      // remove batches from the expired form
      [ongoingEntry.productId]: ongoingEntry.expiry
    }
  }

  await api.report.draft.save({ locationId: report.location.id, service, period, stock, expiry })

  if (saveAndExit) {
    return dispatch(discardOngoingEntry())
  }

  return dispatch({
    type: UPDATE_REPORT,
    report: addProgress({ ...report, stock, expiry }, fieldsById)
  })
}

export const setDisabledProduct = (disabledProduct) => (dispatch, getState) => {
  const state = getState()
  const ongoingEntry = get(state, 'reportEntry.data.ongoingEntry')
  if (!ongoingEntry) return

  return dispatch({
    type: UPDATE_ONGOING_ENTRY,
    data: {
      ...ongoingEntry,
      disabled: disabledProduct
    }
  })
}

// ex `key`: `product:bcg:manufacturer:novartid:batchNo:123`, `field:standard-received`, can be undefined
// ex updates: `{ amount: 300 }`
export const updateProduct = (reportId, productId, key, update, fields) => (dispatch, getState) => {
  const state = getState()
  const ongoingEntry = get(state, 'reportEntry.data.ongoingEntry')
  if (!ongoingEntry) {
    return
  }

  if (ongoingEntry.productId !== productId && ongoingEntry.reportId !== reportId) {
    return
  }

  let entryType
  if (key.includes('field:')) {
    entryType = 'fields'
    key = key.substr(key.indexOf('field:'), key.length)
  } else if (key.includes('expiry:')) {
    const batchNo = key.substr('expiry:'.length)
    const existingBatch = (ongoingEntry.expiry.batches && ongoingEntry.expiry.batches[batchNo]) || {}
    const newState = {
      ...ongoingEntry,
      expiry: {
        batches: {
          ...ongoingEntry.expiry.batches,
          [batchNo]: {
            ...existingBatch,
            ...update
          }
        }
      }
    }
    // delete batch
    if (update === null) {
      delete newState.expiry.batches[batchNo]
    }

    return dispatch({
      type: UPDATE_ONGOING_ENTRY,
      data: newState
    })
  } else if (key.includes('batchNo')) {
    entryType = 'batches'
  } else if (key.startsWith('commit:')) {
    const shortKey = key.substr('commit:'.length)
    return dispatch({
      type: UPDATE_ONGOING_ENTRY,
      data: {
        ...ongoingEntry,
        commits: {
          ...ongoingEntry.commits,
          [shortKey]: {
            ...ongoingEntry.commits[shortKey],
            ...update
          }
        }
      }
    })
  }

  if (entryType) {
    return dispatch({
      type: UPDATE_ONGOING_ENTRY,
      fields,
      data: {
        ...ongoingEntry,
        [entryType]: {
          ...ongoingEntry[entryType],
          [key]: {
            ...ongoingEntry[entryType][key],
            ...update
          }
        }
      }
    })
  }

  // When do you get here?
  return dispatch({
    type: UPDATE_ONGOING_ENTRY,
    data: {
      ...ongoingEntry,
      ...update
    }
  })
}

export const loadProduct = (productId) => (dispatch, getState) => {
  if (!productId) {
    return
  }
  const {
    report,
    ongoingEntry,
    report: { stock }
  } = getState().reportEntry.data
  const reportId = report._id
  const ongoingProductId = ongoingEntry.productId
  const ongoingReportId = ongoingEntry.reportId

  // PSM can 'disable' products to skip them in the entry
  const disabled =
    stock[productId] && typeof stock[productId].disabled !== 'undefined'
      ? stock[productId].disabled
      : getDefaultDisabled(report.serviceId)

  // re-load product if either the selected product or the selected report changed
  if (productId === ongoingProductId && reportId === ongoingReportId) {
    return
  }

  // Hard code commits until we have a place to put them
  // (mutating the object on report.stock here)
  if (report.serviceId === VAN_SERVICE) {
    const productData = report.stock[productId]
    productData.commits = productData.commits || {
      'campaign:sia': {
        amount: 0
      }
    }
  }

  // VSCA adds the Expiry prop to services
  // that submits the expiry form (#3041)
  let expiry = false
  if (report.expiry) {
    expiry = report.expiry[productId] || {}
  }

  dispatch({
    type: UPDATE_ONGOING_ENTRY,
    data: {
      reportId,
      productId,
      disabled,
      ...report.stock[productId],
      expiry
    }
  })
}

export const checkOutdatedStockReport = (report, service) => (dispatch, getState, { api }) => {
  return api.report.draft.isOutdated({ reportId: report._id, service })
}

export const fetchAlerts = (report, service) => async (dispatch, getState, { api }) => {
  dispatch({
    type: ALERT_INDICATORS_CONFIG,
    alertIndicatorsConfig: alertIndicatorsConfig
  })
  const alerts = await api.alert.list({
    target: {
      type: 'stock-count-target',
      serviceId: service.id,
      reportId: report._id
    }
  })
  dispatch({
    type: RECEIVE_ALERTS,
    alerts
  })
}

export const augmentReport = (report, service, fieldsById, productsById, config, location) => async (dispatch, getState, { user, api }) => {
  const tracksPartnerBalances = location.tracksPartnerBalances

  const [period, activePeriod] = await Promise.all([
    // Get reports period
    api.report.period.get({
      program: service.program,
      date: new Date(report.date.period.effectiveStart),
      isEffectiveDate: true
    }),
    // Get now active period
    api.report.period.get({ program: service.program, date: new Date() })
  ])

  // This will set isEditablePeriod to true
  // so you can edit all reports from the locations/services view
  const opensExisting = hasFeature(config.features, 'stockCount:opensExistingReport')
  const writesPartial = hasFeature(config.features, 'stockCount:adjustments')
  const userEditsAny = userIsAuthorised(user, 'feature:edits-any-reporting-period')
  const hasReadOnlyReports = userIsAuthorised(user, 'feature:read-only-reports')
  const canSeeReports = (opensExisting || writesPartial || userEditsAny || hasReadOnlyReports)
  if (canSeeReports) {
    period.isEditable = canSeeReports
  }

  dispatch({
    type: RECEIVE_REPORT,
    report,
    fieldsById,
    productsById,
    service,
    period,
    activePeriod,
    hasReadOnlyReports,
    tracksPartnerBalances
  })
}
