import React from 'react'
import { Banner } from '@fielded/shared-ui'

const OfflineWarning = () => {
  return (
    <div>
      <Banner
        inline
        type='warning'
      >
        <h2>Offline Shipments Only</h2>

        <p>
          We could not reach the server to download the complete shipment history.<br />
          To view those, make sure you are on a reliable connection.
        </p>
      </Banner>
    </div>
  )
}

export default OfflineWarning
