/* Location API methods almost entirely uses the old approach of passing
 state as the first positional param of each of its standalone methods.
 This setup of `LocationApi extends EntityApi` is for new methods.
 See https://field.atlassian.net/wiki/spaces/FIELD/pages/430309377/RFC+FS+Repository+API+Adapter+Pattern
*/
const { EntityApi } = require('../common')
const { wrapEntityApi } = require('../utils/wrap-api')
const PGAdapter = require('./../common/pg-adapter')
const lodashGet = require('lodash/get')
// Legacy methods requiring `state` as first param
const {
  getByIds,
  getByWarehouseCodes,
  get,
  getByWarehouseCode,
  getOrFakeName,
  getWithProposal,
  listWithProposals,
  proposeChange,
  decideProposal,
  listAll,
  listAllRaw,
  listRelated,
  listChildren,
  getParentId,
  exportLocations,
  importLocations,
  saveLocations,
  listAdminLocations
} = require('./api')
const tools = require('./tools')

const rawMethods = {
  get,
  getOrFakeName,
  getByIds,
  getByWarehouseCodes,
  getByWarehouseCode,
  getParentId: getParentId,
  listAllRaw,
  listAll,
  listRelated,
  listChildren,
  proposal: {
    getWithProposal,
    listWithProposals,
    proposeChange,
    decideProposal
  },
  exportLocations,
  importLocations,
  saveLocations,
  tools,
  listAdminLocations
}

const LocationPouchAdapter = require('./data-access/pouchdb-adapter/location-pouch-adapter')
const LocationRestAdapter = require('./data-access/rest-adapter')
const LocationOrdersStatusHistoryAdapter = require('./data-access/orders-status-history-rest-adapter')
const { LocationPGAdapter } = require('./location-pg-adapter')

class LocationApi extends EntityApi {
  constructor (state, restAdapter, logger, pgConnection, agaveAdapter) {
    const { user, locationsDB } = state
    const adapter = new LocationPouchAdapter(
      user, locationsDB
    )
    super(adapter)

    this.restAdapter = new LocationRestAdapter(restAdapter)
    this.agaveAdapter = agaveAdapter

    // TODO: remove this when all raw methods have been ported
    const apiMethods = wrapEntityApi(rawMethods, state)
    Object.assign(this, apiMethods)

    if (pgConnection) {
      const { user = {} } = state
      const username = user.name
      this.pgAdapter = new LocationPGAdapter({pgConnection, username, logger})
      this.supplyGraphPgAdapter = new LocationSupplyGraphPgAdapter({pgConnection, username, logger})
      this.ordersStatusHistory = new LocationOrdersStatusHistoryAdapter({
        pgConnection, username, logger
      })
    }
  }

  listAdditionalFields () {
    return this.adapter.listAdditionalFields()
  }

  getLocationsViaIds (locationIds, dryRun) {
    if (dryRun) {
      return []
    } else if (this.agaveAdapter) {
      return this.agaveAdapter.create('location/get_raw_doc/by_ids', { locationIds })
    }
    // Fallback to pouch
    return this.mainApi.location.getByIds(locationIds)
  }

  getMarketData (alias) {
    if (this.agaveAdapter) {
      return this.agaveAdapter.create(`location/market_data${alias ? `?alias=${alias}` : ''}`)
    }
    return this.pgAdapter.getMarketData(alias)
  }

  async getLocationBlockingRule (locationId) {
    if (this.pgAdapter) {
      const data = await this.pgAdapter.getLocationBlockingRule(locationId)
      const blockingRule = lodashGet(data, 'blocking_rule', 'default')
      return { blockingRule }
    }
    return this.agaveAdapter.get(`location/blocking_rule?locationId=${locationId}`)
  }
}

class LocationSupplyGraphPgAdapter extends PGAdapter {
  constructor ({pgConnection, username, logger}) {
    const columns = [
      'id',
      'supply_from_id',
      'supply_to_id',
      'updated_by'
    ]

    const opts = {addUuid: true}

    super(pgConnection, 'avocado.data_locationsupply', tools.BACKEND_SERVICE_USERNAME, columns, 'id', {}, logger, opts)
  }
}

module.exports = rawMethods
module.exports.LocationApi = LocationApi
