import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'

import flowRight from 'lodash/flowRight'

import { Loading } from '@fielded/shared-ui'

import { withUser } from '../../../../../common/AuthenticationProvider'
import { byCompleteAndDate, findShipments, selectShipments } from '../../../../../root/reducers/shipments'
import withConfig from '../../../../../van-shared/hoc/withConfig'
import { hasFeature } from '../../../../../van-shared/utils/features'
import capitalize from '../../../../../common/utils/capitalize'
import { withApi } from '../../../../../common/ApiProvider'

import { appendLocationType } from '../../../common/utils'

import ErrorView from '../../../components/LoadingError'
import PSMInboundShipments from './PSMInboundShipments'

const PSMInboundShipmentsContainer = ({
  api,
  user,
  config,
  history,
  showCompleted
}) => {
  const dispatch = useDispatch()

  const shipmentsFromStore = useSelector(selectShipments)

  const [shipments, setShipments] = useState([])
  const [hasReceivedShipments, setHasReceivedShipments] = useState(false)
  const [locationObject, setLocationObject] = useState()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const shipmentList = byCompleteAndDate(shipmentsFromStore, user)
  const withListInGeoLocation = hasFeature(config.features, 'shipments:listInGeoLocation')
  const useRouteSpecificFilter = true
  const userLocation = user.location

  const initialize = async () => {
    const options = {
      noExternalShipments: false
    }

    try {
      await dispatch(
        findShipments(
          userLocation,
          withListInGeoLocation,
          useRouteSpecificFilter,
          options
        )
      )
    } catch (e) {
      setError(e)
      return
    }

    const locationObject = await api.location.get(userLocation.id)

    setShipments(shipmentList.complete)
    setLocationObject(locationObject)
    setHasReceivedShipments(true)
    setLoading(false)
  }

  useEffect(() => {
    const initializeData = async () => {
      await initialize()
    }
    initializeData()
  }, [shipmentList.complete.length, shipmentList.incomplete.length])

  if (error) {
    return (<ErrorView error={error} />)
  }

  if (!(hasReceivedShipments)) {
    return <Loading loadingText='Loading completed shipments …' />
  }

  const locationNameFallback = capitalize(userLocation.id.split(':').pop())
  const locationName = get(locationObject, 'fullName', locationNameFallback)
  const fullLocationName = appendLocationType(locationName, config)

  return (
    loading
      ? <div />
      : <PSMInboundShipments
        config={config}
        shipments={shipments}
        history={history}
        fullLocationName={fullLocationName}
      />
  )
}

const withHOCs = flowRight(
  withApi,
  withUser,
  withConfig
)

export default withHOCs(PSMInboundShipmentsContainer)
