// Note: pulling in the original lib/tools/ version to not have to do
// a big refactor of everyone that uses lib/tools/ to start
const {
  getLocation,
  createLocationDoc,
  validateLocationCreate,
  validateLocationEdit,
  locationEditsToDoc
} = require('../../tools')

const {someServiceIdentifiersSupported} = require('../../relationship-graph/tools/get-locations-from-nodes')

const constants = require('./constants')
const {
  BACKEND_SERVICE_USERNAME,
  CREATE_IN_FS_LEVEL,
  INITIALIZED_IN_OPS,
  ACCOUNT_ACTIVATED_LEVEL,
  APPLICATION_APPROVED_LEVEL,
  CLASSIC_LEAD_FULLY_MOBILIZED_LEVEL,
  MEMBERSHIPS,
  SERVICE_FEE_CONSTANTS,
  PAY_SMALL_SMALL_LOCALIZATION,
  LOCATION_CLASSIFICATIONS
} = require('./constants')
const {getWithTimestamps} = require('./docs')
const {addWarehouseCodes, getPSMCodePrefix} = require('./warehouse-codes')

const {filterLocationsByProgram} = require('./filters')
const getUserFilter = require('./get-user-filter')
const fieldsMap = require('./fields-map')

const {getEnabledRouteDetails, decorateAdminLocations} = require('./decorate-admin-locations')
const locationRouteToRelationalModel = require('./location-route-to-relational-model')

const {isLocationAtLeastLevel} = require('./is-location-at-least-level')
const { isLocationBelowLevel } = require('./is-location-below-level')
const { parseCoordinates } = require('./coordinate-helper')

const isPPMV = require('./is-ppmv')

const isClinicOrHospital = require('./is-clinic-or-hospital')

const getCurrentRouteId = require('./get-current-route-id')

const routeDocToCurrentRoute = require('./route-doc-to-current-route')

const { isLocationSubscriptionBased } = require('./is-location-subscription-based')
const { getMembershipTypeAwareServiceId } = require('./membership-type-service-ids')

const {
  isServiceFeeDepositPaid,
  getServiceFeeExpiryDate,
  isInServicePeriod,
  getFreePeriodExpiryDate,
  isInFreePeriod
} = require('./service-fee')
const { getRegionLevelSubTiers } = require('./get-region-level-sub-tiers')

module.exports = {
  validateLocationCreate,
  validateLocationUpdate: validateLocationEdit,
  docToEntity: ({ doc, date, funderOrRouteFilter, allConfigurations, includeProgramsHistory }) => getLocation(doc, date, funderOrRouteFilter, allConfigurations, includeProgramsHistory),
  entityCreateToDoc: ({ edits, effectiveDate, supportCountry }) => {
    return createLocationDoc(edits, effectiveDate, supportCountry)
  },
  entityEditToDoc: ({ edits, effectiveDate, currentDoc }) => {
    return locationEditsToDoc(currentDoc, edits, effectiveDate)
  },
  isInactiveLocation: (location) => {
    return (!location.programs || location.programs.length === 0)
  },
  filterLocationsByProgram,
  constants,
  addWarehouseCodes,
  getPSMCodePrefix,
  getWithTimestamps,
  fieldsMap,
  BACKEND_SERVICE_USERNAME,
  CREATE_IN_FS_LEVEL,
  INITIALIZED_IN_OPS,
  ACCOUNT_ACTIVATED_LEVEL,
  APPLICATION_APPROVED_LEVEL,
  CLASSIC_LEAD_FULLY_MOBILIZED_LEVEL,
  MEMBERSHIPS,
  LOCATION_CLASSIFICATIONS,
  SERVICE_FEE_CONSTANTS,
  PAY_SMALL_SMALL_LOCALIZATION,
  someServiceIdentifiersSupported,
  getEnabledRouteDetails,
  decorateAdminLocations,
  isLocationAtLeastLevel,
  isLocationBelowLevel,
  isPPMV,
  isClinicOrHospital,
  isLocationSubscriptionBased,
  locationRouteToRelationalModel,
  getCurrentRouteId,
  routeDocToCurrentRoute,
  getMembershipTypeAwareServiceId,
  serviceFee: {
    isServiceFeeDepositPaid,
    getServiceFeeExpiryDate,
    isInServicePeriod,
    getFreePeriodExpiryDate,
    isInFreePeriod
  },
  getUserFilter,
  parseCoordinates,
  getRegionLevelSubTiers
}
