const interceptFetchErrors = require('./../../../utils/fetch-error-interceptor')

function handleAndParse (res) {
  return interceptFetchErrors(res).then(response => response.json())
}

async function getOne (state, userId) {
  const endpoint = `/users/${userId}`

  const res = await state.fetch(endpoint, {method: 'GET'})
  return handleAndParse(res)
}
exports.getOne = getOne

async function getAll (state, params = {}) {
  let endpoint = '/users'
  if (params.smallUser) endpoint = endpoint + '?smallUser=true'
  const res = await state.fetch(endpoint, {method: 'GET'})
  return handleAndParse(res)
}
exports.getAll = getAll

const listUsersByRole = async (state, { userRole, userOrganisation }) => {
  let endpoint = '/users'
  if (userRole) endpoint = endpoint + `?userRole=${userRole}`
  if (userOrganisation) endpoint = endpoint + `&userOrganisation=${userOrganisation}`
  const docs = await state.fetch(endpoint, {method: 'GET'})
  return handleAndParse(docs)
}
exports.listUsersByRole = listUsersByRole

const listUsersByRoutes = async (state, { routes = [] }) => {
  const endpoint = '/users'
  const searchParamsString = (new URLSearchParams({ routes })).toString()
  const docs = await state.fetch(`${endpoint}?${searchParamsString}`, {method: 'GET'})
  return handleAndParse(docs)
}
exports.listUsersByRoutes = listUsersByRoutes
