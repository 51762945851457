const { generateExcelSheet } = require('../utils/generate-excel-sheet')
const { warehouseCodeToVirtualId } = require('../order/tools')
const { locationIdToProperties } = require('../tools')
const get = require('lodash/get')
const keyBy = require('lodash/keyBy')
const endOfDay = require('date-fns/end_of_day')
const startOfDay = require('date-fns/start_of_day')

const SHIPMENTEXPORTHEADERS = [
  'locationCode',
  'locationName',
  'state',
  'lga',
  'ward',
  'subOrderId',
  'funderId',
  'programId',
  'shipmentId',
  'totalLoadVolume',
  'route'
]

const exportWareHouseFunderShipments = async function (
  state,
  { api, warehouse, funderIds, startDate, endDate, programs, dryRun = false }
) {
  if (
    !warehouse ||
    warehouse === '' ||
    !funderIds ||
    funderIds.length === 0 ||
    !programs ||
    programs.length === 0
  ) {
    throw new Error('Missing warehouse or funder or program')
  }

  const isStateUser = state.user.roles.includes('feature:userRole:psm-state')
  // Get all locations
  const locations = await api.location.listAll({
    dontFilterServices: true,
    includeInactive: true
  })
  const locationsById = keyBy(locations, '_id')

  // We generate all the {funderId, programId} combinations
  const funderProgramCombinations = funderIds
    .map((funderId) => programs.map((programId) => ({ funderId, programId })))
    .flat()

  const warehousesIds = funderProgramCombinations.reduce(
    (acc, { funderId, programId }) => {
      const warehouseId = warehouseCodeToVirtualId({
        warehouseCode: warehouse,
        funderId,
        programId
      })

      if (acc.includes(warehouseId)) {
        return [...acc]
      }

      return [...acc, warehouseId]
    },
    []
  )

  // Get all shipments for that warehouse

  const shipmentPromises = warehousesIds.map((warehouseId) =>
    // Format dates to account for last second of selected end date
    api.shipment.find({
      location: warehouseId,
      startdate: startOfDay(new Date(startDate)).toISOString(),
      enddate: endOfDay(new Date(endDate)).toISOString()
    })
  )
  const shipments = (await Promise.all(shipmentPromises)).flat()

  const userState = isStateUser
    ? state.user.location.state ||
      get(locationIdToProperties(state.user.location.id), 'state', null)
    : null

  // Filter for open shipments and by specified funder
  // Get all columns from filtered shipments
  const matchingShipments = shipments.filter((shipment) => {
    return (
      shipment.status === 'new' &&
      funderIds.includes(shipment.funderId) &&
      (userState === null || get(shipment, 'destination.state') === userState)
    )
  })

  const shipmentVolumeAndPrices = await Promise.all(
    matchingShipments.map((shipment) =>
      api.shipment.calculateVolumeAndPrice(shipment, api)
    )
  )

  const shipmentRows = matchingShipments.map((shipment, index) => {
    const destination = shipment.destination.id
    const programId = shipment.programId
    const location = get(locationsById, destination)
    const subOrderId = shipment.orderId.split('suborderId:')[1]
    const volume = shipmentVolumeAndPrices[index].totalVolume
    return [
      get(location, 'additionalData.warehouseCode', ''),
      get(location, 'name'),
      get(location, 'location.state'),
      get(location, 'location.lga'),
      get(location, 'additionalData.ward'),
      subOrderId,
      shipment.funderId.split('funder:')[1],
      programId,
      shipment.id,
      volume
    ]
  })
  const shipmentLocations = [SHIPMENTEXPORTHEADERS, ...shipmentRows]

  if (dryRun) return shipmentLocations
  // Generate excel sheet
  return generateExcelSheet(shipmentLocations, 'Warehouse shipment export')
}
module.exports = { exportWareHouseFunderShipments }
