import get from 'lodash/get'

import capitalizeAllWords from '@fielded/shared-ui/src/utils/capitalizeAll'
import { locationIdToProperties } from '@fielded/fs-api/lib/tools'

import { getPSMDriverDeliveryTitle } from '../../PSMDriverDelivery/utils'
import { hasProductQuantityDiscrepancies } from '../../../../common/makeDeliveryItems'

export const VERIFICATION_STATUS = {
  ALL: 'all',
  UNVERIFIED: 'unverified' // Flagged
}

export const getSubViewLinks = (view, history, withViewChangeControls) => {
  const baseLink = '/shipments/last-mile-deliveries'

  if (!withViewChangeControls) {
    return []
  }

  return [
    {
      title: 'List view',
      active: view === 'list',
      path: {
        pathname: `${baseLink}/list`,
        search: history.location.search
      }
    },
    {
      title: 'Map view',
      active: view === 'map',
      path: {
        pathname: `${baseLink}/map`,
        search: history.location.search
      }
    }
  ]
}

export const getShipmentsWithGeolocationData = (shipments, locationsById) => {
  if (!shipments.length) {
    return []
  }

  return shipments
    .map((shipment, index) => {
      const locationId = shipment.destination.id
      const locationAdditionalData = locationsById[locationId] ? locationsById[locationId].additionalData : { latLng: '' }
      const latLng = locationAdditionalData.latLng

      if (latLng) {
        const [latitude, longitude] = latLng.split(',')

        // we need this because the default value of latLng is 'null, null'
        if (Number(latitude) && Number(longitude)) {
          const { location: facilityName } = getPSMDriverDeliveryTitle(shipment)
          return {
            ...shipment,
            facilityName,
            locationAdditionalData: {
              ...locationAdditionalData,
              latitude: parseFloat(latitude),
              longitude: parseFloat(longitude)
            }
          }
        }
      }
    })
    .filter(shipment => Boolean(shipment))
}

export const getStatusList = (geoData) => {
  const statuses = {}
  const statusList = []

  if (!geoData.length) {
    return statusList
  }

  geoData.map(({ properties }) => {
    statuses[properties.status] = statuses[properties.status] + 1 || 1
  })

  for (let status in statuses) {
    statusList.push({
      statusCount: statuses[status],
      status
    })
  }

  return statusList
}

export const getSegmentPercent = (count, total) => {
  return ((count / total) * 100) / 100
}

export const getDeliveryAlerts = (delivery) => {
  const hasQuantityDiscrepancies = hasProductQuantityDiscrepancies(delivery)
  const alertConfig = [
    {
      label: 'Delayed',
      alert: false // TODO: add logic to know if the shipment was delayed (feature not ready yet)
    },
    {
      label: 'Rescheduled',
      alert: false // TODO: add logic to know if the shipment was rescheduled (feature not ready yet)
    },
    {
      label: 'Location verification flagged',
      alert: !!(get(delivery, 'gpsValidationFailComment.reason', ''))
    },
    {
      label: 'OTP validation flagged',
      alert: !!(get(delivery, 'otp.userFailReason', ''))
    },
    {
      label: 'Discrepancies in quantity delivered',
      alert: hasQuantityDiscrepancies
    }
  ]

  return alertConfig.filter(data => data.alert)
}

export const decorateAndFilterShipmentsWithAlerts = (shipments, shouldFilter = false) => {
  let decoratedShipments = shipments.map(shipment => {
    return { ...shipment, deliveryAlerts: getDeliveryAlerts(shipment) }
  })

  if (shouldFilter) {
    decoratedShipments = decoratedShipments.filter(shipment => shipment.deliveryAlerts.length)
  }

  return decoratedShipments
}

export const getBackupLocationName = (locationId) => {
  const { sdp } = locationIdToProperties(locationId)
  const backupLocation = capitalizeAllWords(sdp)
  return backupLocation
}
