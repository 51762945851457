import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import flowRight from 'lodash/flowRight'
import get from 'lodash/get'

import { Loading } from '@fielded/shared-ui'

import { withApi } from '../../../../../../common/ApiProvider'
import { withUser } from '../../../../../../common/AuthenticationProvider'
import withConfig from '../../../../../../van-shared/hoc/withConfig'
import { clearGeolocationWatch, getGeolocation } from '../../../../../../utils/geolocation'
import { PERMISSIONS, getPermission, PERMISSION_STATES } from '../../../../../../utils/permissions-service'
import { onClearGeolocation, selectGeolocation } from '../../../../../../root/reducers/shipments/geolocation'
import PSMDeliveryLocation from './PSMDeliveryLocation'
import { isEPNWarehouse } from '../../../../common/utils'

const PSMDeliveryLocationContainer = ({
  api,
  user,
  config,
  match,
  history
}) => {
  const dispatch = useDispatch()
  const shipments = useSelector(state => state.shipments)
  const geolocationData = useSelector(selectGeolocation)

  const [locationObject, setLocationObject] = useState()
  const [isBottomDrawerOpen, setIsBottomDrawerOpen] = useState(false)
  const [distance, setDistance] = useState()
  const [loading, setLoading] = useState(true)
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false)
  const [isRequestingPermission, setIsRequestingPermission] = useState(false)

  const { snapshotId } = useParams()
  const shipment = shipments[snapshotId]

  const isWarehouseUser = isEPNWarehouse({user, config})

  const {
    geoPosition,
    position,
    newPermission,
    positionErrorMsg,
    watchId
  } = geolocationData

  const otpVerified = get(shipment, 'otp.hasKeyAndPassword', false)
  const otpKey = get(shipment, 'otp.key', {})

  const skipGPSValidation = get(shipment, 'isGpsAuthenticated', false) || !!(get(shipment, 'gpsValidationFailComment.reason', false))
  const skipGPSAndOTPValidation = skipGPSValidation && otpVerified

  const onOpenBottomDrawer = () => {
    setIsBottomDrawerOpen(true)
  }

  const onCloseBottomDrawer = () => {
    setIsBottomDrawerOpen(false)
  }

  const onOpenPermissionModal = () => {
    setIsPermissionModalOpen(true)
  }

  const onClosePermissionModal = () => {
    setIsRequestingPermission(false)
    setIsPermissionModalOpen(false)
  }

  const onRequestLocationPermission = async () => {
    setIsRequestingPermission(true)

    await onGetGeolocation(newPermission, locationObject)
    onClosePermissionModal()
  }

  const onClearGeolocationWatch = () => {
    if (watchId) {
      clearGeolocationWatch(watchId)
      dispatch(onClearGeolocation())
    }
  }

  const onGetCurrentDistance = async () => {
    // using this here because some of these test shipments dont have any
    // location object (ie locationObject = undefined)
    const facilityCoordsMock = '6.4926317,3.489617'

    const facilityPosition = (get(locationObject, 'additionalData.latLng', '') || facilityCoordsMock).split(',')
    const [latPositionFacility, lngPositionFacility] = facilityPosition

    try {
      const distance = await api.shipment.calculateDistance(
        { lat: position.lat, long: position.lng }, // from point A coordinates
        { lat: Number(latPositionFacility), long: Number(lngPositionFacility) } // to point B coordinates
      )

      setDistance(distance)
    } catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }

  const onGetGeolocation = async (permission, locationObject) => {
    const configGeolocationOptions = {
      maximumAge: 5 // five seconds
    }

    // We need to pass dispatch in order to update the redux store
    try {
      const result = await getGeolocation(permission, dispatch, configGeolocationOptions)

      // only watch position if permission is granted after initial prompt
      if (result.newPermission === PERMISSION_STATES.GRANTED && result.newPermission !== permission) {
        await getGeolocation(result.newPermission, dispatch, configGeolocationOptions)
      }
    } catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
      if (permission === PERMISSION_STATES.PROMPT && !watchId) {
        onOpenBottomDrawer()
      }
    }
  }

  const onHandleGetGeolocation = async () => {
    const locationId = get(shipment, 'destination.id', '')
    let permission

    try {
      const locationObject = await api.location.get(locationId)
      permission = await getPermission(PERMISSIONS.GEOLOCATION)

      setLocationObject(locationObject)

      if (permission && permission !== PERMISSION_STATES.PROMPT) {
        await onGetGeolocation(permission, locationObject)
      }
    } catch (error) {
      console.warn(error)
    } finally {
      if (permission === PERMISSION_STATES.PROMPT) {
        setLoading(false)
      }
    }
  }

  const onHandleGetCurrentDistance = async () => {
    if (position.lat && position.lng) {
      await onGetCurrentDistance()
    }
  }

  useEffect(() => {
    onHandleGetGeolocation()
  }, [])

  useEffect(() => {
    onHandleGetCurrentDistance()
  }, [position.lat, position.lng])

  useEffect(() => {
    return () => onClearGeolocationWatch()
  }, [watchId])

  if (skipGPSAndOTPValidation) {
    const shipmentId = get(shipment, 'id')
    return <Redirect to={`/shipments/pick-list/${shipmentId}`} />
  }

  if (loading) {
    return <Loading loadingText='Loading facility details...' />
  }

  return (
    <PSMDeliveryLocation
      api={api}
      otpKey={otpKey}
      otpVerified={otpVerified}
      skipGPSValidation={skipGPSValidation}
      history={history}
      watchId={watchId}
      shipment={shipment}
      permission={newPermission}
      geoPosition={geoPosition}
      position={position}
      distance={distance}
      locationObject={locationObject}
      positionErrorMsg={positionErrorMsg}
      isBottomDrawerOpen={isBottomDrawerOpen}
      isPermissionModalOpen={isPermissionModalOpen}
      isRequestingPermission={isRequestingPermission}
      onOpenBottomDrawer={onOpenBottomDrawer}
      onCloseBottomDrawer={onCloseBottomDrawer}
      onGetGeolocation={onGetGeolocation}
      onOpenPermissionModal={onOpenPermissionModal}
      onClosePermissionModal={onClosePermissionModal}
      onRequestLocationPermission={onRequestLocationPermission}
      isWarehouseUser={isWarehouseUser}
    />
  )
}

const withHOCs = flowRight(
  withApi,
  withUser,
  withConfig
)

export default withHOCs(PSMDeliveryLocationContainer)
