const groupBy = require('lodash/groupBy')
const get = require('lodash/get')
const set = require('lodash/set')
const uuid = require('uuid/v4')
const smartId = require('../../tools/smart-id')
const keyBy = require('lodash/keyBy')
const ROUTE_END_DATE_DAYS = 30

const warehouseNotFound = (locationIds, locations) => {
  return locationIds.filter(warehouseCode => !locations.find(loc => get(loc, 'additionalData.warehouseCode') === warehouseCode))
}

async function parseShipmentUploadData (rows, locationApi, configurationApi, assignedProgramId, assignedFunderId) {
  const supplierGroups = groupBy(rows, 'originwarehousecode')
  const supplierLocationIds = Object.keys(supplierGroups)
  const supplierLocations = await locationApi.getByWarehouseCodes(supplierLocationIds)
  const suppliersByWarehouseCode = keyBy(supplierLocations, 'additionalData.warehouseCode')
  const notFound = warehouseNotFound(supplierLocationIds, supplierLocations)
  if (notFound.length) {
    return {
      type: 'error',
      status: 404,
      message: 'Origin location not found',
      locationIds: notFound
    }
  }

  const nonProgramSupplier = supplierLocations.reduce((acc, loc) => {
    const programs = get(loc, 'programs', []).map(p => p.id)
    if (!programs.includes(assignedProgramId)) {
      acc.push(loc._id)
    }
    return acc
  }, [])

  if (nonProgramSupplier.length) {
    return {
      type: 'error',
      status: 400,
      message: 'Origin location not assigned to users default program',
      locationIds: nonProgramSupplier
    }
  }

  const groupedShipments = groupBy(rows, (row) => `${row.destinationwarehousecode}_${row.originwarehousecode}`)
  const duplicateShipmentPairExternalIds = Object.keys(groupedShipments).map(s => {
    return {
      origin: groupedShipments[s][0].originwarehousecode,
      destination: groupedShipments[s][0].destinationwarehousecode,
      externalIds: [...new Set(groupedShipments[s].map(r => r.externalid))]
    }
  }).filter(s => s.externalIds.length > 1)

  if (duplicateShipmentPairExternalIds.length) {
    return {
      type: 'error',
      status: 400,
      message: 'Shipment pairs with multiple external Ids not allowed',
      shipments: duplicateShipmentPairExternalIds
    }
  }

  const notFoundDestinations = []
  const invalidSupplierState = []
  const notFoundConfigurationDoc = []
  const missingProductsInConfig = []
  const productProgramMap = new Map()
  const productService = new Set()
  const destinationNameById = {}
  let destinationByWarehouseCode = {}
  let hasMultipleProductServices = false
  for (const supplier of supplierLocations) {
    const warehouseCode = get(supplier, 'additionalData.warehouseCode', '')
    // check supplier is assigned to given destinations region
    const destinationIds = new Set(supplierGroups[warehouseCode].map(r => r.destinationwarehousecode))
    const destinations = await locationApi.getByWarehouseCodes([...destinationIds])
    const destinationByWarehouse = keyBy(destinations, 'additionalData.warehouseCode')
    destinationByWarehouseCode = {...destinationByWarehouseCode, ...destinationByWarehouse}
    const notFound = warehouseNotFound([...destinationIds], destinations)
    if (notFound.length) {
      notFoundDestinations.push(...notFound)
      break
    }

    const supplierPrograms = get(supplier, 'programs', []).map(p => p.id)
    // Only get service configuration ids for default
    const configurationIds = get(supplier, 'services', [])
      .filter(s => s.startsWith(assignedProgramId))
      .map(id => `configuration:${id}`)
    // this won't fail if some configs are missing and not all
    const supplierConfigs = await configurationApi.getByIds(configurationIds)
    if (!supplierConfigs.length) {
      notFoundConfigurationDoc.push(supplier._id)
      break
    }

    // check products exist in the supplier config product array
    supplierConfigs.forEach(c => {
      (c.products || []).forEach(p => productProgramMap.set(p, `${c.service}`))
    })
    supplierGroups[warehouseCode]
      .forEach(r => {
        const service = productProgramMap.get(`product:${r.product}`)
        if (service) {
          productService.add(service)
        } else {
          missingProductsInConfig.push(r.product)
        }
      })

    if (missingProductsInConfig.length) {
      break
    }

    if (productService.size > 1) {
      hasMultipleProductServices = true
      break
    }

    const supplierStateSdps = get(supplier, 'relationshipRules.suppliesStateSDPs', [])
    const invalidSupplier = destinations
      .filter(d => {
        // Kenyan locations don't have the :lga property in their fsid, so we split by 'sdp' if no lga
        const { lga } = smartId.parse(d._id)
        let splitValue = ':lga'
        if (!lga) {
          splitValue = ':sdp'
        }
        const destinationPrograms = get(d, 'programs', []).map(p => p.id)
        return !supplierStateSdps.includes(d._id.split(splitValue)[0]) || destinationPrograms.every(p => !supplierPrograms.includes(p))
      })
      .map(d => d._id)
    if (invalidSupplier.length) {
      invalidSupplierState.push({ supplier: supplier._id, locationIds: invalidSupplier })
    }

    destinations.forEach(location => {
      const routeUuid = uuid()
      destinationNameById[location.additionalData.warehouseCode] = {
        name: location.name,
        routeUuid
      }
    })
  }

  if (notFoundDestinations.length) {
    return {
      type: 'error',
      status: 404,
      message: 'Destination locations not found',
      locationIds: notFoundDestinations
    }
  }

  if (hasMultipleProductServices) {
    return {
      type: 'error',
      status: 400,
      message: 'Only products from a single program expected in csv file import'
    }
  }

  if (notFoundConfigurationDoc.length) {
    return {
      type: 'error',
      status: 404,
      message: 'No warehouse configuration doc found for origin location',
      locationIds: notFoundConfigurationDoc
    }
  }

  if (missingProductsInConfig.length) {
    return {
      type: 'error',
      status: 404,
      message: 'Products not found in origin destination configuration',
      productIds: missingProductsInConfig
    }
  }

  if (invalidSupplierState.length) {
    return {
      type: 'error',
      status: 400,
      message: 'Invalid supplier to destination relationship',
      locations: invalidSupplierState
    }
  }

  const currentDate = new Date()
  const endDate = new Date()
  endDate.setDate(endDate.getDate() + ROUTE_END_DATE_DAYS)

  const { parsedRows, shipmentRoutes } = rows.reduce((acc, val) => {
    let routeName = `${destinationNameById[val.destinationwarehousecode].name} - ${val.externalid}`
    let parsedRow = {
      ...val,
      'allocation-method': 'directOrder',
      'supplier': get(suppliersByWarehouseCode, val.originwarehousecode, {})['_id'],
      id: get(destinationByWarehouseCode, val.destinationwarehousecode, {})['_id'],
      'direct order': val.quantity,
      funderId: assignedFunderId,
      externalShipmentId: val.externalid,
      routeId: `route:${destinationNameById[val.destinationwarehousecode].routeUuid}`,
      programId: assignedProgramId
    }

    if (!acc.shipmentRoutes[routeName]) {
      set(acc.shipmentRoutes, routeName, {
        useUUID: true,
        name: routeName,
        id: destinationNameById[val.destinationwarehousecode].routeUuid,
        programs: set({}, `${assignedProgramId}.services`, [...productService]),
        startDate: currentDate.toJSON().substring(0, 10),
        endDate: endDate.toJSON().substring(0, 10)
      })
    }

    if (!acc.parsedRows[assignedFunderId]) {
      set(acc.parsedRows, assignedFunderId, [parsedRow])
    } else {
      acc.parsedRows[assignedFunderId].push(parsedRow)
    }
    return acc
  }, { parsedRows: {}, shipmentRoutes: {} })

  return {
    funderShipments: parsedRows,
    routes: Object.values(shipmentRoutes)
  }
}

module.exports = parseShipmentUploadData
