import React, { Fragment, useState } from 'react'
import classnames from 'classnames'

import { Banner, Button, Form, Page, Text, TextInput } from '@fielded/shared-ui'
import { Arrow, ExclamationTriangle } from '@fielded/shared-ui/src/icons'

import { hasProductQuantityDiscrepancies } from '../../common/makeDeliveryItems'
import ShipmentContentsTablePSM from '../../components/ShipmentContentsTablePSM'

const PSMDeliveryConfirmation = ({
  items,
  name,
  comment,
  signature,
  formRef,
  onCommentChange,
  onProceed
}) => {
  const [hasError, setHasError] = useState(false)

  const hasQuantityDiscrepancies = hasProductQuantityDiscrepancies(null, items)

  const pageTitle = 'Confirm quantities to be delivered'
  const signatureInfoText = (
    <div>
      <Text bold size='small'>
        Please confirm delivery and acknowledge receipt of the products listed above from Field Technology Development Partners LTD by signing on behalf of the company here below:
      </Text>
      <Text className='vs-u-margin-top'>
        Facility manager's signature
      </Text>
    </div>
  )

  const onHandleCommentChange = (event) => {
    setHasError(false)
    onCommentChange(event)
  }

  const onHandleProceed = async () => {
    const formValidationErrors = await formRef.current.validate()
    const hasNoComments = hasQuantityDiscrepancies && !comment

    if (formValidationErrors || hasNoComments) {
      if (hasNoComments) {
        setHasError(true)
      }
      return
    }
    onProceed()
  }

  return (
    <Fragment>
      {hasQuantityDiscrepancies && (
        <Page.Panel reduceBottomMargin>
          <Banner
            title='Quantity Mismatch'
            type='warning'
            center={false}
            icon={<ExclamationTriangle />}
          >
            Some product quantities delivered do not match the quantities packed
          </Banner>
        </Page.Panel>
      )}
      <Page.Intro title={pageTitle} />
      <Page.Panel withBackground>

        <Page.Panel.Section fullWidth>
          <ShipmentContentsTablePSM
            items={items}
            withCode={false}
            withBasicUnit={false}
            withBatch
            withDetailsPopup
            withIcon
          />
        </Page.Panel.Section>

        <Page.Panel.Section className='confirm-pick-up__form' fullWidth>
          <Form ref={formRef}>
            <Form.Row>
              <Form.Field
                fieldName='name'
                labelText="Facility manager's name"
                spread
              >
                <Form.Field.Text
                  disabled={signature || name}
                  defaultValue={name}
                  required={'Please enter a name'}
                />
                <Form.Field.ValidationErrors />
              </Form.Field>
            </Form.Row>

            <Form.Row>
              <Form.Field
                labelText={signatureInfoText}
                fieldName='signature'
                spread
              >
                <Form.Field.Signature
                  disabled={signature}
                  defaultValue={signature}
                  required={'Please sign'}
                />
                <Form.Field.ValidationErrors />
              </Form.Field>
            </Form.Row>
          </Form>
        </Page.Panel.Section>

        <Page.Panel.Section
          fullWidth
          className='sl-delivery-confirmation__signature'
        >
          <Text tag='p'>
            Please leave a comment <Text
              className={classnames({'sl-delivery-confirmation__comment': hasQuantityDiscrepancies})} tag='span'>{hasQuantityDiscrepancies && '*'}</Text>
          </Text>
          <TextInput
            className='confirmation-commment__input'
            name='comment'
            value={comment}
            onChange={(event) => onHandleCommentChange(event)}
            rows={5}
            hasError={hasError}
          />
          {hasError && <Text className='sl-delivery-confirmation__comment' size='small' tag='span'>Comment required due to quantity mismatch</Text>}
        </Page.Panel.Section>
      </Page.Panel>

      <Page.Footer layout='centered'>
        <Button
          fill='full'
          size='large'
          colorVariant='confirmation'
          iconSide='right'
          icon={<Arrow direction='right' />}
          onClick={onHandleProceed}
        >
          Proceed
        </Button>
      </Page.Footer>
    </Fragment>
  )
}

export default PSMDeliveryConfirmation
