const { ProxifiedEntityApi } = require('../common')
const { VEHICLE_CLASS } = require('../routes/tools')
const CarrierPGAdapter = require('./data-access/carrier-pg-adapter')

class CarrierApi extends ProxifiedEntityApi {
  constructor (state, pgConnection, agaveAdapter, logger) {
    const { user } = state

    const adapter = new CarrierPGAdapter(logger, pgConnection, user.name)
    super(
      'carrier',
      ['create', 'update', 'list', 'get'],
      !pgConnection,
      adapter,
      agaveAdapter
    )

    this.adapter = adapter
  }

  // List carriers operating in a given state
  async listCarriersByState ({ state } = {}) {
    const { organisations } = await this.list()
    return organisations.reduce((acc, org) => {
      const { organisation, fleetCategories, operationalStates } = org
      const priority = state
        ? (operationalStates.find(s => s.state === state) || {}).priority
        : undefined
      const fleet = fleetCategories.map(vehicule => {
        const { name: category, displayName: name, maxVolume } = VEHICLE_CLASS.find(vc => vc.name === vehicule.category) || {}
        return {
          category,
          name,
          maxVolume
        }
      })
      return [
        ...acc,
        ...(priority ? [{ ...organisation, fleet, priority }] : [])
      ]
    }, [])
  }
}

module.exports = CarrierApi
