import React, { Fragment } from 'react'
import { ExportButton, Page, Text } from '@fielded/shared-ui'

const PSMInboundShipmentsOverviewDownload = ({
  isDownloading,
  onDownloadTemplate
}) => {
  return (
    <Fragment>
      <Page.Panel.Section>
        <Text.Heading>Download shipment template</Text.Heading>
        <Text>
          Click on the button below to download the Excel template for your shipment import. Ensure you do not modify the structure of the template to avoid upload errors.
        </Text>
      </Page.Panel.Section>
      <Page.Panel.Section>
        <ExportButton
          buttonText='Download template'
          loading={isDownloading}
          filename={`shipments-${new Date().toJSON()}.xlsx`}
          onExport={onDownloadTemplate}
        />
      </Page.Panel.Section>
    </Fragment>
  )
}

export default PSMInboundShipmentsOverviewDownload
