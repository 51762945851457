import React from 'react'

import { BackButton, Button, Card, DeemphasizedLink, Page } from '@fielded/shared-ui'

import { formatDate, toTitleCase } from '../../../van-shared/utils'

const DeleteShipment = ({
  shipment,
  onConfirmDelete,
  deleting,
  history,
  backToOverview,
  redirectLink
}) => {
  const destination = toTitleCase(shipment.destination.id.split(':').pop())
  const formattedDate = formatDate(shipment.date, 'long')

  const onHandleCancelAction = () => {
    if (redirectLink) {
      return history.goBack()
    }
    history.push(backToOverview ? `/shipments/` : `/shipments/pick-list/${shipment.snapshotId}`)
  }

  return (
    <Page situation='danger'>
      <Page.Header
        backButton={<BackButton history={history} />}
      />
      <Page.Panel narrow>
        <Card>
          <Card.Header title={`Do you want to delete this shipment?`}>
            This will remove shipment <strong>{shipment.shipmentNo}</strong> to <strong>{destination}</strong> created on <strong>{formattedDate}</strong>.
            This action cannot be undone.
          </Card.Header>
          <Card.Action centered>
            <DeemphasizedLink
              onClick={() => onHandleCancelAction()}
            >
                Cancel and go back
            </DeemphasizedLink>
            <Button
              loading={deleting}
              loadingLabel='Deleting shipment...'
              colorVariant='danger'
              fill='full'
              onClick={onConfirmDelete}
            >
              Yes, delete it
            </Button>
          </Card.Action>
        </Card>
      </Page.Panel>
    </Page>
  )
}

export default DeleteShipment
