const { ProxifiedEntityApi } = require('../common')
const OrganisationPGAdapter = require('./data-access/organisation-pg-adapter')

class OrganisationApi extends ProxifiedEntityApi {
  constructor (state, pgConnection, agaveAdapter, logger) {
    const { user } = state

    const adapter = new OrganisationPGAdapter(logger, pgConnection, user.name)
    super(
      'organisation',
      ['create', 'update', 'list', 'get'],
      !pgConnection,
      adapter,
      agaveAdapter
    )

    this.adapter = adapter
  }
}

module.exports = OrganisationApi
