import React from 'react'
import { Page } from '@fielded/shared-ui'

import { getNavigationItem } from '../../../../../common/utils/navigationInfo'
import { shipmentTitle } from '../../../common/utils'
import PSMInboundShipmentsEmptyState, { UploadShipmentLink } from './PSMInboundShipmentsEmptyState'
import PSMDeliveryCard from '../shared/PSMDeliveryCard/PSMDeliveryCard'

const PSMInboundShipments = ({
  config,
  shipments,
  history
}) => {
  return (
    <Page className='shipments'>
      <Page.ComposableHeader>
        <Page.ComposableHeader.Breadcrumb
          items={[getNavigationItem({ item: 'shipments', config, includePath: false })]}
        />
        <Page.ComposableHeader.Content spread>
          <Page.ComposableHeader.Title title='Inbound shipments' />
        </Page.ComposableHeader.Content>
      </Page.ComposableHeader>

      <Page.Panel narrow alignLeft>
        {shipments.length ? (
          <section>
            <section className='vs-u-row vs-u-justify-end'>
              <UploadShipmentLink />
            </section>

            {shipments.map(shipment => {
              const headerTitle = shipmentTitle(shipment, config)
              return (
                <PSMDeliveryCard
                  key={shipment.snapshotId}
                  headerTitle={headerTitle}
                  history={history}
                  shipment={shipment}
                  statusText='received'
                  target={`/shipments/list/${shipment.snapshotId}`}
                />
              )
            })}
          </section>
        ) : (
          <PSMInboundShipmentsEmptyState />
        )}
      </Page.Panel>
    </Page>
  )
}

export default PSMInboundShipments
