import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { DeemphasizedLink, PriceDisplay, QuantityDisplay, Text, TotalAmount } from '@fielded/shared-ui'
import { ExclamationTriangle } from '@fielded/shared-ui/src/icons'

import { hasProductQuantityDiscrepancies } from '../../common/makeDeliveryItems'
// import { formatDate } from '../../../../van-shared/utils'
import ShipmentContentsTablePSMPopup from './ShipmentContentsTablePSMPopup'

const getCalculatedColCount = ({colCount, withCode, withBasicUnit, displayIcon, withDetailedColumns}) => {
  if (!withCode) colCount -= 1
  if (!withBasicUnit) colCount -= 1
  if (displayIcon) colCount += 1
  // TODO: Change when adding expiry
  // price, total, manufacturer
  if (withDetailedColumns) colCount += 3
  return colCount
}

const ShipmentContentsTablePSM = ({
  items,
  type,
  withCode,
  withBasicUnit,
  withBatch,
  withIcon,
  withDetailsPopup,
  withDetailedColumns
}) => {
  const [viewedProduct, setViewedProduct] = useState()
  const hasQuantityDiscrepancies = hasProductQuantityDiscrepancies(null, items)
  const displayIcon = hasQuantityDiscrepancies && withIcon

  const isDelivery = type === 'delivery'
  let propToAdd = isDelivery ? 'delivered' : 'packed'
  if (withDetailedColumns) {
    propToAdd = 'deliveredValue'
  }

  const totalValue = items.reduce((acc, item) => {
    const value = item[propToAdd] || 0
    acc = acc + value
    return acc
  }, 0)
  const isEmpty = !items || items.length === 0
  const colCount = isDelivery ? 5 : 4
  const colCountProcessed = getCalculatedColCount({ colCount, withCode, withBasicUnit, displayIcon, withDetailedColumns })

  const onViewInformation = (product) => {
    setViewedProduct(product)
  }

  const onHideInformation = () => {
    setViewedProduct()
  }

  return (
    <Fragment>
      <table className='vs-u-table vs-u-table--deemphasized vs-u-table--rows-distinctive'>
        <thead>
          <tr className='vs-u-table__header'>
            {withCode && (
              <th className='vs-u-table__header-cell'>
              Code
              </th>
            )}
            <th className='vs-u-table__header-cell'>
            Product
            </th>
            {withBasicUnit && (
              <th className='vs-u-table__header-cell'>
              Basic unit
              </th>
            )}
            <th className='vs-u-table__header-cell vs-u-table__header-cell--right'>
            Packed
            </th>
            {isDelivery && (
              <th className='vs-u-table__header-cell vs-u-table__header-cell--right'>
              Delivered
              </th>
            )}
            {withDetailedColumns && (
              <Fragment>
                <th className='vs-u-table__header-cell'>
                  Manufacturer
                </th>
                {/* <th className='vs-u-table__header-cell'>
                  Expiry
                </th> */}
                <th className='vs-u-table__header-cell'>
                  Price
                </th>
                <th className='vs-u-table__header-cell vs-u-table__header-cell--right'>
                  Total
                </th>
              </Fragment>
            )}
            {displayIcon && (
              <th className='vs-u-table__header-cell vs-u-table__header-cell--right vs-u-hide-visually'>
              Alert information
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {!isEmpty ? items.map((item) => {
            const hasAlert = item.delivered !== item.packed

            return (
              <tr className={classnames(
                'vs-u-table__row',
                {'shipment-contents-table__row': hasAlert}
              )} key={item.code}>
                {withCode && (
                  <td className='vs-u-table__cell'>
                    <Text bold>{item.code}</Text>
                  </td>
                )}
                <td className='vs-u-table__cell'>
                  <div className='vs-u-column'>
                    {withDetailsPopup ? (
                      <DeemphasizedLink
                        colorVariant='brand'
                        bold
                        onClick={() => onViewInformation(item)}
                      >
                        {item.name}
                      </DeemphasizedLink>
                    ) : (
                      <Text bold>{item.name}</Text>
                    )}
                    {withBatch && (
                      <Text size='xsmall'>
                        Batch: <Text tag='span' bold>{item.batchNo}</Text>
                      </Text>
                    )}
                  </div>
                </td>
                {withBasicUnit && (
                  <td className='vs-u-table__cell'>
                    {item.basicUnit}
                  </td>
                )}
                <td className='vs-u-table__cell vs-u-table__cell--right'>
                  <QuantityDisplay value={item.packed} />
                </td>
                {isDelivery && (
                  <td className='vs-u-table__cell vs-u-table__cell--right'>
                    <QuantityDisplay value={item.delivered} />
                  </td>
                )}
                {withDetailedColumns && (
                  <Fragment>
                    <td className='vs-u-table__cell'>
                      {item.manufacturer}
                    </td>
                    {/* <td className='vs-u-table__cell'>
                      {formatDate(item.expiry, 'expiry')}
                    </td> */}
                    <td className='vs-u-table__cell'>
                      <PriceDisplay
                        value={item.price}
                        roundUp={false}
                        currencySymbol
                      />
                    </td>
                    <td className='vs-u-table__cell vs-u-table__cell--right'>
                      <PriceDisplay
                        value={item.deliveredValue}
                        roundUp={false}
                        currencySymbol
                      />
                    </td>
                  </Fragment>
                )}
                {displayIcon && (
                  <td className='vs-u-table__cell'>
                    {hasAlert && (
                      <div className='shipment-contents-table__icon'>
                        <ExclamationTriangle />
                      </div>
                    )}
                  </td>
                )}
              </tr>
            )
          }) : (
            <tr>
              <td colSpan={colCountProcessed}>
                <Text
                  bold
                  className='vs-u-margin-top vs-u-margin-bottom vs-u-row-centered'
                >
                There are no products in this delivery
                </Text>
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <TotalAmount
            value={totalValue}
            asTableRow
            totalColumns={displayIcon ? colCountProcessed - 1 : colCountProcessed}
            label={withDetailedColumns ? 'TOTAL PRICE' : 'TOTAL QUANTITY'}
            isSecondary
            bold
            roundUp={false}
            qtyOnly={!withDetailedColumns}
          />
        </tfoot>
      </table>
      <ShipmentContentsTablePSMPopup
        product={viewedProduct}
        onClose={onHideInformation}
      />
    </Fragment>
  )
}

ShipmentContentsTablePSM.propTypes = {
  /**
   * Array of delivery items (can be generated with makeDeliveryItems())
   */
  items: PropTypes.array,
  /**
   *  A type of displayed note
   */
  type: PropTypes.oneOf(['delivery', 'package']),
  /**
   * Display the product's code
   */
  withCode: PropTypes.bool,
  /**
   * Display the product's basic unit
   */
  withBasicUnit: PropTypes.bool,
  /**
   * Display the icon column
   */
  withIcon: PropTypes.bool,
  /**
   * Display Batch number
   */
  withBatch: PropTypes.bool,
  /**
   * Display a link to open the detailed information about item
   */
  withDetailsPopup: PropTypes.bool
}

ShipmentContentsTablePSM.defaultProps = {
  items: [],
  type: 'delivery',
  withCode: true,
  withBasicUnit: true,
  withIcon: false,
  withBatch: false,
  withDetailsPopup: false
}

export default ShipmentContentsTablePSM
