const { locationIdPrefixFor } = require('../../../tools')
const createGraph = require('../../../relationship-graph')
const { listAll } = require('./list-all')

exports.listChildren = async (state, id, {
  date,
  deep,
  includeSelf,
  filters,
  includeInactive = false,
  allLocations = null,
  graph = null,
  filterLevel
} = {}) => {
  const locationId = id || state.user.location.id
  if (!graph) {
    if (!allLocations) {
      const prefix = locationIdPrefixFor(locationId, filterLevel)
      // Performance note: we're getting full location
      // docs (through listAll, which in turn calls
      // state.dal.location.listAll) so that we can run
      // some checks to filter out retired and sometimes inactive
      // locations, construct the graph, etc. We should consider
      // dedicated endpoints that return only the data we need in each
      // case, filtering on the server side when possible
      // and constructing the graph there too.
      allLocations = await listAll(state,
        { date, includeInactive },
        { prefix }
      )
    }
    graph = createGraph(allLocations)
  }
  // Check if we have a location with the id
  const self = await graph.getLocation(locationId)

  if (self) {
    let suppliees
    if (deep) {
      suppliees = await graph.getDescendantSuppliees(self._id, filters)
    } else {
      suppliees = await graph.getSuppliees(self._id, filters)
    }
    return includeSelf ? [self, ...suppliees] : suppliees
  } else {
    // No location found, treat the id as a geolocation id
    const locationsInArea = await graph.getLocationsInGeoLocation(locationId, filters)
    return locationsInArea
  }
}
