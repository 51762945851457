/* eslint-disable max-lines */
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import orderBy from 'lodash/orderBy'
import flowRight from 'lodash/flowRight'

import { locationIdToProperties as toLocationProperties } from '@fielded/fs-api/lib/tools'
import { Loading } from '@fielded/shared-ui'

import { withUser } from '../../../common/AuthenticationProvider'
import withConfig from '../../../van-shared/hoc/withConfig'
import { hasFeature } from '../../../van-shared/utils/features'
import capitalize from '../../../common/utils/capitalize'
import {isReturnShipment} from '../../../common/utils/shipment'
import { withApi } from '../../../common/ApiProvider'
import { useOnlineOffline } from '../../../sync/common'
import { fetchMasterData, selectHasReceivedMasterData, selectProducts } from '../../../root/reducers/shipments/master-data'
import { addNoOpenerAttributeToShipments, addProductData, withRouteIds } from './shipment-utils'
import { PERIOD_BIMONTH, getCurrentPeriod, periodToDateRange } from '../../../common/periods'
import { queryStringifyFilters, resolveQueryStringFilterState } from '../../../common/querystring-filters'

import {
  findShipments,
  selectShipments,
  byCompleteAndDate,
  withRelevantBatchesFlag
} from '../../../root/reducers/shipments'

import {
  isShelflifePowered,
  byId,
  isDistributor,
  isGlobalUser,
  appendLocationType,
  isOperatorUser,
  isPlannerUser,
  isFpUser,
  isPSMVendor,
  isPSMFacility
} from '../common/utils'

import {
  routesFilter,
  sourcesFilter,
  destinationsFilter,
  marketsFilter
} from '../common/filters'

import ErrorView from '../components/LoadingError'
import OfflineWarning from '../common/OfflineWarning'
import Shipments from './Shipments'

const defaultInfo = { values: [], defaultValue: null }
const availableFiltersDefaultValues = {
  route: defaultInfo,
  market: defaultInfo,
  source: defaultInfo,
  destination: defaultInfo,
  pack: defaultInfo,
  deliveryDate: defaultInfo
}

const getPsmNationalStartDate = () => {
  // The startdate for finding shipments for psm national users is the start of the period
  const currPeriod = getCurrentPeriod(PERIOD_BIMONTH)
  const dateRange = periodToDateRange(currPeriod)
  return dateRange.from
}

const ShipmentsContainer = ({
  api,
  user,
  config,
  history,
  showCompleted
}) => {
  const dispatch = useDispatch()

  const shipmentsFromStore = useSelector(selectShipments)
  const hasReceivedMasterData = useSelector(selectHasReceivedMasterData)
  const productsFromMasterData = useSelector(selectProducts)

  const [shipments, setShipments] = useState([])
  const [hasReceivedShipments, setHasReceivedShipments] = useState(false)
  const [rawLocationDocs, setRawLocationDocs] = useState(null)
  const [funders, setFunders] = useState([])
  const [locationObject, setLocationObject] = useState()
  const [availableFilters, setAvailableFilters] = useState(availableFiltersDefaultValues)
  const [routesForFilter, setRoutesForFilter] = useState([])
  const [sourcesForFilter, setSourcesForFilter] = useState([])
  const [destinationsForFilter, setDestinationsForFilter] = useState([])
  const [marketsForFilter, setMarketsForFilter] = useState([])
  const [deliveryDatesForFilter, setDeliveryDatesForFilter] = useState([])
  const [loadedOnlineShipments, setLoadedOnlineShipments] = useState(false)
  const [offlineError, setOfflineError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const shipmentList = byCompleteAndDate(withRelevantBatchesFlag(shipmentsFromStore), user)

  const withListInGeoLocation = hasFeature(config.features, 'shipments:listInGeoLocation')
  const useRouteSpecificFilter = hasFeature(config.features, 'shipments:routeSpecificUsersHideNewShipments')

  const showImportLink = hasFeature(config.features, 'shipments:import')

  const showRemoveShipment = (
    hasFeature(config.features, 'shipments:removeShipmentInOverview') &&
    hasFeature(config.features, 'shipments:removeShipment')
  )

  const editPSMShipment = hasFeature(config.features, 'shipments:editPSMShipment')
  const displayPlanningType = hasFeature(config.features, 'shipments:showPlanningType')

  const hasCreateNewShipment = hasFeature(config.features, 'shipments:createNewShipment')
  const displayNewShipmentButton = !editPSMShipment && hasCreateNewShipment && (isFpUser(user) || isPlannerUser(user) || isOperatorUser(user))

  const userIsGlobal = isGlobalUser(user)
  const userAsDistributor = isDistributor(user)

  const products = get(productsFromMasterData, 'byId', {})
  const userLocation = user.location

  const initialize = async () => {
    const usesPGShipments = hasFeature(config, 'features.usePGShipments')

    const userPSMvendor = isPSMVendor(user)
    const userPSMFacility = isPSMFacility(user)

    const stateUser = toLocationProperties(userLocation.id).level === 'state'
    const location = userIsGlobal && !stateUser ? {id: 'country'} : userLocation

    const locationIsGeoLocation = !userIsGlobal && withListInGeoLocation

    const options = {
      userAsDistributor,
      online: userIsGlobal,
      startdate: userIsGlobal ? getPsmNationalStartDate() : null,
      shouldUsePGShipments: usesPGShipments && !(userPSMvendor || userPSMFacility || userIsGlobal)
    }

    try {
      console.time('findShipments')
      await dispatch(
        findShipments(
          location,
          locationIsGeoLocation,
          useRouteSpecificFilter,
          options
        )
      )
      console.timeEnd('findShipments')
    } catch (e) {
      setError(e)
      return
    }

    let funders = []
    let rawLocationDocs = null
    if (isShelflifePowered(config)) {
      // TODO: change this lookup we make drivers come from a real job entity instead of a location doc's funder
      const rawLocationDocsList = await api.location.listAllRaw()
      rawLocationDocs = byId(rawLocationDocsList, '_id')
    } else {
      funders = await api.funders.list()
    }

    const locationObject = await api.location.get(userLocation.id)

    if (!hasReceivedMasterData) {
      await dispatch(fetchMasterData())
    }

    await initializeShipments(rawLocationDocs)

    setRawLocationDocs(rawLocationDocs)
    setLocationObject(locationObject)
    setHasReceivedShipments(true)
    setFunders(funders)
    setLoading(false)
  }

  const initializeShipments = async (rawLocationDocs) => {
    const decoratedShipments = isShelflifePowered(config) ? withRouteIds(rawLocationDocs, shipmentList) : shipmentList

    let shipmentsComplete = decoratedShipments.complete
    let shipmentsIncomplete = decoratedShipments.incomplete

    // if user is an operator, they should only be able to open shipments originating from their held or territory
    if (isOperatorUser(user)) {
      shipmentsComplete = addNoOpenerAttributeToShipments(shipmentsComplete, user)
      shipmentsIncomplete = addNoOpenerAttributeToShipments(shipmentsIncomplete, user)
    }

    // if user is operator should see incomplete return shipments with status sent
    if (!showCompleted && isOperatorUser(user)) {
      shipmentsIncomplete = shipmentsIncomplete.filter(shipment => {
        return !isReturnShipment(user, shipment) || (isReturnShipment(user, shipment) && (shipment.status === 'sent' || shipment.status === 'arrived'))
      })
    }

    const chosenShipments = showCompleted
      ? addProductData(shipmentsComplete, products)
      : addProductData(shipmentsIncomplete, products)

    const routesForFilter = routesFilter(chosenShipments || [])
    const sourcesForFilter = sourcesFilter(chosenShipments || [])
    const destinationsForFilter = destinationsFilter(chosenShipments || [])
    const marketsForFilter = marketsFilter(chosenShipments || [])
    const deliveryDatesForFilter = orderBy(uniq(chosenShipments.map(s => s.date)), 'desc')

    const availableFilters = {
      route: {
        values: routesForFilter,
        defaultValue: null
      },
      market: {
        values: marketsForFilter,
        defaultValue: null
      },
      source: {
        values: sourcesForFilter.map(s => s.id).concat(null),
        defaultValue: null
      },
      destination: {
        values: destinationsForFilter.map(d => d.id).concat(null),
        defaultValue: null
      },
      pack: {
        values: ['full'],
        defaultValue: null
      },
      deliveryDate: {
        values: deliveryDatesForFilter,
        defaultValue: null
      }
    }

    setAvailableFilters(availableFilters)
    setRoutesForFilter(routesForFilter)
    setMarketsForFilter(marketsForFilter)
    setDestinationsForFilter(destinationsForFilter)
    setSourcesForFilter(sourcesForFilter)
    setDeliveryDatesForFilter(deliveryDatesForFilter)
    setShipments(chosenShipments)
  }

  const loadOnlineShipments = async () => {
    const options = { userAsDistributor, online: true }

    setHasReceivedShipments(false)
    setError(null)

    try {
      await dispatch(
        findShipments(
          userLocation,
          withListInGeoLocation,
          useRouteSpecificFilter,
          options
        )
      )

      setLoadedOnlineShipments(true)
      setOfflineError(false)
    } catch (error) {
      setOfflineError(true)
    } finally {
      // We have previously loaded online shipments on the reducer already
      setHasReceivedShipments(true)
    }
  }

  const onFilterChange = (update) => {
    const activeFilters = getActiveFilters()
    history.push({
      ...history.location,
      search: queryStringifyFilters({
        ...activeFilters,
        ...update
      })
    })
  }

  const getActiveFilters = () => {
    return resolveQueryStringFilterState(
      history.location.search,
      {
        sorters: {},
        history,
        availableFilters
      }
    ).filters
  }

  const acknowledgeNewShipmentToast = () => {
    window.localStorage.removeItem('newDelivery')
  }

  useEffect(() => {
    const initializeData = async () => {
      await initialize()
    }
    initializeData()
  }, [shipmentList.complete.length, shipmentList.incomplete.length])

  useEffect(() => {
    const updateInitializedShipments = async () => {
      await initializeShipments(rawLocationDocs)
    }

    updateInitializedShipments()
  }, [showCompleted])

  if (error) {
    return (<ErrorView error={error} />)
  }

  if (!(hasReceivedShipments && hasReceivedMasterData)) {
    return <Loading loadingText={`Loading ${showCompleted ? 'completed' : 'scheduled'} shipments …`} />
  }

  let onLoadOnlineShipments = null
  // - All non-completed shipments are loaded by default (controlled via id dispensing)
  // - this is only relevant for online-offline users
  if (useOnlineOffline(user) && showCompleted && !loadedOnlineShipments && !userIsGlobal) {
    onLoadOnlineShipments = loadOnlineShipments
  }

  const locationNameFallback = capitalize(userLocation.id.split(':').pop())
  const locationName = get(locationObject, 'fullName', locationNameFallback)
  const fullLocationName = appendLocationType(locationName, config)

  let filters = {}
  if (!loading) {
    filters = getActiveFilters()
  }

  const newShipmentStringed = window.localStorage.getItem('newDelivery')
  const newShipment = JSON.parse(newShipmentStringed)

  return (
    loading
      ? <div />
      : (
        <Fragment>
          {offlineError && <OfflineWarning />}
          <Shipments
            shipments={shipments}
            newShipment={newShipment}
            acknowledgeNewShipmentToast={acknowledgeNewShipmentToast}
            funders={funders}
            locationName={fullLocationName}
            showCompleted={showCompleted}
            products={products}
            config={config}
            showImportLink={showImportLink}
            showRemoveShipmentLink={showRemoveShipment}
            editPSMShipment={editPSMShipment}
            loadOnlineShipments={onLoadOnlineShipments}
            displayPlanningType={displayPlanningType}
            displayNewShipmentButton={displayNewShipmentButton}
            user={user}
            api={api}
            activeFilters={filters}
            onFilterChange={onFilterChange}
            routesForFilter={routesForFilter}
            marketsForFilter={marketsForFilter}
            sourcesForFilter={sourcesForFilter}
            deliveryDatesForFilter={deliveryDatesForFilter}
            destinationsForFilter={destinationsForFilter}
            history={history}
          />
        </Fragment>
      )
  )
}

const withHOCs = flowRight(
  withApi,
  withUser,
  withConfig
)

export default withHOCs(ShipmentsContainer)
