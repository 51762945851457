import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import get from 'lodash/get'

import { Loading, ErrorView } from '@fielded/shared-ui'
import { getStatesNg } from '@fielded/fs-api/lib/service/tools/territory-lookup'
import { toast } from '@fielded/shared-ui/src/components/Page/Page'

import { withUser } from '../../../common/AuthenticationProvider'
import { withApi } from '../../../common/ApiProvider'
import CarrierManagementAddEdit from '../components/CarrierManagementAddEdit'

const CarrierManagementAddEditContainer = ({
  config,
  api,
  user,
  match
}) => {
  const { carrierId } = match.params
  const history = useHistory()
  const formRef = useRef()
  const emptyServiceArea = {
    state: getStatesNg()[0],
    priority: 'primary'
  }
  const isCreate = carrierId === 'new'
  const isCarrierUser = user.roles.includes('feature:userRole:psm-carrier')

  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [serviceAreas, setServiceAreas] = useState([emptyServiceArea])
  const [carrierRecord, setCarrierRecord] = useState()
  const [carrierState, setCarrierState] = useState()
  const [carrierFleets, setCarrierFleets] = useState([])

  const initialize = async () => {
    if (!isCreate) {
      try {
        const carrierOrganisation = await api.carrier.get(carrierId)
        const carrier = get(carrierOrganisation, 'organisation')
        const serviceArears = carrierOrganisation.operationalStates.map((opState) => ({
          state: opState.state,
          priority: opState.priority
        }))
        const fleetCategories = carrierOrganisation.fleetCategories.map((fleet) => fleet.category)
        setCarrierRecord(carrier)
        setServiceAreas(serviceArears)
        setCarrierState(carrier.state)
        setCarrierFleets(fleetCategories)
      } catch (e) {
        setError(e)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    initialize()
  }, [])

  const onAddServiceArea = () => {
    const updatedServiceAreas = [...serviceAreas, emptyServiceArea]
    setServiceAreas(updatedServiceAreas)
  }

  const onRemoveServiceArea = (index) => {
    let updatedServiceAreas = serviceAreas.slice()
    updatedServiceAreas.splice(index, 1)
    setServiceAreas(updatedServiceAreas)
  }

  const onHandleServiceAreaChange = (index, value, property) => {
    let updatedServiceAreas = [...serviceAreas]
    updatedServiceAreas[index][property] = value
    setServiceAreas(updatedServiceAreas)
  }

  const onHandleStateChange = (value) => {
    setCarrierState(value)
  }

  const onSave = async () => {
    setSaving(true)
    const formValidationErrors = await formRef.current.validate()

    if (formValidationErrors) {
      setSaving(false)
      window.scrollTo(0, 0)
      return
    }

    const fields = formRef.current.getFields()

    const carrierData = {
      organisation: {
        ...(isCreate ? {} : {id: carrierId}),
        name: fields.name,
        phone_number: fields.phone,
        email_addresses: [fields.email],
        country: get(user, 'location.country'),
        state: carrierState,
        lga: fields.lga,
        city: fields.city,
        street: fields.street
      },
      fleetCategories: fields.fleet.map((carrierFleet) => ({category: carrierFleet})),
      operationalStates: serviceAreas
    }

    try {
      if (isCreate) {
        await api.carrier.create(carrierData)
      } else {
        await api.carrier.update(carrierId, carrierData)
      }

      setSaving(false)

      if (!isCreate) {
        toast({
          title: 'Carrier updated',
          type: 'success',
          children: `Carrier "${fields.name}" has been updated successfully`
        })
      }

      if (isCreate) {
        history.push({
          pathname: '/settings/carriers',
          state: { carrierName: fields.name }
        })
      }
    } catch (error) {
      setError(error)
    } finally {
      setSaving(false)
    }
  }

  if (error) {
    return <ErrorView error={error} />
  }

  if (loading) {
    return <Loading />
  }

  return (
    <CarrierManagementAddEdit
      carrierRecord={carrierRecord}
      carrierFleets={carrierFleets}
      history={history}
      serviceAreas={serviceAreas}
      onHandleServiceAreaChange={onHandleServiceAreaChange}
      onAddServiceArea={onAddServiceArea}
      onRemoveServiceArea={onRemoveServiceArea}
      onHandleStateChange={onHandleStateChange}
      onSave={onSave}
      saving={saving}
      formRef={formRef}
      isEdit={!isCreate}
      viewOnly={isCarrierUser}
    />
  )
}

export default withApi(withUser(CarrierManagementAddEditContainer))
