import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Text } from '@fielded/shared-ui'
import { Upload } from '@fielded/shared-ui/src/icons'
import emptyState from './assets/emptyStateIcon.png'

export const UploadShipmentLink = () => (
  <Button
    fill='full'
    colorVariant='brand'
    size='regular'
    component={Link}
    to='/shipments/list/overview/download'
    icon={<Upload />}
  >
    Import a shipment
  </Button>
)

const PSMInboundShipmentsEmptyState = () => {
  return (
    <section className='inbound-shipments-empty-state__wrapper'>
      <div className='inbound-shipments-empty-state__empty-state-img-wrapper'>
        <img className='inbound-shipments-empty-state__empty-state-img' src={emptyState} alt='' />
      </div>
      <section className='inbound-shipments-empty-state__info-wrapper'>
        <Text size='xlarge' bold>No inbound shipments yet</Text>
        <Text className='inbound-shipments-empty-state__info'>Import a shipment file to log received items and update records.</Text>
      </section>
      <UploadShipmentLink />
    </section>
  )
}

export default PSMInboundShipmentsEmptyState
