const keyBy = require('lodash/keyBy')
const get = require('lodash/get')

const getShipmentVolumes = async (shipmentIds, api) => {
  try {
    return (await api.shipment.calculateVolume({ shipmentIds }))
      .reduce((acc, {
        shipment_id: shipmentId,
        total_volume: totalVolume
      }) => {
        return {
          ...acc,
          [shipmentId]: totalVolume
        }
      }, {})
  } catch (error) {
    console.error('Error calculating shipment volume!', error.message)
    return {}
  }
}

const decorateRouteWithDetails = async ({ routes, includeDrivers = false }, api) => {
  if (!routes || routes.length === 0) {
    return []
  }

  const shipments = routes.reduce((acc, route) => {
    return [
      ...acc,
      ...(route.shipments || [])
    ]
  }, [])

  const locationAndFunderAndProgramIds = shipments.reduce((acc, curr) => {
    acc.locations.add(curr.destination_id)
    acc.locations.add(curr.origin_id)
    acc.funders.add(curr.funder_id)
    acc.programs.add(curr.program_id)
    return acc
  }, {locations: new Set(), funders: new Set(), programs: new Set()})

  const locationsAndFundersAndProgramsAndDrivers = await Promise.all([
    ...Array.from(locationAndFunderAndProgramIds.locations).map(
      (locationId) => api.location.get(locationId)
    ),
    ...Array.from(locationAndFunderAndProgramIds.funders).map((funderId) =>
      api.funders.get(funderId)),
    ...Array.from(locationAndFunderAndProgramIds.programs).map((programId) =>
      api.program.get(programId)),
    ...(includeDrivers ? [api.user.listUsersByRoutes({routes: routes.map(route => route.couchdb_id)})] : [])
  ])
  const drivers = includeDrivers ? locationsAndFundersAndProgramsAndDrivers.pop() : []
  const driversByRouteId = !includeDrivers ? {} : drivers.reduce((acc, driver) => {
    if (!driver.routes || !driver.routes.length) {
      return acc
    }
    const routeId = get(driver, 'routes[0]')
    return {
      ...acc,
      [routeId]: get(driver, 'fullName') || get(driver, 'name')
    }
  }, {})

  const locationsAndFundersAndPrograms = [...locationsAndFundersAndProgramsAndDrivers]

  const locations = locationsAndFundersAndPrograms.slice(
    0,
    locationAndFunderAndProgramIds.locations.size
  )
  const locationsById = keyBy(locations, '_id')

  const funders = locationsAndFundersAndPrograms.slice(
    locationAndFunderAndProgramIds.locations.size,
    locationAndFunderAndProgramIds.locations.size + locationAndFunderAndProgramIds.funders.size
  )
  const fundersById = keyBy(funders, '_id')

  const programs = locationsAndFundersAndPrograms.slice(-1 * locationAndFunderAndProgramIds.programs.size)
  const programsById = keyBy(programs, 'id')

  const shipmentsByIds = keyBy(shipments, 'shipment_id')
  const shipmentVolumes = await getShipmentVolumes(Object.keys(shipmentsByIds), api)

  return (
    routes
      .map((route) => {
        // Collect the shipments that belong to this route
        // and add the calculated load volume to each shipment
        const routeShipments = (route.shipments || []).map((shipment) => {
          const loadVolume = parseFloat(shipmentVolumes[shipment.shipment_id])
          return {
            ...shipment,
            loadVolume: isNaN(loadVolume) ? 0 : loadVolume,
            origin: locationsById[shipment.origin_id],
            destination: locationsById[shipment.destination_id],
            funder: fundersById[shipment.funder_id],
            program: programsById[shipment.program_id]
          }
        })

        // Collect the unique LGAs, the total load volume and number of stops of the route
        const routeShipmentsData = routeShipments.reduce(
          (acc, shipment) => {
            const destinationId = shipment.destination_id
            const lga = get(locationsById, `${destinationId}.location.lga`, null)
            acc.lga.add(lga)
            const state = get(locationsById, `${destinationId}.location.state`, null)
            acc.state.add(state)
            acc.destinations.add(locationsById[destinationId])
            acc.origin.add(shipment.origin_id)
            acc.funders.add(shipment.funder_id)
            acc.programs.add(shipment.program_id)
            acc.totalLoadVolume += shipment.loadVolume
            return acc
          },
          {
            lga: new Set(),
            totalLoadVolume: 0,
            state: new Set(),
            destinations: new Set(),
            origin: new Set(),
            funders: new Set(),
            programs: new Set()
          }
        )

        if (routeShipmentsData.origin.size > 1) {
          console.error('Route has multiple origins or states! That mean it contains shipments from multiple different warehouses!', route)
          // throw new Error('Route is invalid! It has multiple origins!', { id: route.couchdb_id })
        }

        return {
          id: route.couchdb_id,
          name: route.name,
          lga: Array.from(routeShipmentsData.lga).join(', '),
          state: Array.from(routeShipmentsData.state).join(', '),
          origin: routeShipmentsData.origin.size === 1
            ? locationsById[Array.from(routeShipmentsData.origin)[0]]
            : null,
          destinations: Array.from(routeShipmentsData.destinations),
          stopsNo: routeShipmentsData.destinations.size,
          load: routeShipmentsData.totalLoadVolume,
          startDate: route.start_date,
          endDate: route.end_date,
          approvalDate: route.approval_date
            ? route.approval_date
            : null,
          estimatedDeliveryDate: route.estimated_delivery_date
            ? route.estimated_delivery_date
            : null,
          estimatedPickupDate: route.estimated_pickup_date
            ? route.estimated_pickup_date
            : null,
          funders: Array.from(routeShipmentsData.funders).map(funderId => fundersById[funderId]),
          programs: Array.from(routeShipmentsData.programs).map(programId => programsById[programId]),
          status: route.status,
          shipments: routeShipments,
          carrier: get(route, 'carrier[0]', undefined),
          ...(includeDrivers ? { driverName: driversByRouteId[route.couchdb_id] } : {}
          )
        }
      })
      // Filter out routes that have invalid origins. We can detect it if the route has shipments but
      // the origin is not set. (meaning it the associated routes have more than one origin)
      .filter(route => !(route.shipments.length > 0 && !route.origin))
  )
}
module.exports = { decorateRouteWithDetails }
