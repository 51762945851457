import {
  previousReportingPeriod,
  nextReportingPeriod,
  dateToWeeklyReportingPeriod,
  reportingPeriodToDate,
  endOfReportingPeriodToDate
} from '@fielded/fs-api/lib/tools'

import {
  format,
  differenceInCalendarDays,
  subDays,
  addDays,
  setDate,
  getMonth,
  setMonth,
  subMonths,
  addMonths,
  lastDayOfMonth,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
  addQuarters,
  getYear,
  subYears,
  addYears
} from 'date-fns'

export const PERIOD_DATE_FORMAT = 'yyyy-LL-dd'
export const PERIOD_MONTH_FORMAT = 'yyyy-LL'
// keep a full date here to avoid issues with month mixups
export const PERIOD_QUARTER_FORMAT = 'yyyy-LL-dd'
export const PERIOD_YEAR_FORMAT = 'yyyy'
export const PERIOD_DATE_CONNECTOR = '--'

export const PERIOD_CUSTOM = 'custom'
export const PERIOD_ISOWEEK = 'week'
export const PERIOD_BIMONTH = 'bimonth'
export const PERIOD_QUARTER = 'quarter'
export const PERIOD_MONTH = 'month'
export const PERIOD_YEAR = 'year'

export const periodTypes = ['week', 'bimonth', 'month', 'quarter', 'year', 'custom']

const handleUnknownPeriodType = (periodType, callerName) => {
  console.warn(`Unknown period type in ${callerName}: ${periodType}`)
  return null
}

export const getPeriodTypeAndDate = (period) => {
  if (!period) return null
  let periodType
  let periodDate

  if (period.indexOf(':') !== -1) {
    [periodType, periodDate] = period.split(':')
  } else {
    // we assume that only a type has been passed
    // to get the default current/next/prev period
    periodType = period
  }

  if (!periodTypes.includes(periodType)) {
    return handleUnknownPeriodType(periodType, 'getPeriodTypeAndDate')
  }

  return {
    periodType,
    periodDate
  }
}

export const getCurrentPeriod = periodType => {
  const today = new Date().toISOString()

  switch (periodType) {
    case PERIOD_ISOWEEK:
      return formatPeriod(periodType, dateToWeeklyReportingPeriod('weekly'))
    case PERIOD_BIMONTH:
      return formatPeriod(periodType, previousReportingPeriod('bimonthly'))
    case PERIOD_QUARTER:
      return formatPeriod(periodType, subQuarters(today, 1))
    case PERIOD_CUSTOM:
      console.warn(`Can not set a default period for period type custom`)
      return null
    default:
      return formatPeriod(periodType, today)
  }
}

export const getPrevPeriod = period => {
  if (!period) return null
  let { periodType, periodDate } = getPeriodTypeAndDate(period)

  if (!periodDate) {
    // if only a type was passed, we assume we should base this on today
    const { periodDate: defaultDate } = getPeriodTypeAndDate(getCurrentPeriod(periodType)) || {}
    periodDate = defaultDate
  }

  switch (periodType) {
    case PERIOD_CUSTOM:
      if (!periodDate) return null
      let { from, to } = periodToDateRange(period)
      from = removeTime(from)
      to = removeTime(to)
      const periodLength = differenceInCalendarDays(to, from)
      const prevTo = subDays(from, 1)
      const prevPeriod = {
        from: subDays(prevTo, periodLength),
        to: prevTo
      }
      return formatPeriod(periodType, prevPeriod)
    case PERIOD_YEAR:
      return formatPeriod(periodType, subYears(periodDate, 1))
    case PERIOD_QUARTER:
      return formatPeriod(periodType, subQuarters(periodDate, 1))
    case PERIOD_MONTH:
      return formatPeriod(periodType, subMonths(periodDate, 1))
    case PERIOD_ISOWEEK:
      return formatPeriod(periodType, previousReportingPeriod('weekly', periodDate))
    case PERIOD_BIMONTH:
      return formatPeriod(periodType, previousReportingPeriod('bimonthly', periodDate))
    default:
      handleUnknownPeriodType(periodType, 'getPrevPeriod')
  }
}

export const getNextPeriod = period => {
  if (!period) return null
  let { periodType, periodDate } = getPeriodTypeAndDate(period)

  if (!periodDate) {
    // if only a type was set, we assume we should base this on today
    const { periodDate: defaultDate } = getPeriodTypeAndDate(getCurrentPeriod(periodType)) || {}
    periodDate = defaultDate
  }

  switch (periodType) {
    case PERIOD_CUSTOM:
      if (!periodDate) return null
      let { from, to } = periodToDateRange(period)
      from = removeTime(from)
      to = removeTime(to)
      const periodLength = differenceInCalendarDays(to, from)
      const nextFrom = addDays(to, 1)
      const nextPeriod = {
        from: nextFrom,
        to: addDays(nextFrom, periodLength)
      }
      return formatPeriod(periodType, nextPeriod)
    case PERIOD_YEAR:
      return formatPeriod(periodType, addYears(periodDate, 1))
    case PERIOD_QUARTER:
      return formatPeriod(periodType, addQuarters(periodDate, 1))
    case PERIOD_MONTH:
      return formatPeriod(periodType, addMonths(periodDate, 1))
    case PERIOD_ISOWEEK:
      return formatPeriod(periodType, nextReportingPeriod('weekly', periodDate))
    case PERIOD_BIMONTH:
      return formatPeriod(periodType, nextReportingPeriod('bimonthly', periodDate))
    default:
      handleUnknownPeriodType(periodType, 'getNextPeriod')
  }
}

export const formatPeriod = (periodType, periodDate) => {
  if (!periodDate) return null

  if (!periodType || !periodTypes.includes(periodType)) {
    handleUnknownPeriodType(periodType, 'formatPeriod')
  }

  if (typeof periodDate === 'object' && periodType === PERIOD_CUSTOM) {
    periodDate = Object.keys(periodDate).map(key => {
      const date = periodDate[key]
      return format(date, PERIOD_DATE_FORMAT)
    }).join(PERIOD_DATE_CONNECTOR)
  }

  if (periodType === PERIOD_MONTH) {
    periodDate = format(periodDate, PERIOD_MONTH_FORMAT)
  }

  if (periodType === PERIOD_QUARTER) {
    periodDate = format(periodDate, PERIOD_QUARTER_FORMAT)
  }

  if (periodType === PERIOD_YEAR) {
    periodDate = format(periodDate, PERIOD_YEAR_FORMAT)
  }

  return `${periodType}:${periodDate}`
}

export const removeTime = jsonDate => {
  // get the Day from the JSON date string.
  // e.g if we have 2019-05-26T23:00:00Z it’ll give us `Mon May 27 2019` in GMT +1
  return new Date(jsonDate).toDateString()
}

export const periodToHumanReadable = period => {
  const { periodType, periodDate } = getPeriodTypeAndDate(period)

  switch (periodType) {
    case PERIOD_CUSTOM:
    case PERIOD_ISOWEEK:
    {
      const dates = periodToDateRange(period)
      return dateRangeToHumanReadable({ to: removeTime(dates.to), from: removeTime(dates.from) }) }
    case PERIOD_YEAR:
      return format(periodDate, 'yyyy')
    case PERIOD_QUARTER:
      return format(periodDate, 'QQQ yyyy')
    case PERIOD_MONTH:
      return format(periodDate, 'LLLL yyyy')
    case PERIOD_BIMONTH:
      const date = reportingPeriodToDate(periodDate)
      const firstMonth = format(date, 'LLL')
      const secondMonth = format(addMonths(date, 1), 'LLL yyyy')
      return `${firstMonth}-${secondMonth}`
    default:
      handleUnknownPeriodType(periodType, 'periodToHumanReadable')
  }
}

/*
 * We do this to be consistent across time zones
 */
export const beginPeriod = date => {
  date.setHours(0, 0, 0, 0)
  return date.toISOString()
}

export const endPeriod = date => {
  date.setHours(23, 59, 59, 999)
  return date.toISOString()
}

export const periodToDateRange = period => {
  if (!period) return null

  const { periodType, periodDate } = getPeriodTypeAndDate(period)
  let startDate
  let endDate

  switch (periodType) {
    case PERIOD_CUSTOM:
      if (periodDate.includes(PERIOD_DATE_CONNECTOR)) {
        const [from, to] = periodDate
          .split(PERIOD_DATE_CONNECTOR)
          .sort((a, b) => (new Date(b) - new Date(a)) * -1)
        startDate = from
        endDate = to
      }

      return {
        from: beginPeriod(new Date(startDate)),
        to: endPeriod(new Date(endDate))
      }

    case PERIOD_YEAR:
      startDate = setDate(setMonth(periodDate, 0), 1)
      endDate = setDate(setMonth(periodDate, 11), 31)

      return {
        from: beginPeriod(startDate),
        to: endPeriod(endDate)
      }

    case PERIOD_QUARTER:
      startDate = startOfQuarter(periodDate)
      endDate = endOfQuarter(periodDate)

      return {
        from: beginPeriod(startDate),
        to: endPeriod(endDate)
      }

    case PERIOD_MONTH:
      startDate = setDate(periodDate, 1)
      endDate = lastDayOfMonth(periodDate)

      return {
        from: beginPeriod(startDate),
        to: endPeriod(endDate)
      }
    case PERIOD_ISOWEEK:
      const prevPeriod = previousReportingPeriod('weekly', periodDate)
      const prevPeriodEnd = endOfReportingPeriodToDate(prevPeriod)
      startDate = addDays(prevPeriodEnd, 1)
      endDate = new Date(endOfReportingPeriodToDate(periodDate))

      return {
        from: beginPeriod(startDate),
        to: endPeriod(endDate)
      }
    case PERIOD_BIMONTH:
      startDate = new Date(reportingPeriodToDate(periodDate))
      startDate.setHours(0, 0, 0, 0)
      endDate = new Date(endOfReportingPeriodToDate(periodDate))

      return {
        from: beginPeriod(startDate),
        to: endPeriod(endDate)
      }
    default:
      handleUnknownPeriodType(periodType, 'periodToDateRange')
  }
}

export const periodToDateRangeNoTime = period => {
  const range = periodToDateRange(period)
  return {
    to: removeTime(range.to),
    from: removeTime(range.from)
  }
}

export const dateRangeToHumanReadable = dateRange => {
  // only show date info as needed
  // 1-4 Dec 2018 or even just 1-4 Dec '18
  // 29 Nov - 3 Dec 2018
  // 28 Dec 2018 - 04 Jan 2019
  const { from, to } = dateRange

  const isSameYear = getYear(from) === getYear(to)
  const isSameMonth = getMonth(from) === getMonth(to)

  let fromDisplay = format(from, 'd LLL yyyy')
  let toDisplay = format(to, 'd LLL yyyy')
  let connector = ' - '

  if (isSameYear) {
    if (isSameMonth) {
      fromDisplay = format(from, 'd')
      connector = '-'
    } else {
      fromDisplay = format(from, 'd LLL')
    }
  }

  return `${fromDisplay}${connector}${toDisplay}`
}

export const defaultPeriod = (app, programs) => {
  if (app === 'psm') {
    // Hack to get configuration for the different reporting periods for programs
    // Since the programs document is not loaded when this is called
    // see https://github.com/fielded/fs-web/blob/develop/src/subapps/analytics/common/filters.js#L88
    // we cannot use the configuration of the program.

    const isMonthlyProgram = () => {
      const monthlyPrograms = ['program:ng-epn', 'program:ke-epn']
      return programs && programs.every(program => monthlyPrograms.includes(program))
    }

    const isQuarterlyProgram = () => {
      const quarterlyPrograms = ['program:tb', 'program:hypertension']
      return programs && programs.every(program => quarterlyPrograms.includes(program))
    }

    if (isQuarterlyProgram()) {
      return getCurrentPeriod(PERIOD_QUARTER)
    }

    if (isMonthlyProgram()) {
      return getCurrentPeriod(PERIOD_MONTH)
    }

    return getCurrentPeriod(PERIOD_BIMONTH)
  }

  if (app === 'van') {
    return getCurrentPeriod(PERIOD_ISOWEEK)
  }

  console.log('No default period set for app: ', app, 'default to VAN settings')
  return getCurrentPeriod(PERIOD_ISOWEEK)
}

export const isTodayInReportingPeriod = period => {
  return isDateInPeriodCycle(new Date().toISOString(), period)
}

export const isDateInPeriodCycle = (date, period) => {
  try {
    const [periodType, periodDate] = period.split(':')
    switch (periodType) {
      case PERIOD_ISOWEEK:
        return periodDate === dateToWeeklyReportingPeriod('weekly', date)
      case PERIOD_BIMONTH:
        // for psm bimonthly always allow them to edit
        // https://github.com/fielded/van-orga/issues/1833#issuecomment-401334988
        return true
      default:
        throw new Error('Unknown period type: ' + periodType)
    }
  } catch (e) {
    throw new Error('Date or period is incorrect', e.message)
  }
}
