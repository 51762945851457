import React from 'react'

import { Button, Form, Page, SelectInput } from '@fielded/shared-ui'
import { Plus } from '@fielded/shared-ui/src/icons'
import capitalize from '@fielded/shared-ui/src/utils/capitalize'
import { getStatesNg } from '@fielded/fs-api/lib/service/tools/territory-lookup'
import { VEHICLE_CLASS } from '@fielded/fs-api/lib/routes/tools'

import settingsBreadcrumb from '../../common/settingsBreadcrumb'
import { isValidEmail, isValidPhoneNumber } from '../../../../van-shared/utils/utils'

import CarrierManagementServiceAreaItem from './CarrierManagementServiceAreaItem'

const CarrierManagementAddEdit = ({
  formRef,
  carrierRecord = {},
  carrierFleets = [],
  serviceAreas,
  history,
  onSave,
  error,
  saving,
  isEdit,
  viewOnly,
  onHandleServiceAreaChange,
  onAddServiceArea,
  onRemoveServiceArea,
  onHandleStateChange
}) => {
  const validateField = (value, field) => {
    if (field === 'email') {
      const emails = value.split(',').map(email => email.trim())
      const invalidEmails = emails.filter(email => !isValidEmail(email))

      if (invalidEmails.length > 0) {
        return Promise.resolve(`Invalid email addresses: ${invalidEmails.join(', ')}`)
      }
    }

    if (field === 'phone' && !isValidPhoneNumber(value)) {
      return Promise.resolve('Please enter a valid phone number')
    }
  }

  const statesNG = getStatesNg()
  const stateOptions = statesNG.map((state) => {
    return {
      value: state,
      label: capitalize(state.split(':state:')[1])
    }
  })

  const fleetOptions = VEHICLE_CLASS.map((vehicle) => {
    return {
      value: vehicle.name,
      label: vehicle.displayName
    }
  })

  return (
    <Form ref={formRef}>
      <Page>
        <Page.Header
          breadcrumbItems={[
            settingsBreadcrumb,
            {
              title: 'Carriers',
              path: '/settings/carriers'
            }
          ]}
          title={`${isEdit ? 'Edit' : 'Create'} Carrier profile`}
        />
        <Page.Panel
          withMargin
          narrow
          alignLeft
          spread
        >
          {error && (
            <Page.Panel.Message type='warning'>
          something went wrong: {error.message}
            </Page.Panel.Message>
          )}
          <Form.Section
            title='Basic information'
            intro='Enter essential details for the Carrier provider, including name and contact info, to ensure accurate records.'
          >
            <Form.Row>
              <Form.Field
                fieldName='name'
                labelText='Name'
              >
                <Form.Field.Text
                  placeholder='Enter name'
                  required
                  defaultValue={carrierRecord.name}
                  disabled={viewOnly}
                />
                <Form.Field.ValidationErrors />
              </Form.Field>
              <Form.Field
                fieldName='phone'
                labelText='Phone'
              >
                <Form.Field.Text
                  placeholder='Enter mobile phone number'
                  defaultValue={carrierRecord.phone_number}
                  disabled={viewOnly}
                  type='tel'
                  validate={(value) => validateField(value, 'phone')}
                  required
                />
                <Form.Field.ValidationErrors />
              </Form.Field>
            </Form.Row>
            <Form.Row>
              <Form.Field
                spread
                fieldName='email'
                labelText='Email'
              >
                <Form.Field.Text
                  placeholder='Enter email addresses'
                  defaultValue={carrierRecord.email_addresses && carrierRecord.email_addresses.join(', ')}
                  type='email'
                  disabled={viewOnly}
                  required
                  validate={(value) => validateField(value, 'email')}
                />
                <Form.Field.ValidationErrors />
              </Form.Field>
            </Form.Row>
          </Form.Section>
          <Form.Section
            title='Address'
            intro="Enter the Carrier provider's address details to ensure accurate records."
          >
            <Form.Row>
              <Form.Field
                spread
                fieldName='state'
                labelText='State'
              >
                <SelectInput
                  clearable={false}
                  value={carrierRecord.state}
                  options={stateOptions}
                  onChange={({ value }) => onHandleStateChange(value)}
                />
              </Form.Field>
            </Form.Row>
            <Form.Row>
              <Form.Field
                fieldName='city'
                labelText='City'
              >
                <Form.Field.Text
                  placeholder='Enter city'
                  defaultValue={carrierRecord.city}
                />
              </Form.Field>
              <Form.Field
                fieldName='lga'
                labelText='LGA'
              >
                <Form.Field.Text
                  placeholder='Enter LGA'
                  defaultValue={carrierRecord.lga}
                />
              </Form.Field>
            </Form.Row>
            <Form.Row>
              <Form.Field
                spread
                fieldName='street'
                labelText='Address'
              >
                <Form.Field.Text
                  placeholder='Enter street address'
                  defaultValue={carrierRecord.street}
                />
              </Form.Field>
            </Form.Row>
          </Form.Section>
          <Form.Section
            title='Fleet information'
            intro="Enter the Carrier provider's fleet information to ensure accurate records."
          >
            <Form.Row>
              <Form.Field
                spread
                fieldName='fleet'
              >
                <Form.Field.Options
                  multi
                  required
                  defaultValue={carrierFleets}
                  options={fleetOptions}
                  className='carrier-management__fleet-options'
                />
              </Form.Field>
            </Form.Row>
          </Form.Section>
          <Form.Section
            title='Service area'
            intro='Select the states where the Carrier operates.'
          >
            {serviceAreas.map((serviceArea, index) => {
              const canBeRemoved = serviceAreas.length > 1
              return (
                <CarrierManagementServiceAreaItem
                  onHandleServiceAreaChange={onHandleServiceAreaChange}
                  onRemoveServiceArea={onRemoveServiceArea}
                  index={index}
                  serviceArea={serviceArea}
                  stateOptions={stateOptions}
                  canBeRemoved={canBeRemoved}
                  viewOnly={viewOnly}
                />
              )
            })}
            {!viewOnly && <Button
              onClick={onAddServiceArea}
              colorVariant='brand'
              fill='none'
              icon={<Plus />}
            >
              Add service area
            </Button>}
          </Form.Section>
        </Page.Panel>
        <Page.Footer>
          <Form.Actions>
            <Form.Button
              type='submit'
              colorVariant='brand'
              fill='full'
              loading={saving}
              size='large'
              onClick={onSave}
            >
              Submit & {isEdit ? 'Update' : 'Create'} Carrier
            </Form.Button>
            <Form.Button
              variant='deemphasized'
              alignOpposite
              disabled={saving}
              onClick={() => history.goBack()}
            >
              Cancel and discard changes
            </Form.Button>
          </Form.Actions>
        </Page.Footer>
      </Page>
    </Form>
  )
}

export default CarrierManagementAddEdit
