import React from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'

import { CloseCircle, Settings } from '@fielded/shared-ui/src/icons'
import { Button } from '@fielded/shared-ui'

const ShipmentHeaderOptionButton = ({
  isEditable,
  isOptions,
  shipment,
  redirectLink
}) => {
  if (!(isEditable || isOptions)) {
    return null
  }

  const history = useHistory()

  let buttonProps = {
    fill: 'outline',
    colorVariant: 'brand',
    icon: <Settings />,
    title: 'Options',
    size: 'small',
    onClick: () => history.push({
      pathname: `/shipments/pick-list/${shipment.snapshotId}/shipment-options`,
      state: { redirectLink }
    })
  }

  if (isOptions) {
    buttonProps = {
      ...buttonProps,
      component: Link,
      to: redirectLink || `/shipments/pick-list/${shipment.snapshotId}`,
      colorVariant: 'neutral',
      icon: <CloseCircle />,
      title: 'Close'
    }
  }

  return (
    <section className='shipment-header__meta-action'>
      <Button {...buttonProps}>
        {buttonProps.title}
      </Button>
    </section>
  )
}

ShipmentHeaderOptionButton.propTypes = {
  isEditable: PropTypes.bool,
  isOptions: PropTypes.bool,
  shipment: PropTypes.object.isRequired,
  redirectLink: PropTypes.string
}

ShipmentHeaderOptionButton.defaultProps = {
  isEditable: undefined,
  isOptions: undefined,
  redirectLink: ''
}

export default ShipmentHeaderOptionButton
