const nhlmisRestGet = async (mainApi, { locationId, serviceId }) => {
  const service = await mainApi.service.get(serviceId)
  let stockCountDoc, period

  // search for period for 12 months to the past
  let currentDate = new Date()
  const timeframe = 12
  for (let count = 0; count < timeframe; count++) {
    if (stockCountDoc && stockCountDoc.length) break

    period = await mainApi.report.period.get({
      program: service.program,
      date: currentDate.toJSON(),
      isEffectiveDate: true
    })

    stockCountDoc = await mainApi.report.findForLocation({
      locationId,
      serviceId,
      period
    })
    currentDate.setMonth(currentDate.getMonth() - 1)
  }

  if (!stockCountDoc.length) {
    const error = new Error(`Could not find stock count for ${locationId}, ${serviceId} between now and period ${period && period.id}`)
    error.status = 404
    throw error
  }

  const reports = await Promise.all(stockCountDoc.map(async stockCount => {
    let batches = []
    Object.keys(stockCount.stock).map(productId => {
      const productBatches = stockCount.stock[productId].batches
      if (productBatches) {
        batches.push(...Object.keys(productBatches))
      }
    })

    const batchList = await mainApi.batch.list({ ids: batches })

    Object.keys(stockCount.stock).map(productId => {
      stockCount.stock[productId].available = undefined
      stockCount.stock[productId].availableTotal = undefined

      if (batchList) {
        for (let batchId of batches) {
          for (let batch of batchList) {
            if (batchId === batch._id && stockCount.stock[productId].batches && stockCount.stock[productId].batches[batchId]) {
              stockCount.stock[productId].batches[batchId].expiry = batch.expiry
            }
          }
        }
      }
    })
    return stockCount
  }))

  return reports[0]
}
module.exports = nhlmisRestGet
