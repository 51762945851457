import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import keyBy from 'lodash/keyBy'
import flowRight from 'lodash/flowRight'
import { Modal, DataTable, Button, ActionsBar, Loading } from '@fielded/shared-ui'
import {getShipmentBatches} from '@fielded/fs-api/lib/shipment/utils/utils'
import { SHIPMENT_STATUS } from '@fielded/fs-api/lib/shipment/constants'
import withOffline from '@fielded/shared-ui/src/common/offline'
import { getServiceForLocationId } from '@fielded/fs-api/lib/service/tools/territory-lookup'
import { DIRECT_ORDER_TYPES } from '@fielded/shared-ui/src/utils/subscription-type-constants'

import { updateShipment } from '../../../root/reducers/shipments'
import { createSnapshot } from '../confirmation/confirmation-reducer'
import { loadPickList, selectPickList } from '../../../root/reducers/shipments/pick-list'

import { userIsAuthorised } from '../../../van-shared/utils/auth'
import withConfig from '../../../van-shared/hoc/withConfig'
import { withUser } from '../../../common/AuthenticationProvider'
import withShipment from '../common/WithShipmentWrapper'
import { withApi } from '../../../common/ApiProvider'
import {
  batchIdToProductId,
  isPSMDriver,
  isEPNWarehouse,
  isShipmentComplete,
  isShipmentCompleteForWarehouseUser
} from '../common/utils'
import { hasFeature } from '../../../van-shared/utils/features'
import { isReturnShipment } from '../../../common/utils/shipment'
import { getSupplierLedger } from '../common/add-products-held-quantity'
import { getPlannedQuantities, makeDeliveryItems } from '../common/makeDeliveryItems'
import { isWithinTheSameState } from '../shipments/shipment-utils'
import { displayWarningToast } from './utils'

import Count from './Count'
import CompletedShipmentSummary from '../common/CompletedShipmentSummary'
import ShipmentSummaryContainer from '../../retailer/shipments/ShipmentSummaryContainer'
import { fetchMasterData, selectHasReceivedMasterData, selectProducts } from '../../../root/reducers/shipments/master-data'

/*
* Count is for when a user (built for SL) needs to
* check off products in a one-page, "count" view.
* Intentionally built without picklist or batch reducer so we can
* move away from maintaining several batch lists in the redux store.
* see https://github.com/fielded/van-orga/issues/1099
* Supports unbatched products to start.
*/
const CountContainer = ({
  reloadShipment,
  shipment,
  history,
  api,
  user,
  config,
  isOnline,
  match
}) => {
  const dispatch = useDispatch()
  const hasReceivedMasterData = useSelector(selectHasReceivedMasterData)
  const products = useSelector(selectProducts)
  const pickList = useSelector(selectPickList)

  const [quantityConflictProducts, setQuantityConflictProducts] = useState([])
  const [supplierLedger, setSupplierLedger] = useState()
  const [loaded, setLoaded] = useState(false)
  const [batches, setBatches] = useState([])
  const [updatedProductsById, setUpdatedProductsById] = useState({})
  const [deliveryItems, setDeliveryItems] = useState()
  const [hasSelectedAllItems, setHasSelectedAllItems] = useState(false)
  // We need this in case user checks/unchecks Select all option
  const [previousCounts, setPreviousCounts] = useState(shipment.counts)

  const isEPNWarehouseUser = isEPNWarehouse({user, config})
  const shipmentIsComplete = (isEPNWarehouseUser ? isShipmentCompleteForWarehouseUser : isShipmentComplete)(shipment, user)
  const showPackPointLedger = hasFeature(config.features, 'shipments.showPackPointLedger')
  const isOperator = userIsAuthorised(user, 'feature:userRole:operator')
  const isGlobalPlanner = userIsAuthorised(user, 'feature:userRole:planner')
  const useProductTranslation = hasFeature(config, 'features.shipments.useProductTranslation')

  const initialize = async () => {
    if (!hasReceivedMasterData) {
      await dispatch(fetchMasterData())
    }

    const shipmentIds = Object.keys(shipment.counts)
      .map(batchId => batchIdToProductId(batchId))
      .filter(id => !products.allIds.includes(id))

    let processedProducts = { ...products }
    let missingProducts = []

    if (shipmentIds.length) {
      try {
        missingProducts = await api.product.getByIds(shipmentIds)

        if (missingProducts && missingProducts.length !== shipmentIds.length) {
          missingProducts = await api.product.getProductsViaIds(shipmentIds)
        }
      } catch (error) {
        displayWarningToast(error)
      }
      const productsByIds = keyBy(missingProducts, '_id')
      processedProducts = {
        ...processedProducts,
        allIds: [...processedProducts.allIds, ...shipmentIds],
        byId: {...processedProducts.byId, ...productsByIds}
      }
    }

    const batches = getShipmentBatches(shipment, processedProducts.byId)

    await dispatch(loadPickList(shipment, processedProducts, user))

    let supplierLedger
    // lets also check if it's online then hit fetch ledger data.
    if (showPackPointLedger && (isOperator || isGlobalPlanner) && isOnline) {
      const location = await api.location.get(shipment.origin.id)
      // supplier ledger should load only once per shipment
      if (location && location.level === 'pack-point' && shipment.status === 'new') {
        supplierLedger = await getSupplierLedger({
          location,
          api
        })
        if (supplierLedger) {
          batches.forEach(batch => {
            batch.ledgerQuantity = supplierLedger.ledger[batch._id]
          })
        }
      }
    }

    setSupplierLedger(supplierLedger)
    setBatches(batches)
    setUpdatedProductsById(processedProducts.byId)
    setLoaded(true)

    if (isPSMDriver(user) || isEPNWarehouseUser) {
      const plannedQuantities = getPlannedQuantities(shipment)
      const items = makeDeliveryItems({ shipment, productsById: processedProducts.byId, plannedQuantities }) || []
      setDeliveryItems(items)
    }

    /*
     * Creating the "Arrived" Snapshot + translating products from one market to another if necessary
     * This should happen when operators open a return marked as "sent"
     * and when drivers (FPs and PSM drivers) open a delivery marked as "sent"
     * For global planners it will happen to any shipment marked as "sent"
     * but creating the snapshot does not seem to cause any harm
     */
    if (
      (
        shipment.status === 'sent' ||
         (isEPNWarehouseUser && shipment.status === SHIPMENT_STATUS.PACKED)
      ) && !isShipmentComplete(shipment, user)) {
      const destinationId = shipment.destination.id
      const products = await api.product.listAll()
      const allProducts = [...missingProducts, ...products]
      const date = new Date().toJSON()
      const location = await api.location.get(destinationId, date)

      let counts = shipment.counts
      if (useProductTranslation) {
        const { translatedCounts } = await api.shipment.bulkTranslateShipmentProducts({shipment, location, products: allProducts})
        counts = translatedCounts
      }

      const opts = {
        status: 'arrived',
        counts
      }
      try {
        const snapshot = await dispatch(createSnapshot(opts, shipment))
        reloadShipment(shipment.id)
        return history.replace(`/shipments/pick-list/${snapshot.snapshotId}`)
      } catch (error) {
        // For online/offline users editing a shipment outside the sync range
        console.error(error)
      }
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  useEffect(() => {
    const batches = getShipmentBatches(shipment, updatedProductsById)
    if (supplierLedger) {
      batches.forEach((batch) => {
        batch.ledgerQuantity = supplierLedger.ledger[batch._id]
      })
    }
    if (isPSMDriver(user) || isEPNWarehouseUser) {
      const plannedQuantities = getPlannedQuantities(shipment)
      const items = makeDeliveryItems({ shipment, productsById: updatedProductsById, plannedQuantities }) || []
      setDeliveryItems(items)
    }

    setBatches(batches)
  }, [shipment.counts])

  const handleCountUpdate = async (batchId, inputQuantity, checked, paymentType = DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL) => {
    const { snapshotId } = match.params
    const counts = {
      [batchId]: { checked, quantity: inputQuantity, paymentType }
    }
    const updatedShipment = await api.shipment.saveChanges(snapshotId, counts)
    // this is a prop from withShipment wrapper
    reloadShipment(snapshotId, updatedShipment)

    // the confirmation container still looks at `store.shipments`,
    // so this is to keep the shipment up to date
    // there as well for whichever point the user advances
    await dispatch(updateShipment(updatedShipment))
  }

  const handleToggleAllItems = async () => {
    const { snapshotId } = match.params

    let updatedCounts
    if (hasSelectedAllItems) {
      updatedCounts = previousCounts
      setHasSelectedAllItems(false)
    } else {
      setPreviousCounts(shipment.counts)
      setHasSelectedAllItems(true)
      updatedCounts = Object.entries(shipment.counts).reduce((acc, [batchId, batchInfo]) => {
        acc[batchId] = {
          ...batchInfo,
          checked: true
        }
        return acc
      }, {})
    }

    const updatedShipment = await api.shipment.saveChanges(snapshotId, updatedCounts)
    // this is a prop from withShipment wrapper
    reloadShipment(snapshotId, updatedShipment)

    // the confirmation container still looks at `store.shipments`,
    // so this is to keep the shipment up to date
    // there as well for whichever point the user advances
    await dispatch(updateShipment(updatedShipment))
  }

  const handleProceed = () => {
    const unMappedSkus = []
    const isUserGP = userIsAuthorised(user, 'feature:userRole:planner')
    const originMarket = shipment.origin.state
    const destinationMarket = shipment.destination.state
    // cross territory shipment alias check
    if (shipment.status === 'new' && (originMarket !== destinationMarket)) {
      const { service: destinationService } = getServiceForLocationId(shipment.destination.id)
      batches.forEach(product => {
        if (!product.alias || (!Object.keys(product.alias).includes(destinationService) && product.quantity > 0)) {
          unMappedSkus.push(product._id.split(':')[1])
        }
      })
    }

    if (unMappedSkus.length > 0) {
      window.alert(`${unMappedSkus.length} ${originMarket.toUpperCase()} products with ids ${unMappedSkus} don't have product mappings in ${destinationMarket.toUpperCase()} market. Remove or 0 these products to proceed.`)
      return
    }

    if (isUserGP) {
      const completeDelivery = window.confirm(`You're about to confirm this delivery as a GLOBAL PLANNER, Are you sure you want to continue?`)
      if (!completeDelivery) return
    }

    if (supplierLedger) {
      const quantityConflictProducts = batches.filter(product => product.ledgerQuantity < product.quantity)
      if (quantityConflictProducts.length) {
        setQuantityConflictProducts(quantityConflictProducts)
      }
    }

    return navigateToConfirmation()
  }

  const navigateToConfirmation = async () => {
    // load picklist for confirmation screen from saved batches
    await dispatch(loadPickList(shipment, products))
    return history.push(`/shipments/confirmation/${shipment.snapshotId}`)
  }

  const resetQuantityConflictProducts = () => {
    setQuantityConflictProducts([])
  }

  if (!loaded) {
    return <Loading />
  }

  const isReturn = isReturnShipment(user, shipment)

  if (shipmentIsComplete) {
    const isOperator = userIsAuthorised(user, 'feature:userRole:operator')
    const isInTheSameState = isWithinTheSameState(shipment, user)
    const userCanAdjust = userIsAuthorised(user, 'feature:userRole:planner') || (isOperator && isInTheSameState)
    const adjustmentFeature = hasFeature(config.features, 'shipments.adjustments')

    return (
      <CompletedShipmentSummary
        pickList={pickList}
        shipment={shipment}
        config={config}
        isReturn={isReturn}
        showAdjustment={userCanAdjust && adjustmentFeature}
        isOperator={isOperator}
      />
    )
  }

  // We always show external suppliers the shipment summary
  const isExternalPlanner = userIsAuthorised(user, 'feature:userRole:external-planner')

  if (isExternalPlanner) {
    return (
      <ShipmentSummaryContainer
        api={api}
        config={config}
        snapshotId={shipment.snapshotId}
        isExternalPlanner={isExternalPlanner}
      />
    )
  }

  return (
    <Fragment>
      <Count
        batches={batches}
        shipment={shipment}
        config={config}
        api={api}
        user={user}
        deliveryItems={deliveryItems}
        isReturnShipment={isReturn}
        onProductUpdate={handleCountUpdate}
        onToggleAll={handleToggleAllItems}
        hasSelectedAllItems={hasSelectedAllItems}
        onClickProceed={handleProceed}
      />
      <Modal
        onClose={resetQuantityConflictProducts}
        isOpen={!!quantityConflictProducts.length}
        title={'The confirmed quantities are more than the available quantities in the origin pack point'}>
        <DataTable entries={quantityConflictProducts}>
          <DataTable.Column
            label='Product Name'
            dataKey='name'
          />
          <DataTable.Column
            label='PP Ledger quantity'
            dataKey='ledgerQuantity'
          />
          <DataTable.Column
            label='Confirmed Quantity'
            dataKey='quantity'
          />
        </DataTable>
        <ActionsBar>
          <Button onClick={() => navigateToConfirmation} colorVariant='neutral' fill='full'>
               Continue
          </Button>
          <Button onClick={resetQuantityConflictProducts} fill='full' colorVariant='brand'>
               Cancel
          </Button>
        </ActionsBar>
      </Modal>
    </Fragment>
  )
}

const withHOCs = flowRight(
  withUser,
  withApi,
  withConfig,
  withShipment,
  withOffline
)

export default withHOCs(CountContainer)
