/* eslint-disable max-lines */
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'

import flowRight from 'lodash/flowRight'

import { Loading } from '@fielded/shared-ui'

import { withUser } from '../../../common/AuthenticationProvider'
import { findShipments, selectShipments, byCompleteAndDate } from '../../../root/reducers/shipments'
import withConfig from '../../../van-shared/hoc/withConfig'
import { hasFeature } from '../../../van-shared/utils/features'
import capitalize from '../../../common/utils/capitalize'
import { withApi } from '../../../common/ApiProvider'
import { useOnlineOffline } from '../../../sync/common'

import {
  appendLocationType,
  isEPNWarehouse
} from '../common/utils'

import ErrorView from '../components/LoadingError'
import OfflineWarning from '../common/OfflineWarning'
import DriverShipments from './PSMDriverShipments'

const PSMDriverShipmentsContainer = ({
  api,
  user,
  config,
  history,
  showCompleted
}) => {
  const dispatch = useDispatch()

  const shipmentsFromStore = useSelector(selectShipments)

  const [shipments, setShipments] = useState([])
  const [hasReceivedShipments, setHasReceivedShipments] = useState(false)
  const [programs, setPrograms] = useState([])
  const [locationObject, setLocationObject] = useState()

  const [loadedOnlineShipments, setLoadedOnlineShipments] = useState(false)
  const [offlineError, setOfflineError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const shipmentList = byCompleteAndDate(shipmentsFromStore, user)

  const withListInGeoLocation = hasFeature(config.features, 'shipments:listInGeoLocation')

  const useRouteSpecificFilter = true

  const userLocation = user.location

  const initialize = async () => {
    const options = {
      noExternalShipments: true
    }

    try {
      console.time('findShipments')
      await dispatch(
        findShipments(
          userLocation,
          withListInGeoLocation,
          useRouteSpecificFilter,
          options
        )
      )
      console.timeEnd('findShipments')
    } catch (e) {
      setError(e)
      return
    }

    let programs = await api.program.list()

    const locationObject = await api.location.get(userLocation.id)

    initializeShipments()

    setLocationObject(locationObject)
    setHasReceivedShipments(true)
    setPrograms(programs)
    setLoading(false)
  }

  const initializeShipments = () => {
    const shipmentsComplete = shipmentList.complete
    const shipmentsIncomplete = shipmentList.incomplete

    const chosenShipments = showCompleted
      ? shipmentsComplete
      : shipmentsIncomplete

    setShipments(chosenShipments)
  }

  const loadOnlineShipments = async () => {
    const options = { online: true }

    setHasReceivedShipments(false)
    setError(null)

    try {
      await dispatch(
        findShipments(
          userLocation,
          withListInGeoLocation,
          useRouteSpecificFilter,
          options
        )
      )

      setLoadedOnlineShipments(true)
      setOfflineError(false)
    } catch (error) {
      setOfflineError(true)
    } finally {
      // We have previously loaded online shipments on the reducer already
      setHasReceivedShipments(true)
    }
  }

  useEffect(() => {
    const initializeData = async () => {
      await initialize()
    }
    initializeData()
  }, [shipmentList.complete.length, shipmentList.incomplete.length])

  useEffect(() => {
    const updateInitializedShipments = async () => {
      initializeShipments()
    }

    updateInitializedShipments()
  }, [showCompleted])

  if (error) {
    return (<ErrorView error={error} />)
  }

  if (!(hasReceivedShipments)) {
    return <Loading loadingText={`Loading ${showCompleted ? 'completed' : 'scheduled'} shipments …`} />
  }

  let onLoadOnlineShipments = null
  // - All non-completed shipments are loaded by default (controlled via id dispensing)
  // - this is only relevant for online-offline users
  if (useOnlineOffline(user) && showCompleted && !loadedOnlineShipments) {
    onLoadOnlineShipments = loadOnlineShipments
  }

  const locationNameFallback = capitalize(userLocation.id.split(':').pop())
  const locationName = get(locationObject, 'fullName', locationNameFallback)
  const fullLocationName = appendLocationType(locationName, config)

  return (
    loading
      ? <div />
      : (
        <Fragment>
          {offlineError && <OfflineWarning />}
          <DriverShipments
            isWarehouseUser={isEPNWarehouse({user, config})}
            shipments={shipments}
            programs={programs}
            locationName={fullLocationName}
            showCompleted={showCompleted}
            config={config}
            loadOnlineShipments={onLoadOnlineShipments}
            history={history}
          />
        </Fragment>
      )
  )
}

const withHOCs = flowRight(
  withApi,
  withUser,
  withConfig
)

export default withHOCs(PSMDriverShipmentsContainer)
