import React, { useState, useRef } from 'react'
import { useParams } from 'react-router'
import flowRight from 'lodash/flowRight'

import { Text } from '@fielded/shared-ui'
import { toast } from '@fielded/shared-ui/src/components/Page'

import withConfig from '../../../../../van-shared/hoc/withConfig'
import { withApi } from '../../../../../common/ApiProvider'
import PSMInboundShipmentsOverview from './PSMInboundShipmentsOverview'
import { arrayBufferFileReader } from '@fielded/shared-ui/src/components/ImportButton'

export const VIEW_TYPES = {
  DOWNLOAD: 'download',
  UPLOAD: 'upload'
}

const getSubNavigationItems = (view) => {
  return [
    {
      title: 'Download template',
      path: `/shipments/list/overview/${VIEW_TYPES.DOWNLOAD}`,
      active: !view || view === VIEW_TYPES.DOWNLOAD
    },
    {
      title: 'Upload shipment',
      path: `/shipments/list/overview/${VIEW_TYPES.UPLOAD}`,
      active: view === VIEW_TYPES.UPLOAD
    }
  ]
}

const PSMInboundShipmentsOverviewContainer = ({
  api,
  config,
  history
}) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)

  const documentFormRef = useRef()

  const { view } = useParams()

  const onDownloadTemplate = async () => {
    setIsDownloading(true)

    try {
      const data = await api.shipment.getImportTemplate()
      const blob = new global.Blob([
        new global.Uint8Array(data)
      ], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })

      setIsDownloading(false)
      toast({
        title: 'Template downloaded',
        type: 'success',
        children: <Text>Open the file in Excel, update the required details, and upload it when ready.</Text>
      })
      return global.URL.createObjectURL(blob)
    } catch (error) {
      console.error(error)
    }
  }

  const onHandleUpload = async (importData) => {
    try {
      setIsUploading(true)
      await api.shipment.importShipmentsFromSheet(importData, {
        useFunderSuffixForWarehouse: false,
        validateManufacturer: false,
        newStatus: 'received',
        doCheckSent: false
      })
      toast({
        title: 'Upload Successful',
        type: 'success',
        children: <Text>Your shipment data is being processed.</Text>
      })
    } catch (error) {
      toast({
        title: 'Upload Failed',
        type: 'warning',
        children: <Text>{error.message || 'There was an issue with your upload. Check the file format and required fields, then try again.'}</Text>
      })
      console.error(error)
    } finally {
      setIsUploading(false)
    }
  }

  const onUploadTemplate = async () => {
    const documentValidationErrors = await documentFormRef.current.validate()
    if (documentValidationErrors) {
      return
    }

    const files = documentFormRef.current.getFiles()
    const file = files.file[0]
    arrayBufferFileReader(file, { onImported: onHandleUpload })
  }

  return (
    <PSMInboundShipmentsOverview
      view={view}
      config={config}
      history={history}
      subNavigationItems={getSubNavigationItems(view)}
      documentFormRef={documentFormRef}
      isDownloading={isDownloading}
      isUploading={isUploading}
      onDownloadTemplate={onDownloadTemplate}
      onUploadTemplate={onUploadTemplate}
    />
  )
}

const withHOCs = flowRight(
  withApi,
  withConfig
)

export default withHOCs(PSMInboundShipmentsOverviewContainer)
