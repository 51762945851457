export function getSubNavigationItems (
  {showImportLink = false, activeTab = 'shipments', shipmentId = undefined, isSelfPickup = false}
) {
  const navigationItems = [
    {
      title: isSelfPickup ? 'Packed' : 'Scheduled/incoming',
      path: isSelfPickup ? '/shipments/pick-up' : '/shipments/list',
      active: (activeTab === 'shipments')
    },
    {
      title: isSelfPickup ? 'Received' : 'Completed',
      path: isSelfPickup ? '/shipments/pick-up/completed' : '/shipments/completed',
      active: (activeTab === 'completed')
    }
  ]
  if (showImportLink) {
    navigationItems.push(
      {
        title: shipmentId ? 'Edit Shipment' : 'New Shipment',
        path: shipmentId ? `/${shipmentId}/edit` : '/shipments/new',
        active: (activeTab === 'new')
      },
      {
        title: 'Import',
        path: '/shipments/import',
        active: (activeTab === 'import')
      }
    )
  }

  return navigationItems
}
