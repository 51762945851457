module.exports = `
with
shipment as (
  select
    s.id,
    least(o.created_at, s.created_at) as order_created_at,
    s.shipment_id,
    s.order_id,
    s.status,
    lo.uuid as origin_location_id,
    lo.market_id as origin_market_id,
    ld.uuid as destination_location_id,
    ld.market_id as destination_market_id
  from avocado.data_shipment s
  left join avocado.data_order o on o.order_id = s.order_id
  left join avocado.data_location lo on lo.fsid = s.origin_id
  left join avocado.data_location ld on ld.fsid = s.destination_id
  where s.shipment_id = $1
    and not ld.classification in ('Supplier', 'Pack Point')
),
invoice as (
  select
    destination_location_id as location_id
  from shipment
),
shipment_lines as (
  select
    sl.order_invoicing_type,
    -- Use the translated sku for 'new' and 'sent' cross market shipments.
    -- Received shipments already have translated skus and because skus for the same
    -- product can change over time, we only want to translate when necesarry.
    case when origin_market_id <> destination_market_id and s.status in ('new', 'sent')
      then cm.sku
      else sl.sku
    end as sku,
    s.order_created_at as date,
    sl.amount as quantity,
    0 as bonus,
    s.order_id,
    s.shipment_id
  from shipment s
  inner join avocado.data_shipmentline sl on sl.shipment_id = s.id
  left join lateral (
    -- Translate SKU between source and tarket market
    select ps2.old_sku_id as sku
    from avocado.data_productsku ps1
    inner join avocado.data_productsku ps2 on ps2.market_id = destination_market_id
                                          and ps2.presentation_id = ps1.presentation_id
    where ps1.old_sku_id = sl.sku
    -- Prefer non discontinued non deleted products by sorting those to the bottom
    -- This works, because false < true
    order by ps2._deleted nulls first,
             ps2.visibility = 'Discontinued'
    limit 1
  ) cm on true
  where s.shipment_id = s.shipment_id
    and sl.order_invoicing_type <> 'consignment'
),
existing_lines as (
  select
    coalesce(il.order_id, s.order_id) as order_id,
    coalesce(il.shipment_id, s.shipment_id) as shipment_id,
    il.sku,
    max(il.order_invoicing_type) as order_invoicing_type,
    sum(il.quantity) as quantity,
    max(il.date) as date
  from shipment s
  left join avocado.data_invoice_line il on (
      il.shipment_id = s.shipment_id
    or
      -- This is a fallback, for when we don't have a shipment id.
      -- Prepaid invoices initially don't have a shipment id, only
      -- a order id, but the shipment sync will backfill the shipment id.
      il.shipment_id is null and il.order_id = s.order_id
  )
  left join avocado.data_invoice i on i.id = il.invoice_id
  where i.location_id = s.destination_location_id
    and il.event_type = 'delivery'
  group by 1, 2, 3
),
diffed_lines as (
  select
    'delivery' as event_type,
    coalesce(el.order_invoicing_type, sl.order_invoicing_type) as order_invoicing_type,
    coalesce(sl.sku, el.sku) as sku,
    coalesce(sl.date, el.date) as date,
    coalesce(sl.quantity, 0) - coalesce(el.quantity, 0) as quantity,
    0 as bonus,
    coalesce(sl.order_id, el.order_id) as order_id,
    coalesce(sl.shipment_id, el.shipment_id) as shipment_id
  from shipment_lines sl
  full outer join existing_lines el on el.sku = sl.sku
  where coalesce(sl.quantity, 0) - coalesce(el.quantity, 0) <> 0
)
select
  (select to_jsonb(i) from (
    select
      location_id as "locationId"
    from invoice
  ) i) as invoice,
  coalesce(
    (select jsonb_agg(l) from (
      select
        event_type as "eventType",
        order_invoicing_type as "orderInvoicingType",
        sku,
        date,
        quantity,
        coalesce(bonus, 0) as bonus,
        order_id as "orderId",
        shipment_id as "shipmentId"
      from diffed_lines
    ) l),
    '[]'::jsonb
  ) as lines
`
