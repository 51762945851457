const capitalizeAllWords = (string, character = ' ') => {
  if (!string) return string

  return string.split('-')
    .map(word => {
      const [ firstLetter, ...restOfWord ] = word
      return `${firstLetter ? firstLetter.toUpperCase() : null}${restOfWord.join('')}`
    })
    .join(character)
}

export default capitalizeAllWords
