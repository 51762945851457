import React from 'react'

import { Card, Page, PriceDisplay, StatusPill, Text } from '@fielded/shared-ui'
import capitalize from '@fielded/shared-ui/src/utils/capitalize'

import { formatDate } from '../../../../../van-shared/utils/utils'
import { getNavigationItem } from '../../../../../common/utils/navigationInfo'
import { batchIdToProductId, shipmentTitle } from '../../../common/utils'
// import ShipmentHeaderOptionButton from '../../../common/ShipmentHeaderOptionButton'
import PSMInboundShipmentDetailsTable from './PSMInboundShipmentDetailsTable'

const getProductManufacturer = (productId) => {
  const parts = productId.split(':')
  const manufacturerIndex = parts.indexOf('manufacturer')
  return manufacturerIndex !== -1 ? parts[manufacturerIndex + 1] : ''
}

const PSMInboundShipmentDetails = ({
  config,
  history,
  shipment,
  productsMap
}) => {
  const title = shipmentTitle(shipment, config)
  const totalProductReceived = Object.keys(shipment.counts).length
  const batchList = []
  let totalQuantityReceived = 0

  const productList = Object.entries(shipment.counts).map(([key, value]) => {
    const batch = key.split(':batchNo:')[1]
    if (!batchList.includes(batch)) {
      batchList.push(batch)
    }
    const product = value
    product.id = batchIdToProductId(key)
    product.name = productsMap.get(product.id).name
    product.manufacturer = getProductManufacturer(key)
    product.batchNo = batch
    totalQuantityReceived += product.quantity
    return product
  })

  return (
    <Page>
      <Page.ComposableHeader>
        {/* TODO: we are currently disabling this for inbound shipment */}
        {/* <div className='shipment-header__meta'>
          <ShipmentHeaderOptionButton
            shipment={shipment}
            redirectLink={`/shipments/list/${shipment.snapshotId}`}
            isEditable
          />
        </div> */}
        <Page.ComposableHeader.Breadcrumb
          items={[
            getNavigationItem({ item: 'shipments', config, includePath: false }),
            {
              title: 'Inbound shipments',
              path: '/shipments'
            },
            {
              title: shipment.shipmentNo,
              active: true
            }
          ]}
        />
        <Page.ComposableHeader.Content spread>
          <Page.ComposableHeader.Title title={title} />
        </Page.ComposableHeader.Content>
      </Page.ComposableHeader>

      <Page.Panel reduceBottomMargin>
        <Page.Panel.Section>
          <div className='vs-u-row vs-u-flex-wrap vs-u-gap'>
            <StatusPill
              className={`delivery-location__status--${shipment.status}`}
              label='Status'
              value={capitalize(shipment.status)}
            />
            <StatusPill
              label='Received on'
              value={formatDate(shipment.createdAt, 'long')}
            />
            <StatusPill
              label='ID'
              value={shipment.shipmentNo}
            />
          </div>
        </Page.Panel.Section>

        <Page.Panel.Section>
          <Card semitight>
            <Card.Content>
              {/* TODO: We are not getting this value for now */}
              <section className='vs-u-row vs-u-gap-double'>
                <div className='vs-u-column vs-u-gap'>
                  <Text size='small'>Total products:</Text>
                  <Text size='small'>Total quantity received:</Text>
                  <Text size='small'>Batch count:</Text>
                  {/* <Text>Earliest expiry date:</Text> */}
                </div>

                <div className='vs-u-column vs-u-gap'>
                  <Text size='small' bold>
                    <PriceDisplay value={totalProductReceived} />
                  </Text>
                  <Text size='small' bold>
                    <PriceDisplay value={totalQuantityReceived} />
                  </Text>
                  <Text size='small' bold>
                    <PriceDisplay value={batchList.length} /> {batchList.length > 1 ? 'unique batches' : 'batch'}
                  </Text>
                  {/* <Text bold>{expiryDate}</Text> */}
                </div>
              </section>
            </Card.Content>
          </Card>
        </Page.Panel.Section>

        <Page.Panel.Section>
          <PSMInboundShipmentDetailsTable
            productList={productList}
          />
        </Page.Panel.Section>
      </Page.Panel>
    </Page>
  )
}

export default PSMInboundShipmentDetails
