module.exports = {
  locationIdToProperties,
  adminLocationPropsForCountry,
  locationIdPrefixFor
}

const PSM_NG_NATIONAL_USER_LOCATION_ID = 'national'
const PSM_NG_LOCATION_ID_PREFIX = 'zone'
const LEVELS = ['national', 'zone', 'state', 'lga', 'sdp']

// This is for getting the location object for PSM national level users.
// National Kenyan users use the expected location.id containing the country code.
// National Nigerian users use the location.id 'national', for compatibility with
// the existing data.
function adminLocationPropsForCountry (countryId) {
  return countryId === 'country:ng' ? locationIdToProperties(PSM_NG_NATIONAL_USER_LOCATION_ID)
    : locationIdToProperties(countryId)
}

// This returns the CouchDB location _id prefix, to be used
// on CouchDB queries that request an _id range.
// In PSM Nigeria, for legacy reasons, the location _id does not contain
// the country code, any other countries (and tenants) will contain it.
function locationIdPrefixFor (locationId, level = 'national') {
  const props = locationIdToProperties(locationId)
  if (LEVELS.indexOf(props.level) < LEVELS.indexOf(level)) {
    return
  }
  if (level === 'national') {
    if (locationId.startsWith('country:')) {
      return `country:${props.country}`
    }
    if (props.country === 'ng') {
      return PSM_NG_LOCATION_ID_PREFIX
    }
  }
  // If the provided location does not have the requested level
  // (ex: level zone for a KE location) we cannot return a prefix
  if (!props[level]) {
    return
  }
  if (level === 'zone' && locationId.startsWith('zone:')) {
    return `zone:${props.zone}`
  }
  const parts = locationId.split(`:${level}:`)
  return `${parts[0]}:${level}:${props[level]}`
}

function locationIdToProperties (id) {
  // This is for PSM NG only, PSM KE will use `country:ke` as id
  if (id === PSM_NG_NATIONAL_USER_LOCATION_ID) {
    return {
      country: 'ng', // default to NG if no country provided
      level: 'national',
      id
    }
  }

  const [, country] = id.match(/country:([^:]+)/) || []
  const [, zone] = id.match(/zone:([^:]+)/) || []
  const [, state] = id.match(/state:([^:]+)/) || []
  const [, lga] = id.match(/lga:([^:]+)/) || []
  const [, sdp] = id.match(/sdp:([^:]+)/) || []
  const [, name] = id.match(/name:([^:]+)/) || []

  const locationId = []
  const properties = {
    country: 'ng'
  }

  if (country) {
    properties.level = 'national'
    properties.country = country
    locationId.push('country', country)
  }
  if (zone) {
    properties.level = 'zone'
    properties.zone = zone
    locationId.push('zone', zone)
  }
  if (state) {
    properties.state = state
    properties.level = 'state'
    locationId.push('state', state)
  }
  if (lga) {
    properties.lga = lga
    properties.level = 'lga'
    locationId.push('lga', lga)
  }
  if (sdp) {
    properties.sdp = sdp
    properties.level = 'sdp'
    locationId.push('sdp', sdp)
  }
  if (name) {
    properties.name = name
    locationId.push('name', name)
  }
  /*
    What we want to have in properties.id is the location doc._id.
    However we cannot just set `properties.id = id` because this function
    is used sometimes to pass a non location id like
    `zone:north-central:state:benue:lga:ado:sdp:general-hospital-igumale:biprogram:hiv-aids:service:arvs-cotrim`
    expecting to get back
    {
      country: 'ng',
      level: 'sdp',
      zone: 'north-central',
      state: 'benue',
      lga: 'ado',
      sdp: 'general-hospital-igumale',
      id: 'zone:north-central:state:benue:lga:ado:sdp:general-hospital-igumale'
    }
  */
  properties.id = locationId.join(':')
  return properties
}
