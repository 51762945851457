const tools = require('../../tools')

exports.listAll = async (state, { date, includeInactive, dontFilterServices } = {}, filters = {}) => {
  const funderOrRouteFilter = dontFilterServices ? [] : tools.getUserFilter(state.user)

  const isoDate = new Date(date).toJSON()
  const docs = await state.dal.location.listAll(state, filters)
  const allConfigurations = await state.dal.configuration.listAll(state)

  const locations = []
  for (const doc of docs) {
    const location = tools.docToEntity({doc, date: isoDate, funderOrRouteFilter, allConfigurations})
    if (!includeInactive && tools.isInactiveLocation(location)) {
      continue
    }
    locations.push(location)
  }
  // This is to remove all retired locations
  return locations.filter(location => !location.retired)
}
