import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import flowRight from 'lodash/flowRight'

import { Loading } from '@fielded/shared-ui'

import { withApi } from '../../../../../common/ApiProvider'
import { withUser } from '../../../../../common/AuthenticationProvider'
import withConfig from '../../../../../van-shared/hoc/withConfig'
import withMasterData from '../../../common/withMasterData'
import withShipment from '../../../common/WithShipmentWrapper'
import { loadPickList } from '../../../../../root/reducers/shipments/pick-list'
import { batchIdToProductId } from '../../../common/utils'
import { selectProducts } from '../../../../../root/reducers/shipments/master-data'
import ErrorView from '../../../components/LoadingError'
import PSMInboundShipmentDetails from './PSMInboundShipmentDetails'

const PSMInboundShipmentDetailsContainer = ({
  api,
  user,
  config,
  history,
  shipment
}) => {
  const dispatch = useDispatch()
  const productsFromStore = useSelector(selectProducts)

  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)
  const [productsMap, setProductsMap] = useState(new Map())

  const initialize = async () => {
    try {
      await dispatch(loadPickList(shipment, productsFromStore, user))

      const productIds = Object.keys(shipment.counts).map(batchId => batchIdToProductId(batchId))
      const products = await api.product.getByIds(productIds)
      const productsMap = products.reduce(
        (map, p) => map.set(p._id, p), new Map()
      )

      setProductsMap(productsMap)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  if (error) {
    return <ErrorView error={error} />
  }

  if (loading) {
    return <Loading />
  }

  return (
    <PSMInboundShipmentDetails
      config={config}
      history={history}
      shipment={shipment}
      productsMap={productsMap}
    />
  )
}

const withHOCs = flowRight(
  withApi,
  withUser,
  withConfig,
  withMasterData,
  withShipment
)

export default withHOCs(PSMInboundShipmentDetailsContainer)
