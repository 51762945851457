const PGAdapter = require('../../common/pg-adapter')
const isPGUsageErrorCode = require('../../common/isPGUsageErrorCode')
const getLocationSkuHistoryQuery = require('./queries/get-location-sku-history-query')
const getReservationQuery = require('./queries/get-reservation-query')
const getLedgerQuery = require('./queries/get-ledger-query')
class LedgerPGAdapter extends PGAdapter {
  constructor ({pgConnection, username, logger}) {
    const columns = [
      'created_at',
      'updated_at',
      'id',
      'location_id',
      'sku',
      'event_id',
      'partner_balance',
      'sl_balance',
      'date',
      'is_stockcount',
      'ledger_id',
      '_deleted'
    ]
    super(pgConnection, 'avocado.couch_ledgers', username, columns, 'id')
    this.logger = logger
  }

  async getOne (locationFsid, opts = {includeShipments: false, includeOrders: false}) {
    // If FSID convert to UUID
    const locationUuid = await this.getLocationUUID(locationFsid)

    let row = { stock: {} }
    try {
      const { rows } = await this.pgConnection.query(getLedgerQuery, [
        locationUuid, locationFsid
      ])
      if (rows.length) {
        row = rows[0]
        const finalAmounts = row.stock.reduce((acc, item) => {
          acc[`product:${item.sku}`] = item.amount
          if (opts.includeShipments) {
            acc[`product:${item.sku}`] += item.shipments || 0
          }
          if (opts.includeOrders) {
            acc[`product:${item.sku}`] += item.orders || 0
          }
          return acc
        }, {})
        row.stock = finalAmounts
      }
    } catch (err) {
      if (isPGUsageErrorCode(err.code)) {
        err.status = 400
      }
      throw err
    }
    return row.stock
  }

  async getLocationSkuHistory (locationId, productId) {
    let transformedProductId = productId

    if (productId.includes('product:')) {
      transformedProductId = productId.replace('product:', '')
    }

    let results = []
    try {
      const {rows} = await this.pgConnection.query(getLocationSkuHistoryQuery, [locationId, transformedProductId])
      if (rows.length) {
        results = rows
      }
    } catch (err) {
      if (isPGUsageErrorCode(err.code)) {
        err.status = 400
      }
      throw err
    }
    return results
  }

  async getSkuReservations (locationId, productId) {
    // If FSID convert to UUID
    const transformedLocationId = await this.getLocationUUID(locationId)

    let transformedProductId = productId

    if (productId.includes('product:')) {
      transformedProductId = productId.replace('product:', '')
    }

    // Set a default to zero for when theres no data i.e no shipments or orders with this sku
    let result = {
      shipments: 0,
      orders: 0,
      total: 0,
      rows: []
    }

    try {
      const {rows} = await this.pgConnection.query(getReservationQuery, [transformedLocationId, transformedProductId, locationId])
      if (rows.length) {
        const row = rows[0]
        const { shipment_reservations: shipmentReservations, order_reservations: orderReservations } = row
        const reservationRows = [...shipmentReservations || [], ...orderReservations || []]

        const transformedRows = reservationRows.reduce((acc, row) => {
          const { type } = row
          if (!type) return acc

          const reservationObject = {
            location: row.destination,
            quantity: row.amount,
            type,
            id: type === 'shipment'
              ? row.shipmentId
              : row.orderId
          }

          acc.push(reservationObject)
          return acc
        }, [])

        result.shipments = parseInt(row.shipments) || 0
        result.orders = parseInt(row.orders) || 0
        result.total = result.shipments + result.orders
        result.rows = transformedRows
      }
    } catch (err) {
      if (isPGUsageErrorCode(err.code)) {
        err.status = 400
      }
      throw err
    }
    return result
  }

  async getLocationUUID (locationId) {
    if (locationId.includes('country')) {
      try {
        const {rows} = await this.pgConnection.query('SELECT uuid FROM avocado.data_location WHERE fsid = $1', [locationId])

        if (rows[0].length === 0) {
          const err = new Error('Location not found')
          err.status = 404
          throw err
        }

        return `${rows[0].uuid}`
      } catch (err) {
        if (isPGUsageErrorCode(err.code)) {
          err.status = 400
        }
        throw err
      }
    }
    return locationId
  }

  async create ({sku, date}) {
    throw new Error('Ledger cannot be modified directly')
  }
  async delete () {
    throw new Error('Ledger cannot be modified directly')
  }
  async update () {
    throw new Error('Ledger cannot be modified directly')
  }
}

module.exports = {LedgerPGAdapter}
