const get = require('lodash/get')
const {warehouseCodeToVirtualId} = require('../../../../order/tools/read/warehouse-code-to-virtual')
const {parse, endOfDay} = require('date-fns')
const toNumber = require('lodash/toNumber')
const toBatchId = require('../../../../utils/batch-id')
const generateShipmentNumber = require('../../generate-shipment-number')
const {sanitiseBatchId, sanitiseManufacturer} = require('../../../../batch/tools')
const PLANNING_TYPES = require('../../planning-types')

function translateCodes ({rows, products}, useFunderSuffixForWarehouse) {
  return rows.map(row => {
    let destinationId = null
    if (row.productCodeAlias === 'none') {
      // because our template doesn't have locationId on it and I didn't want to confuse it
      // with locationId
      destinationId = row.destinationWarehouseCode
      return Object.assign({}, row, {destinationId})
    }

    if (!(['one', 'epn'].includes(row.productCodeAlias))) {
      // we will throw on this in validation
      return row
    }

    try {
      destinationId = warehouseCodeToVirtualId({
        warehouseCode: row.destinationWarehouseCode,
        funderId: row.funderId,
        programId: row.programId,
        useFunderSuffixForWarehouse
      })
    } catch (error) {
      // skipping this so validation picks it up with a
      // message & row number
    }

    const aliasId = row.productId.replace(/^product:/, '')
    const aliasProduct = products.find(product => {
      const aliases = get(product, `alias.${row.productCodeAlias}`, [])
      return aliases.includes(aliasId)
    })

    // if an alias product could not be found, we throw in validate rows
    const productId = aliasProduct ? aliasProduct._id : null

    // batchAlias is so creating new batches can know what alias to store on it
    const batchAlias = {[row.productCodeAlias]: aliasId}
    return Object.assign({}, row, {destinationId, productId, batchAlias})
  })
}

function rowsToShipmentSnapshots (rows) {
  const byDestinationDate = rows
    .reduce((acc, row) => {
      const {destinationId, originId, date, funderId, programId, planningType} = row
      // our best guess for a unique shipment
      const key = `${destinationId}${date}${funderId}${programId}`
      acc[key] = acc[key] || {destinationId, originId, date, funderId, programId, planningType, rows: []}
      acc[key].rows.push(row)
      return acc
    }, {})

  const withCounts = Object.values(byDestinationDate)
    .map(shipmentRow => {
      const {destinationId, originId, date, rows, funderId, programId, planningType} = shipmentRow
      const counts = rows
        .reduce((acc, row) => {
          const {batchId} = row
          acc[batchId] = acc[batchId] || {quantity: 0}
          acc[batchId].quantity += row.quantity
          return acc
        }, {})

      return {destinationId, originId, date, funderId, programId, counts, planningType}
    })

  return withCounts
    .map(shipmentObject => {
      return {
        date: shipmentObject.date.split('T')[0],
        // NB intentionally hard coded, if we need dynamic origins
        // on shipment imports, add tests & validation for it.
        origin: 'country',
        destination: shipmentObject.destinationId,
        shipmentNo: generateShipmentNumber(),
        status: 'pre-advice',
        shipmentTypeId: `allocated:${shipmentObject.funderId}`,
        planningType: shipmentObject.planningType || PLANNING_TYPES.DEFAULT,
        // we are overriding overrideCreatedAt so this shipment
        // is seen in the ledger at the time specified in the import file,
        // not whne the file was imported.
        overrideCreatedAt: shipmentObject.date,
        programId: shipmentObject.programId,
        funderId: shipmentObject.funderId,
        counts: shipmentObject.counts
      }
    })
}

// parseRow called first in import api file so we can use its result
// to figure out what batches to create.
function parseRow (row) {
  // end of day to make it not edge case to the previous month
  const expiry = row.expiry
    ? endOfDay(parse(row.expiry)).toJSON()
    : undefined

  const manufacturer = row.manufacturer ? sanitiseManufacturer(row.manufacturer) : null
  const batchId = sanitiseBatchId(toBatchId(Object.assign({}, row, {manufacturer})))

  return Object.assign({}, row, {
    expiry,
    batchId,
    manufacturer,
    // endOfDay to make it not edge case to the previous day
    date: endOfDay(parse(row.date)).toJSON(),
    quantity: toNumber(row.quantity)
  })
}

module.exports = {
  rowsToShipmentSnapshots,
  parseRow,
  translateCodes
}
