import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Form, Page, ShelfLifeLogo } from '@fielded/shared-ui'
import VersionInfo from '../common/VersionInfo'
import LoginIntercomLink from '../common/LoginIntercomLink'
import { hasFeature } from '../van-shared/utils/features'
import withConfig from '../van-shared/hoc/withConfig'

const isCookieEnabled = () => {
  if (!navigator.cookieEnabled) {
    return false
  }

  const cookieKey = 'fs_supports_cookies'
  document.cookie = `${cookieKey}=yes`

  if (document.cookie.indexOf(cookieKey) === -1) {
    return false
  }
  document.cookie = `${cookieKey}=;Expires=` + new Date(0).toUTCString()

  return true
}

const Login = ({
  config,
  errorMessage,
  onSubmit,
  loginInProgress,
  extras
}) => {
  const hasWhiteLabel = hasFeature(config.features, 'useWhiteLabel')

  const isPSM = config.app === 'psm' && !hasWhiteLabel
  const isShelfLife = config.app === 'shelflife'
  const hasAside = isPSM
  const allowResetPass = hasFeature(config.features, 'allowResetPass')

  const loginProgramHead = (
    <div className='login__intro'>
      {config.name && isShelfLife &&
        (
          <h1 className='login__logo'>
            <ShelfLifeLogo fill='#fff' />
          </h1>
        )
      }
      {config.name && isPSM && <h1 className='login__app-name'>{config.name} {`(${config.shortName})`}</h1>}
      {config.name && !(isShelfLife || isPSM) &&
        (
          <h1 className='login__logo'>
            <span className='vs-u-visually-hidden'>{config.name}</span>
            {config.logoUrl && (
              <img src={config.logoUrl} alt='Logo' className='login__logo' />
            )}
          </h1>
        )
      }
      {config.program && !hasWhiteLabel && <h2 className='login__program'>{config.program}</h2>}
    </div>
  )

  return (
    <div className={classnames(
      'login',
      {'login--has-aside': hasAside}
    )}
    >
      <Page situation='ready' className='login__main' contentClassName='login__main-inner' title={config.title}>
        {isShelfLife && (
          <div className='login__texture' />
        )}
        {loginProgramHead}
        <Page.Panel
          withBackground
          withMargin
          className='login__panel'
        >
          <Page.Panel.Section>
            <div className='login__panel-title'>
              Log in with your username and password.
            </div>
          </Page.Panel.Section>
          {!isCookieEnabled() && (
            <Page.Panel.Section>
              Your browser does not have cookies enabled. <br />
              Cookies must be enabled in order to log in.
            </Page.Panel.Section>
          )}

          {errorMessage && (
            <Page.Panel.Message type='warning'>
              {errorMessage}
            </Page.Panel.Message>
          )}

          {extras.sessionExpired && (
            <Page.Panel.Message type='info'>
              Your session has expired, please log in again.
              You need to be online every 30 days to avoid this.
              Any drafts and stored work will be available after logging in again.
            </Page.Panel.Message>
          )}
          <Page.Panel.Section>
            <Form
              onSubmit={onSubmit}
              component='form'
            >
              <Form.Row>
                <Form.Field
                  fieldName='username'
                  labelText='Your username'
                >
                  <Form.Field.Text
                    autoFocus
                    autoCapitalize='off'
                    placeholder='username'
                    required='Please provide a username'
                    disabled={loginInProgress}
                    tabIndex='1'
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </Form.Row>

              <Form.Row>
                <Form.Field
                  fieldName='password'
                  labelText='Your password'
                  className='login__field-password'
                  labelLink={(allowResetPass && isShelfLife)
                    ? (
                      <NavLink
                        to='/forgot-password'
                        className='vs-u-link'
                      >
                        Forgot your password?
                      </NavLink>
                    )
                    : null
                  }
                >
                  <Form.Field.Text
                    type='password'
                    placeholder='password'
                    required='Please provide a password'
                    disabled={loginInProgress}
                    tabIndex='1'
                    autoCapitalize='off'
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </Form.Row>
              {extras.loginAs && extras.loginAs === 'admin' && (
                <Form.Row>
                  <Form.Field
                    fieldName='loginAsUser'
                    labelText='Username That you want to login'
                  >
                    <Form.Field.Text
                      autoFocus
                      autoCapitalize='off'
                      placeholder='username'
                      required='Please provide a username that you want to login'
                      disabled={loginInProgress}
                      tabIndex='1'
                    />
                    <Form.Field.ValidationErrors />
                  </Form.Field>
                </Form.Row>
              )}
              <Form.Actions className='login__field-actions' nowrap>
                {isShelfLife &&
                  <span>
                    Don’t have an account? <a
                      href='https://shelflife.co/signup/'
                      className='vs-u-link login__actions-link'
                    >
                      Sign up today
                    </a>
                  </span>
                }

                {!isShelfLife &&
                  <NavLink
                    to='/forgot-password'
                    className='vs-u-link'
                  >
                    Forgot your password?
                  </NavLink>
                }
                <Form.Button
                  className='vs-u-nowrap'
                  alignOpposite
                  size='large'
                  disabled={loginInProgress}
                  loading={loginInProgress}
                  tabIndex='1'
                >
                  Log in
                </Form.Button>
              </Form.Actions>
            </Form>
          </Page.Panel.Section>
        </Page.Panel>
        <LoginIntercomLink config={config} />

        {isPSM && (config.logoUrl || config.unit) && (
          <Page.Panel
            withMargin={false}
            spread
            className='login__foot'
          >
            {config.logoUrl && (
              <img src={config.logoUrl} alt='Logo' className='login__logo' />
            )}
            {config.unit && (
              <div className='login__client-name'>{config.unit}</div>
            )}
            <VersionInfo />
          </Page.Panel>
        )}

        {isShelfLife &&
          <Page.Panel
            withMargin={false}
            spread
            className='login__foot'
          >
            <footer className='login__footnote'>
              Shelf Life is a retail pharmacy revolution in Africa. Find out more at <a className='login__footnote-link' href='https://shelflife.co/' rel='noreferrer noopener' targer='_blank'>shelflife.co</a>
            </footer>
          </Page.Panel>
        }
      </Page>

      {isPSM &&
        <aside className='login__aside'>
          {loginProgramHead}
          <aside className='login__info'>
            <p className='login__info-highlight'>
              The Nigeria Health LMIS is Nigeria’s first national health LMIS, providing visibility into the country’s stock situation down to the last mile.
            </p>
            <p className='login__info-paragraph'>
              The Nigeria Health LMIS enables stakeholder at all levels to analyse critical data trends and helps to support inventory management decision-making processes.
            </p>
            <p className='login__info-highlight'>
              Secure, offline-capable, and user-friendly
            </p>
            <p className='login__info-paragraph'>
              The Nigeria Health LMIS uses offline-capable, cloud based technology to capture, store, track and assess logistics data across the nation’s pharmaceutical supply chain for key public health programmes.
            </p>
          </aside>
        </aside>}
    </div>
  )
}

Login.propTypes = {
  config: PropTypes.object.isRequired,
  loginInProgress: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
}

Login.defaultProps = {
  loginInProgress: false,
  errorMessage: null
}

export default withConfig(Login)
