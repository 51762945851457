class Report {
  constructor (quickbooks) {
    this.quickbooks = quickbooks
  }

  async getTransactionList ({
    companyCode,
    columns,
    customerIds = null,
    timeframe = null,
    startDate = null,
    endDate = null,
    entityTypes = null
  }) {
    const searchParams = [
      `minorversion=${this.quickbooks.quickbooksInterface.apiVersion}`,
      `columns=${encodeURIComponent(columns.join(','))}`,
      timeframe ? `date_macro=${timeframe}` : null,
      startDate ? `start_date=${startDate}` : null,
      endDate ? `end_date=${endDate}` : null,
      entityTypes && Array.isArray(entityTypes) && entityTypes.length
        ? `transaction_type=${encodeURIComponent(entityTypes.join(','))}`
        : null,
      customerIds && Array.isArray(customerIds) && customerIds.length
        ? `customer=${encodeURIComponent(customerIds.join(','))}`
        : null
    ].filter(p => !!p).join('&')

    const resp = await this.quickbooks.quickbooksInterface.get(
      companyCode, `reports/TransactionList?${searchParams}`
    )
    return resp
  }

  async getCustomerTransactionList ({ companyCode, customerId, startDate, endDate, txnTypes }) {
    const columns = ['txn_type', 'tx_date', 'create_date', 'memo']
    const resp = await this.getTransactionList(
      { companyCode, customerIds: [customerId], startDate, endDate, columns, entityTypes: txnTypes }
    )
    // Map from Quickbooks columns description + data array to array of structs
    const keys = resp.Columns.Column.map(c => c.MetaData[0].Value)
    const rows = []

    if (!resp.Rows.Row) {
      return rows
    }

    for (const qboRow of resp.Rows.Row) {
      if (qboRow.type !== 'Data') {
        continue
      }
      const row = {}
      for (const [i, cell] of qboRow.ColData.entries()) {
        const key = keys[i]
        if (key === 'txn_type') {
          row.type = cell.value.replace(' ', '')
          row.id = cell.id
        } else if (key === 'tx_date') {
          row.txnDate = cell.value
        } else if (key === 'create_date') {
          row.createdAt = cell.value
        } else if (key === 'memo') {
          row.PrivateNote = cell.value
        }
      }
      rows.push(row)
    }
    return rows
  }
}

module.exports = {
  Report
}
