import React from 'react'
import classnames from 'classnames'

import { Checkbox } from '@fielded/shared-ui'
import { getProgramReadableName } from '@fielded/fs-api/lib/program/tools'
import { SHIPMENT_STATUS } from '@fielded/fs-api/lib/shipment/constants'

import PSMDeliveryCard from '../shared/PSMDeliveryCard/PSMDeliveryCard'

const PSMDriverDeliveryCard = ({
  shipment,
  programs,
  history,
  headerTitle,
  showCompleted,
  selectedDeliveries,
  onToggleCheckbox,
  singleActionOnly,
  isDriver
}) => {
  const {
    status,
    snapshotId
  } = shipment
  const isDeliverySelected = !!selectedDeliveries.find(d => d.snapshotId === shipment.snapshotId)
  const isCheckboxDisabled = status !== SHIPMENT_STATUS.PACKED
  const programName = getProgramReadableName(shipment.programId, programs)
  const { ARRIVED, RECEIVED } = SHIPMENT_STATUS
  const target = [ARRIVED, RECEIVED].includes(status)
    ? `/shipments/pick-list/${snapshotId}`
    : `/shipments/delivery/${snapshotId}`

  return (
    <PSMDeliveryCard
      headerTitle={headerTitle}
      history={history}
      programName={programName}
      shipment={shipment}
      target={target}
      isDriver={isDriver}
    >
      {!showCompleted && !singleActionOnly && (
        <Checkbox.Stateless
          className={classnames(
            'driver-delivery-card__checkbox',
            {'driver-delivery-card__checkbox--disabled': isCheckboxDisabled}
          )}
          checked={isDeliverySelected}
          disabled={isCheckboxDisabled}
          onChange={onToggleCheckbox}
        />
      )}
    </PSMDeliveryCard>
  )
}

export default PSMDriverDeliveryCard
