import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import flowRight from 'lodash/flowRight'
import keyBy from 'lodash/keyBy'

import { Loading } from '@fielded/shared-ui'

import withConfig from '../../../../../../van-shared/hoc/withConfig'
import { withApi } from '../../../../../../common/ApiProvider'
import { withUser } from '../../../../../../common/AuthenticationProvider'
import { VERIFICATION_STATUS, decorateAndFilterShipmentsWithAlerts } from './utils'
import {
  queryStringifyFilters,
  resolveQueryStringFilterState
} from '../../../../../../common/querystring-filters'
import {
  getCurrentPeriod,
  PERIOD_MONTH,
  periodToDateRange
} from '../../../../../../common/periods'

import ErrorView from '../../../../components/LoadingError'
import LMDList from './LMDList'

const PAGE_LIMIT = 50
const validSearchFilters = ['time', 'route', 'state', 'source', 'destination', 'program', 'funder', 'creationDate', 'status', 'thirdParty', 'limit', 'offset', 'verification']

const byId = (list) => keyBy(list, '_id')

const LMDListContainer = ({ api, user, config }) => {
  const history = useHistory()
  const defaultTime = getCurrentPeriod(PERIOD_MONTH)
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)
  const [loadingShipments, setLoadingShipments] = useState(false)
  const [shipments, setShipments] = useState([])
  const [appPrograms, setAppPrograms] = useState()
  const [fundersById, setFundersById] = useState({})
  const [locationsById, setLocationsById] = useState({})
  const [routesById, setRoutesById] = useState({})
  const [totalShipmentsNumber, setTotalShipmentsNumber] = useState(0)
  const [filterOptions, setFilterOptions] = useState({})

  // Create filters configurations object applying default config as a base
  const availableFilters = validSearchFilters.reduce((acc, filterName) => {
    acc[filterName] = { values: [] }
    return acc
  }, {})

  // Some filters configurations need non-default values
  availableFilters.time.defaultValue = defaultTime
  availableFilters.limit.defaultValue = PAGE_LIMIT
  availableFilters.offset.defaultValue = 0

  const initialize = async () => {
    try {
      /* Fetch the data wih parallel requests promises */
      const [
        locationsById,
        fundersById,
        routesById,
        appPrograms,
        { shipments, total },
        filterOptions
      ] = await Promise.all([
        api.location.listAll().then(byId).catch(() => { throw new Error('listAll') }),
        api.funders.list().then(byId).catch(() => { throw new Error('fundersList') }),
        api.routes.list().then(byId).catch(() => { throw new Error('routesList') }),
        api.program.list(true).catch(() => { throw new Error('programsList') }),
        getShipments().catch(() => { throw new Error('getShipments') }),
        getFilteringValues().catch(() => { throw new Error('getFilteringValues') })
      ])

      /* Set the state with the results */
      setFilterOptions(filterOptions)
      setShipments(shipments)
      setTotalShipmentsNumber(total)
      setLocationsById(locationsById)
      setFundersById(fundersById)
      setRoutesById(routesById)
      setAppPrograms(appPrograms)
    } catch (e) {
      if (e.toString() === 'Error: fundersList') {
        // Do whatever you need to do when the funders list fails
      }
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  const getApiFilters = ({filters = {}, usePaginationOptions = false, withQueryString = true}) => {
    let queryStringFilters = {}
    if (withQueryString) {
      queryStringFilters = getQueryStringFilters()
    }
    const effectiveFilter = { ...queryStringFilters, ...filters }
    if (withQueryString) {
      history.push({ ...history.location, search: queryStringifyFilters(effectiveFilter) })
    }

    const periodDates = periodToDateRange(effectiveFilter.time)

    const paginationOptions = usePaginationOptions && {
      offset: effectiveFilter.offset,
      limit: effectiveFilter.limit
    }

    const translatedFilter = {
      origin_id: effectiveFilter.source,
      program_id: effectiveFilter.program,
      vendor_id: effectiveFilter.thirdParty,
      route_id: effectiveFilter.route,
      destination_id: effectiveFilter.destination,
      funder_id: effectiveFilter.funder,
      status: effectiveFilter.status,
      created_at: effectiveFilter.creationDate,
      state: effectiveFilter.state,
      updated_at_gte: periodDates.from,
      updated_at_lte: periodDates.to,
      ...paginationOptions
    }

    // Clean up nulls and undefined values
    const cleanedUpFilter = Object.keys(translatedFilter).reduce((acc, key) => {
      if (translatedFilter[key]) {
        acc[key] = translatedFilter[key]
      }
      return acc
    }, {})

    return cleanedUpFilter
  }

  const exportDeliveries = async () => {
    const blob = await api.shipment.exportLMDShipments({api, shipments})
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.href = url
    link.download = `shipment-line-export-${new Date().toJSON()}.xlsx`
    document.body.appendChild(link)
    link.click()
  }

  const getShipments = async (update = {}) => {
    const filters = getApiFilters({filters: update, usePaginationOptions: true})
    if (!filters.program_id) {
      filters.program_id = user.programs
    }

    return api.shipment.listLastMileDeliveries({ filters })
  }

  const getFilteringValues = async () => {
    // Only time period condition is needed to get the filter values
    const { time } = getQueryStringFilters()
    const filters = getApiFilters({filters: { time }, usePaginationOptions: false, withQueryString: false})

    if (!filters.program_id) {
      filters.program_id = user.programs
    }

    const values = await api.shipment.getLastMileDeliveriesFilterValues(undefined, { filters })

    // ensure null values get converted to undefined for proper later handling
    const valuesWithoutNulls = Object.keys(values).reduce((acc, key) => {
      acc[key] = values[key] || undefined
      return acc
    }, {})

    const {
      origin_id: source = [],
      program_id: program = [],
      vendor_id: thirdParty = [],
      route_id: route = [],
      destination_id: destination = [],
      funder_id: funder = [],
      status = [],
      created_at: creationDate = [],
      state = []
    } = valuesWithoutNulls
    return {
      source,
      program,
      thirdParty,
      route,
      destination,
      funder,
      status,
      creationDate,
      state,
      verification: Object.values(VERIFICATION_STATUS)
    }
  }

  const onFilterChange = async (update) => {
    setLoadingShipments(true)
    const promisesToAwait = [
      getShipments(update)
    ]
    if (update.time) {
      // If the time filter changes, we need to update the filter options
      promisesToAwait.push(getFilteringValues().then(
        filterOptions => setFilterOptions(filterOptions))
      )
    }

    // Any filter option can make an API request apart from the verification filter.
    if (!update.verification) {
      const [{ shipments, total }] = await Promise.all(promisesToAwait)
      setShipments(shipments)
      setTotalShipmentsNumber(total)
    }

    setLoadingShipments(false)
  }

  const getQueryStringFilters = () => {
    const queryStringFilters = resolveQueryStringFilterState(
      history.location.search,
      { sorters: {}, history, availableFilters, withValueValidation: false, withUndefinedValues: false }
    ).filters
    return {
      ...queryStringFilters,
      limit: parseInt(queryStringFilters.limit),
      offset: parseInt(queryStringFilters.offset)
    }
  }

  const onPageChange = (offset) => {
    onFilterChange({ offset })
  }

  if (error) {
    return <ErrorView error={error} />
  }

  if (loading) {
    return <Loading />
  }

  const updatedFilters = getQueryStringFilters()

  const showViewRoute = !!updatedFilters.route

  let routeLink = ''

  if (showViewRoute) {
    routeLink = `/settings/routePlanning/view/${updatedFilters.route}`
  }

  const filterAlerts = updatedFilters.verification === VERIFICATION_STATUS.UNVERIFIED

  // Decorate shipment with delivery alerts
  const shipmentsWithAlerts = decorateAndFilterShipmentsWithAlerts(shipments, !!filterAlerts)

  return (
    <LMDList
      history={history}
      config={config}
      shipments={shipmentsWithAlerts}
      filters={updatedFilters}
      filterOptions={filterOptions}
      onFilterChange={onFilterChange}
      onPageChange={onPageChange}
      programs={user.programs}
      locationsById={locationsById}
      fundersById={fundersById}
      routesById={routesById}
      appPrograms={appPrograms}
      totalShipmentsNumber={totalShipmentsNumber}
      showViewRoute={showViewRoute}
      routeLink={routeLink}
      exportDeliveries={exportDeliveries}
      pageLimit={updatedFilters.limit}
      loadingShipments={loadingShipments}
    />
  )
}

export default flowRight(withApi, withUser, withConfig)(LMDListContainer)
