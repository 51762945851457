const PGAdapter = require('./../common/pg-adapter')
const { getInsertProperties } = require('../../lib/tools/sql-tools')
const { isDeleted } = require('./tools/utils')
const TRANSACTIONS_TABLE_NAME = 'avocado.data_quickbooksrawtransactions'
const TRANSACTIONS_COLUMNS = [
  'id',
  'company_code',
  'txn_id',
  'txn_type',
  'created_at',
  'updated_at',
  'raw_data'
]

class QuickbooksRawTransactionsAdapter extends PGAdapter {
  constructor (pgConnection, username) {
    super(
      pgConnection,
      TRANSACTIONS_TABLE_NAME,
      username,
      TRANSACTIONS_COLUMNS,
      'id'
    )
  }

  async getRawRow (companyCode, id, txnType) {
    const txnTypeFilter = txnType ? ' AND txn_type = $3 ' : ''
    const params = txnType ? [id, companyCode, txnType] : [id, companyCode]
    const { rows } = await this.pgConnection.query(`
      SELECT * FROM ${TRANSACTIONS_TABLE_NAME}
      WHERE txn_id = $1 AND company_code = $2 ${txnTypeFilter}
    `, params)
    return rows[0]
  }

  async getLastRawTransaction (companyCode) {
    const query = `
      SELECT * FROM ${TRANSACTIONS_TABLE_NAME}
      WHERE company_code = $1
      ORDER BY "created_at" DESC LIMIT 1
    `
    const { rows } = await this.pgConnection.query(query, [companyCode])
    return rows[0]
  }

  async getLoanAccountFeeDeposits (loanAccountId, companyCode) {
    const query = `
      with deposit_lines as (
        select
          jsonb_array_elements((rt.raw_data ->> 'Line')::text::jsonb) as lines,
          rt.raw_data -> 'MetaData' ->> 'CreateTime' as created_at
        from ${TRANSACTIONS_TABLE_NAME} rt
        where (
          raw_data -> 'Line' -> 0 -> 'DepositLineDetail' -> 'AccountRef' ->> 'value' = $1
            OR
          raw_data -> 'Line' -> 1 -> 'DepositLineDetail' -> 'AccountRef' ->> 'value' = $1
        ) and company_code = $2
      )
      select
        dl.lines -> 'DepositLineDetail' -> 'AccountRef' ->> 'name' as "accountName",
        dl.lines -> 'DepositLineDetail' -> 'AccountRef' ->> 'value' as "accountId",
        (dl.lines ->> 'Amount')::float as amount,
        dl.created_at as "createdAt"
      from deposit_lines dl
      where dl.lines -> 'DepositLineDetail' -> 'AccountRef' ->> 'value' != $1
      order by dl.created_at
    `
    const { rows } = await this.pgConnection.query(query, [loanAccountId, companyCode])
    return rows
  }

  async upsertRawTransactions (rows) {
    if (rows.length === 0) {
      return []
    }
    const {
      columns,
      values,
      placeholders
    } = getInsertProperties(rows)
    await this.pgConnection.query(`
      INSERT INTO ${TRANSACTIONS_TABLE_NAME} AS t (${columns})
      VALUES ${placeholders}
      ON CONFLICT (txn_id, txn_type, company_code) DO
         UPDATE SET updated_at = excluded.updated_at
                  , raw_data = excluded.raw_data
                  , sync_token = excluded.sync_token
         WHERE coalesce(excluded.sync_token, '0')::integer >= coalesce(t.sync_token, '0')::integer
            OR excluded.raw_data->>'status' = 'Deleted'
    `, values)
    return rows.map(r => {
      return {
        txnId: r.txn_id,
        txnType: r.txn_type
      }
    })
  }

  async updateRawDeleted (rows) {
    if (rows.length === 0) {
      return []
    }
    const {
      columns,
      values,
      placeholders
    } = getInsertProperties(rows, [
      'company_code',
      'txn_id',
      'updated_at',
      'raw_data',
      'txn_type'
    ])
    const { rows: updatedRows } = await this.pgConnection.query(`
      UPDATE ${TRANSACTIONS_TABLE_NAME} AS r
         SET updated_at = d.updated_at::timestamptz
           , raw_data = d.raw_data::jsonb
      FROM (VALUES ${placeholders}) AS d (${columns})
      WHERE d.company_code = r.company_code
        AND d.txn_id = r.txn_id
        AND d.txn_type = r.txn_type
      RETURNING r.txn_id as "txnId", r.txn_type as "txnType"
    `, values)
    return updatedRows
  }

  async saveRawTransactions (rawRows) {
    const updatedRows = []
    const deletedRows = []
    for (const r of rawRows) {
      if (isDeleted(r.raw_data)) {
        deletedRows.push(r)
      } else {
        updatedRows.push(r)
      }
    }
    const listOfIds = await Promise.all([
      this.upsertRawTransactions(updatedRows),
      this.updateRawDeleted(deletedRows)
    ])
    return [].concat(...listOfIds)
  }
}

module.exports = QuickbooksRawTransactionsAdapter
