import React, { createContext, useContext, useEffect } from 'react'
import * as amplitude from '@amplitude/analytics-browser'
import { isShelflife } from '../van-shared/utils/utils'

export const AnalyticsContext = createContext()
export const useAmplitudeAnalytics = () => useContext(AnalyticsContext)

const getAmplitudeAnalyticsValues = (shouldTrackAnalytics) => {
  if (!shouldTrackAnalytics) {
    return
  }

  return {
    setUser: (id) => {
      amplitude.setUserId(id)
    },
    trackEvent: (eventName, data = {}) => {
      amplitude.track(eventName, data)
    }
  }
}

const AmplitudeAnalyticsProvider = ({ user, config, children }) => {
  // We want to track all SL users for now.
  const shouldTrackAmplitudeAnalytics = isShelflife(config)
  const amplitudeAnalytics = getAmplitudeAnalyticsValues(shouldTrackAmplitudeAnalytics)

  const initialize = () => {
    if (!shouldTrackAmplitudeAnalytics) {
      return
    }

    const { apiKey } = config.amplitude
    amplitude.init(apiKey, {
      autocapture: {
        attribution: true,
        pageViews: true,
        sessions: true,
        formInteractions: true,
        fileDownloads: true,
        elementInteractions: true
      }
    })
  }

  useEffect(() => {
    initialize()
  }, [])

  return (
    <AnalyticsContext.Provider value={amplitudeAnalytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export default AmplitudeAnalyticsProvider
