import React, { Fragment } from 'react'

import { Form, Page, Text } from '@fielded/shared-ui'
import { Upload } from '@fielded/shared-ui/src/icons'

const PSMInboundShipmentsOverviewUpload = ({
  documentFormRef,
  isUploading,
  onUploadTemplate
}) => {
  return (
    <Fragment>
      <Page.Panel.Section>
        <Text.Heading>Upload updated shipment file</Text.Heading>
        <Text>
          After filling in the shipment information in the downloaded template, upload the file here. Make sure all required fields are complete and the file is saved in the correct format (Excel) before uploading.
        </Text>
      </Page.Panel.Section>

      <Page.Panel.Section>
        <Form ref={documentFormRef} component='form'>
          <Form.Section>
            <Form.Row>
              <Form.Field
                fieldName='file'
              >
                <Form.Field.FileUploadAdvanced
                  acceptText='.xlsx'
                  required
                />
                <Form.Field.ValidationErrors />
              </Form.Field>
            </Form.Row>
          </Form.Section>
          <Form.Actions>
            <Form.Button
              alignOpposite
              icon={<Upload />}
              loading={isUploading}
              onClick={onUploadTemplate}
            >
              Upload file
            </Form.Button>
          </Form.Actions>
        </Form>
      </Page.Panel.Section>
    </Fragment>
  )
}

export default PSMInboundShipmentsOverviewUpload
