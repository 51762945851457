import { useEffect, useState } from 'react'

// This can only be used inside a function component
const usePageLoading = () => {
  const [isPageLoading, setIsPageLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      window.scroll({ top: 0, left: 0 })
      setIsPageLoading(false)
    }, 500)
  }, [])

  return { isPageLoading }
}

export { usePageLoading }
