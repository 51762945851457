import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import classnames from 'classnames'
import { isEPNWarehouse, isPSMDriver } from '../common/utils'
import { hasFeature } from '../../../van-shared/utils/features'

import PrintContainer from '../complete/PrintContainer'
import CompleteContainer from '../complete/CompleteContainer'
import NewShipmentContainer from '../planning/NewShipmentContainer'
import ShipmentCreateContainer from '../shipments/ShipmentsCreateContainer'
import ReturnsNoteContainer from '../components/ReturnsNote/ReturnsNoteContainer'
import AddProductContainer from '../planning/AddProductContainer'
import AddProductQuantityContainer from '../planning/AddProductQuantityContainer'
import CountContainer from '../count/CountContainer'
import UpdateCRQuantitiesContainer from '../count/UpdateCRContainer'
import AdjustmentContainer from '../count/AdjustmentContainer'
import ShipmentsContainer from '../shipments/ShipmentsContainer'
import PSMDriverShipmentsContainer from '../shipments/PSMDriverShipmentContainer'
import PSMInboundShipmentsContainer from '../shipments/psm-shipments/PSMInboundShipments/PSMInboundShipmentsContainer'
import DeliveriesContainer from '../deliveries/DeliveriesContainer'
import ShipmentOptionsContainer from '../shipments/ShipmentOptionsContainer'
import DeleteShipmentContainer from '../shipments/DeleteShipmentContainer'
import LastMileDeliveriesListContainer from '../shipments/psm-shipments/LastMileDeliveries/LMDList/LMDListContainer'
import LastMileDeliveriesDetailContainer from '../shipments/psm-shipments/LastMileDeliveries/LMDDeliveryDetail/LMDDeliveryDetailContainer'
import PSMShipmentExportImportContainer from '../shipments/psm-shipments/PSMShipmentExportImport/PSMShipmentExportImportContainer'
import PSMDeliveryStatement from '../complete/print-templates/psm-delivery-note/PSMDeliveryNote'
import PSMPackageNote from '../complete/print-templates/psm-package-note/PSMPackageNote'
import ConfirmPickUpContainer from '../shipments/psm-shipments/PSMDriverDelivery/ConfirmPickUp/ConfirmPickUpContainer'
import PSMDeliveryLocationContainer from '../shipments/psm-shipments/PSMDriverDelivery/PSMDeliveryLocation/PSMDeliveryLocationContainer'
import FacilityDeliveriesContainer from '../deliveries/psm-deliveries/PSMFacilityDeliveries/FacilityDeliveriesContainer'
import FacilityDeliveryDetailContainer from '../deliveries/psm-deliveries/PSMFacilityDeliveryDetail/FacilityDeliveryDetailContainer'
import PSMInboundShipmentsOverviewContainer from '../shipments/psm-shipments/PSMInboundShipments/PSMInboundShipmentsOverviewContainer'
import PSMInboundShipmentDetailsContainer from '../shipments/psm-shipments/PSMInboundShipments/PSMInboundShipmentDetailsContainer'

import DeleteProductsContainer from '../shipments/DeleteProductsContainer'
import DeleteProductsConfirmContainer from '../shipments/DeleteProductsConfirmContainer'
import ConfirmationContainer from '../confirmation/ConfirmationContainer'
import ConfirmationCommentContainer from '../confirmation/ConfirmationCommentContainer'
import ShipmentsImportContainer from '../shipments/ShipmentsImportContainer'
import ReturnShipmentContainer from '../return-shipment/ReturnShipmentContainer'

const Routes = ({ isOnline, rootHistory, basename, config, user }) => {
  const { features } = config
  const isEPNWarehouseUser = isEPNWarehouse({user, config})

  const shipmentHasPlanning = hasFeature(features, 'shipments:planning')
  const editPSMShipment = hasFeature(features, 'shipments:editPSMShipment')
  const canUploadShipments = hasFeature(features, 'shipments:uploadShipments') && isEPNWarehouseUser

  const userIsPSMDriver = isPSMDriver(user)
  let SelectedShipmentContainer = ShipmentsContainer

  if (userIsPSMDriver) {
    SelectedShipmentContainer = PSMDriverShipmentsContainer
  }

  if (isEPNWarehouseUser) {
    SelectedShipmentContainer = PSMInboundShipmentsContainer
  }

  return (
    <div className={classnames('van-shipments', { 'offline': !isOnline })}>
      {/* Redirect from / to /shipments with the help of `render`, because react
          router has issues with <Redirect/> and optional parameters on the target
          route of the redirect. */}
      <Switch>
        <Route exact path={`${basename}/`} render={() => (<Redirect to={`${basename}/list`} />)} />
        <Route exact path={`${basename}/list/`} component={SelectedShipmentContainer} />
        <Route exact path={`${basename}/completed/`}
          render={props => <SelectedShipmentContainer {...props} showCompleted />}
        />
        <Route exact path={`${basename}/deliveries/:location`} component={props => (
          <DeliveriesContainer {...props} rootHistory={rootHistory} />
        )} />

        {shipmentHasPlanning && (
          <Route path={`${basename}/new`} component={NewShipmentContainer} />
        )}

        {!shipmentHasPlanning && (
          <Route path={`${basename}/new`} component={ShipmentCreateContainer} />
        )}

        {editPSMShipment && (
          <Route exact path={`${basename}/:snapshotId/edit`} component={ShipmentCreateContainer} />
        )}

        {canUploadShipments && (
          <Route exact path={`${basename}/export-import`} component={PSMShipmentExportImportContainer} />
        )}

        <Route
          exact
          path={`${basename}/returns-note/:shipmentId`}
          render={({ history, match: { params: { shipmentId } } }) => (
            <ReturnsNoteContainer history={history} shipmentId={shipmentId} />
          )}
        />

        <Route exact path={`${basename}/count/:snapshotId`} component={CountContainer} />
        <Route exact path={`${basename}/adjustment/:snapshotId`} render={AdjustmentContainer} />
        <Route exact path={`${basename}/update-cr-quantities/:snapshotId`} component={UpdateCRQuantitiesContainer} />
        <Route exact path={`${basename}/pick-list/:snapshotId`} component={CountContainer} />
        <Route exact path={`${basename}/return-list/:snapshotId`} component={ReturnShipmentContainer} />

        <Route exact path={`${basename}/pick-list/:snapshotId/shipment-options`} component={ShipmentOptionsContainer} />
        <Route exact path={`${basename}/pick-list/:snapshotId/shipment-options/delete-shipment`} component={DeleteShipmentContainer} />
        <Route exact path={`${basename}/pick-list/:snapshotId/shipment-options/remove-products`} component={DeleteProductsContainer} />
        <Route exact path={`${basename}/pick-list/:snapshotId/shipment-options/remove-products/confirm`} component={DeleteProductsConfirmContainer} />
        <Route exact path={`${basename}/pick-list/:snapshotId/shipment-options/add-product`} component={AddProductContainer} />
        <Route exact path={`${basename}/pick-list/:snapshotId/shipment-options/add-product/quantity`} component={AddProductQuantityContainer} />
        <Route exact path={`${basename}/confirmation/:snapshotId`} component={ConfirmationContainer} />
        <Route exact path={`${basename}/confirmation/:snapshotId/comment`} component={ConfirmationCommentContainer} />
        <Route exact path={`${basename}/complete/:snapshotId`}
          component={props => <CompleteContainer {...props} rootHistory={rootHistory} />}
        />
        <Route exact path={`${basename}/complete/:snapshotId/print/:allStatements?`} component={PrintContainer} />
        <Route exact path={`${basename}/import`} component={ShipmentsImportContainer} />
        <Route exact path={`${basename}/last-mile-deliveries/:view?`} component={LastMileDeliveriesListContainer} />
        <Route exact path={`${basename}/last-mile-deliveries/:view/details/:snapshotId`} component={LastMileDeliveriesDetailContainer} />
        <Route exact path={`${basename}/last-mile-deliveries/delivery-statement/:snapshotId`} history={rootHistory} component={PSMDeliveryStatement} />
        <Route exact path={`${basename}/last-mile-deliveries/package-note/:snapshotId`} component={PSMPackageNote} />
        <Route exact path={`${basename}/confirm-pick-up`} component={ConfirmPickUpContainer} />
        <Route exact path={`${basename}/delivery/:snapshotId`} component={PSMDeliveryLocationContainer} />
        <Route exact path={`${basename}/facility/deliveries`} component={FacilityDeliveriesContainer} />
        <Route exact path={`${basename}/facility/deliveries/:snapshotId`} component={FacilityDeliveryDetailContainer} />
        <Route exact path={`${basename}/list/overview/:view`} component={PSMInboundShipmentsOverviewContainer} />
        <Route exact path={`${basename}/list/:snapshotId`} component={PSMInboundShipmentDetailsContainer} />
        <Route exact path={`${basename}/pick-up`} component={PSMDriverShipmentsContainer} />
        <Route exact path={`${basename}/pick-up/completed/`}
          render={props => <PSMDriverShipmentsContainer {...props} showCompleted />}
        />
      </Switch>
    </div>
  )
}

export default Routes
