import React from 'react'

import { Page, StatusPill } from '@fielded/shared-ui'
import { SHIPMENT_STATUS } from '@fielded/fs-api/lib/shipment/constants'
import { getProgramReadableName } from '@fielded/fs-api/lib/program/tools'

import { getNavigationItem } from '../../../../../../common/utils/navigationInfo'
import { formatDate } from '../../../../../../van-shared/utils/utils'
import { batchIdToProductId, isEPNWarehouse } from '../../../../common/utils'
import { getPlannedQuantities } from '../../../../common/makeDeliveryItems'

import ShipmentHeaderOptionButton from '../../../../common/ShipmentHeaderOptionButton'
import LMDDeliveryDetailTransferInfo from './LMDDeliveryDetailTransferInfo'
import LMDDeliveryDetailVerificationInfo from './LMDDeliveryDetailVerificationInfo'
import LMDDeliveryDetailTable from './LMDDeliveryDetailTable'

const LMDDeliveryDetail = ({
  user,
  history,
  config,
  delivery,
  productsMap,
  appPrograms,
  sourceName
}) => {
  const isEPNWarehouseUser = isEPNWarehouse({ config, user })
  const plannedQuantities = getPlannedQuantities(delivery)
  const isReceived = !!delivery.snapshotDates.received
  const isDeliveryPackedAlready = !!delivery.snapshotDates.packed

  const hasVerificationFlags = [SHIPMENT_STATUS.ARRIVED, SHIPMENT_STATUS.RECEIVED].includes(delivery.status)
  const hasHeaderOptionButton = isEPNWarehouseUser && [SHIPMENT_STATUS.NEW, SHIPMENT_STATUS.PACKED].includes(delivery.status)
  const baseUrl = '/shipments/last-mile-deliveries'

  const productList = Object.entries(delivery.counts).map(([key, value]) => {
    const product = value
    product.id = batchIdToProductId(key)
    product.name = productsMap.get(product.id).name
    if (plannedQuantities) {
      const packedProduct = plannedQuantities.counts[key]
      product.packedQty = packedProduct ? packedProduct.quantity : 0
    }
    return product
  })

  return (
    <Page
      className='shipments'
    >
      <Page.ComposableHeader>
        {hasHeaderOptionButton && (
          <div className='shipment-header__meta'>
            <ShipmentHeaderOptionButton
              shipment={delivery}
              redirectLink={`${baseUrl}/list/details/${delivery.snapshotId}`}
              isEditable
            />
          </div>
        )}
        <Page.ComposableHeader.Breadcrumb
          items={[
            getNavigationItem({ item: 'shipments', config, includePath: false }),
            {
              title: 'Last mile deliveries',
              path: baseUrl
            },
            {
              title: delivery.shipmentNo,
              active: true
            }
          ]}
        />
        <Page.ComposableHeader.Content spread>
          <Page.ComposableHeader.Title title={`Delivery to ${delivery.destination.name || delivery.destination.sdp}`} />
        </Page.ComposableHeader.Content>
      </Page.ComposableHeader>
      <Page.Panel reduceBottomMargin>
        <Page.Panel.Section>
          <div className='vs-u-row vs-u-flex-wrap vs-u-gap-half'>
            <StatusPill
              label='Driver'
              value={delivery.driverName || 'No information'}
            />
            <StatusPill
              label='ID'
              value={delivery.shipmentNo}
            />
            <StatusPill
              label='Order creation date'
              value={formatDate(delivery.createdAt, 'long')}
            />
            <StatusPill
              label='Source'
              value={sourceName}
            />
            <StatusPill
              label='Program'
              value={getProgramReadableName(delivery.programId, appPrograms) || 'No information'}
            />
            <StatusPill
              label='Funder'
              value={delivery.funder || 'No information'}
            />
          </div>
        </Page.Panel.Section>
      </Page.Panel>
      <Page.Panel
        withBackground
        reduceBottomMargin
        className='lmd-detail__transfer-panel'
      >
        <Page.Panel.Section>
          <LMDDeliveryDetailTransferInfo delivery={delivery} />
        </Page.Panel.Section>
      </Page.Panel>
      {hasVerificationFlags && (
        <Page.Panel withBackground>
          <LMDDeliveryDetailVerificationInfo delivery={delivery} />
        </Page.Panel>
      )}
      <Page.Panel withMinimalMargin>
        <LMDDeliveryDetailTable
          productList={productList}
          isReceived={isReceived}
          isPacked={isDeliveryPackedAlready}
        />
      </Page.Panel>
    </Page>
  )
}

export default LMDDeliveryDetail
