import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { keyboardMovement, keyboardActionMovement } from './../keyboard-nav'
import ReportEntryTotal from '../report-entry-total'
import Commits from '../Commits'
import AlertReportFieldContainer from '../report-field/AlertReportFieldContainer'
import ReportField from '../report-field/ReportField'
import { formatDate } from '../../../../van-shared/utils'
import { batchNumberFromId } from '../../common/utils'

class StockCountFormBatched extends Component {
  constructor (props) {
    super(props)
    this.state = { tabIndex: 0 }
  }
  componentDidUpdate (prevProps) {
    if (prevProps.productId !== this.props.productId) {
      // make the first element in the form active when any
      this.setState({ tabIndex: 0 })
    }
  }

  render () {
    const {
      alerts,
      batchesInReport,
      masterDataBatches,
      countedTotal,
      productId,
      commits = {},
      batchSize,
      onProductUpdate
    } = this.props

    const { tabIndex } = this.state

    const maxTabIndex = Object.keys(batchesInReport).length + Object.keys(commits).length

    const handleKeyDown = event => {
      const movement = keyboardMovement(event)
      if (movement != null) {
        const navigate = this.state.tabIndex + movement
        if (navigate >= 0 && navigate < maxTabIndex) {
          this.setState({
            tabIndex: this.state.tabIndex + movement
          })
        } else {
          // handle prev or next
          keyboardActionMovement(movement)
        }
      }
    }

    const handleClick = index => {
      this.setState({ tabIndex: index })
    }

    let fieldTabIndex = 0

    return (
      <div
        className='report-form report-form--batch'
        onKeyDown={e => {
          handleKeyDown(e)
        }}
      >
        <div className='report-form__main-fields'>
          {Object.keys(batchesInReport).map((batchId) => {
            const index = fieldTabIndex
            const amount = get(batchesInReport[batchId], 'fields.field:standard-physical-count.amount')
            const alert = alerts.find(a => a.target.productId === batchId)
            const batchNumber = batchNumberFromId(batchId)
            const masterData = masterDataBatches[batchId]
            const masterExpiry = masterData ? masterData.expiry : undefined
            const batchExpiry = masterExpiry ? `Expires ${formatDate(masterExpiry, 'expiry')}` : undefined
            const batchManufacturer = masterData ? masterData.manufacturerName : undefined
            const subLabel = [batchManufacturer, batchExpiry].filter(Boolean).join(' | ')

            fieldTabIndex += 1
            const fieldProps = {
              id: batchId,
              label: batchNumber,
              subLabel: subLabel,
              key: batchId,
              value: amount,
              onProductUpdate: onProductUpdate,
              autoFocus: tabIndex === index,
              handleClick: () => handleClick(index),
              type: 'batch',
              batchSize
            }
            if (alert) {
              return (
                <AlertReportFieldContainer
                  alert={alert}
                  {...fieldProps}
                />
              )
            }
            return <ReportField {...fieldProps} /> // eslint-disable-line react/jsx-key
          })}

        </div>
        <ReportEntryTotal total={countedTotal} />
        <Commits
          productId={productId}
          commits={commits}
          currentTabIndex={tabIndex}
          startTabIndex={fieldTabIndex}
          onProductUpdate={onProductUpdate}
          setTabIndex={handleClick}
          total={countedTotal}
        />
      </div>
    )
  }
}

StockCountFormBatched.propTypes = {
  batchesInReport: PropTypes.object.isRequired,
  masterDataBatches: PropTypes.object.isRequired,
  countedTotal: PropTypes.number.isRequired,
  commits: PropTypes.object,
  onProductUpdate: PropTypes.func.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default StockCountFormBatched
