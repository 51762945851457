const {wrapEntityApi} = require('../utils/wrap-api')
// REFACTOR: let's rename data-access = data-adapters across the board.
const { rawMethods, StockPouchDBAdapter, StockRestAdapter, StockPGAdapter } = require('./data-access')
const getWarehouseBatchData = require('../stock-warehouse-get-batch-data')
const nhlmisRestSubmit = require('./data-access/nhlmis-rest-submit')
const nhlmisRestGet = require('./data-access/nhlmis-rest-get')

const TransactionApi = require('./transactions')

// Not an EntityApi, stock is a cross entity concept
class StockApi {
  constructor (state, mainApi, restAdapter, logger, pgConnection, agaveAdapter) {
    // REFACTOR: this could be in EntityApi and subclasses use super(MyPouchDBAdaper, StockRestAdapter)
    let adapter
    const { user } = state
    adapter = new StockPouchDBAdapter(user, restAdapter)
    this.adapter = adapter
    this.mainApi = mainApi
    this.agaveAdapter = agaveAdapter
    // TODO: remove this when all raw methods have been ported
    const apiMethods = wrapEntityApi(rawMethods, state)
    Object.assign(this, apiMethods)

    this.restAdapter = new StockRestAdapter(restAdapter)
    if (pgConnection) {
      const username = user.name
      this.pgAdapter = new StockPGAdapter({ pgConnection, username, logger })
    }

    this.transactions = new TransactionApi(state, mainApi, logger)
  }

  getWarehouseProductBatchData (funderId, programId) {
    return getWarehouseBatchData(this.mainApi.state, {
      batchApi: this.mainApi.batch,
      funderApi: this.mainApi.funders,
      configurationApi: this.mainApi.configuration,
      locationApi: this.mainApi.location,
      funderId,
      programId
    })
  }

  getWarehouseStockImportFile (funder, program) {
    return this.agaveAdapter.getBlob('stock/warehouse-import-csv', {
      funder,
      program
    })
  }

  uploadWarehouseStock (formData) {
    return this.agaveAdapter.create('stock/warehouse-upload', formData)
  }

  getWarehouseStockData (locationId, serviceId) {
    return this.agaveAdapter.getBlob('stock/warehouse-stock', {
      locationId,
      serviceId
    })
  }

  nhlmisRestGet ({ locationId, serviceId }) {
    return nhlmisRestGet(this.mainApi, { locationId, serviceId })
  }
}

Object.assign(StockApi.prototype, {nhlmisRestSubmit})

// TODO: remove this when all raw methods have been ported
module.exports = rawMethods
module.exports.StockApi = StockApi
