import React from 'react'
import { Link } from 'react-router-dom'

import { DownloadButton, ImportButton, Page, Table, Text, Loading } from '@fielded/shared-ui'

import withConfig from '../../../../../van-shared/hoc/withConfig'
import { usePageLoading } from '../../../../../van-shared/hooks/pageLoading'
import { getNavigationItem } from '../../../../../common/utils/navigationInfo'
import { COLUMNS, ROWS } from './utils'
import ExportImportCard from '../../../../../van-shared/components/ExportImportCard/ExportImportCard'

const ACCEPTED_FILE_TYPES = [
  'text/csv'
]

const PSMShipmentExportImport = ({
  loading,
  onImport,
  onError,
  config
}) => {
  const { isPageLoading } = usePageLoading()

  const rows = ROWS[config.name] || ROWS['default']

  return (
    <Page>
      <Page.ComposableHeader>
        <Page.ComposableHeader.Breadcrumb
          items={[getNavigationItem({ item: 'shipments', config, includePath: false })]}
        />
        <Page.ComposableHeader.Content spread>
          <Page.ComposableHeader.Title title='Export & Import' />
        </Page.ComposableHeader.Content>
      </Page.ComposableHeader>
      {isPageLoading ? (
        <Loading />
      ) : (
        <Page.Panel>
          <ExportImportCard
            title='Create shipments'
            description='Upload a CSV file to create your shipments'
            button={
              <ImportButton
                buttonText='Upload shipments'
                accept={ACCEPTED_FILE_TYPES}
                loading={loading}
                onImport={onImport}
                onError={onError}
              />
            }
            extraButton={
              <DownloadButton
                component={Link}
                target='_blank'
                download
                to='/psm/document-examples/shipment-template.csv'
                size='regular'
              >
                Download shipments template
              </DownloadButton>
            }
          >
            <Text>Here is an example how the excel file should look:</Text>
            <Table
              entries={rows}
              columns={COLUMNS}
              headerTextStyle='lowercase'
            />
            <Text><b>destination warehouse code</b> column contains the destination warehouse code.</Text>
            <Text><b>product</b> column contains the product code</Text>
            <Text><b>quantity</b> column contains the quantity to be delivered</Text>
            <Text><b>origin warehouse code</b> column contains the origin warehouse code</Text>
            <Text><b>date</b> column contains the date of the shipment (this is optional)</Text>
            <Text><b>lot no</b> column contains the lot number (this is optional)</Text>
            <Text><b>manufacturer</b> column contains the product manufacturer (this is optional)</Text>
            <Text><b>expiry</b> column contains the batch expiry date (this is optional)</Text>
            <Text><b>external Id</b> column contains the external shipment id</Text>
          </ExportImportCard>
        </Page.Panel>
      )}
    </Page>
  )
}

export default withConfig(PSMShipmentExportImport)
