import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import PriceDisplay from '../PriceDisplay'
import Text from '../Text'

const TotalAmount = (props) => {
  const {
    qtyOnly,
    label,
    value,
    roundUp,
    originalValue,
    countryId,
    isTight,
    withPagePadding,
    asTableRow,
    totalColumns,
    footnote,
    footnoteOnRight,
    isDimmed,
    isSecondary,
    isProvisional,
    isWithWarning,
    isPromo,
    className,
    children
  } = props

  const classList = classnames(
    'vs-total-amount',
    {'vs-total-amount--tight': isTight},
    {'vs-total-amount--dynamic-padding': withPagePadding},
    {'vs-total-amount--table': asTableRow},
    {'vs-total-amount--dimmed': isDimmed},
    {'vs-total-amount--secondary': isSecondary},
    {'vs-total-amount--provisional': isProvisional},
    {'vs-total-amount--warning': isWithWarning},
    className
  )

  const basicPriceDisplay = (
    <PriceDisplay
      bold
      value={value}
      country={countryId}
      currencySymbol
      suffix={footnote ? '*' : ''}
      inColor={isPromo}
      prefixedValue={isPromo ? '- ' : ''}
      roundUp={roundUp}
    />
  )

  let valueToDisplay = basicPriceDisplay

  if (originalValue) {
    valueToDisplay = (
      <span>
        <PriceDisplay
          value={originalValue}
          country={countryId}
          currencySymbol
          invalid
          roundUp={roundUp}
        />
        {basicPriceDisplay}
      </span>
    )
  }

  if (isDimmed) {
    valueToDisplay = (<Text bold>-</Text>)
  }

  if (qtyOnly) {
    valueToDisplay = (<Text bold>{value}</Text>)
  }

  if (asTableRow) {
    return (
      <Fragment>
        <tr className={classList}>
          <td colSpan={totalColumns - 1}>
            <Text bold>
              {label}
            </Text>
          </td>
          <td>
            {valueToDisplay}
          </td>
        </tr>
        {children}
        {footnote && (
          <tr
            className={classnames(
              'vs-total-amount__footnote',
              {'vs-total-amount__footnote--on-right': footnoteOnRight}
            )}
          >
            <td colSpan={totalColumns}>
              * {footnote}
            </td>
          </tr>
        )}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className={classList}>
        <Text bold>
          {label}
        </Text>
        {valueToDisplay}
      </div>
      {children}
      {footnote && (
        <div
          className={classnames(
            'vs-total-amount__footnote',
            {'vs-total-amount__footnote--on-right': footnoteOnRight}
          )}
        >
          * {footnote}
        </div>
      )}
    </Fragment>
  )
}

TotalAmount.propTypes = {
  /**
   * A text explaining the number
   */
  label: PropTypes.node,
  /**
   * A value to be shown in the price display in the right
   */
  value: PropTypes.number,
  /**
   * A country marker to make it possible to add the currency
   */
  countryId: PropTypes.string,
  /**
   * A text showing under the total amount bar in case you need to have some additional info. Will show asterisk on the Price Display
   */
  footnote: PropTypes.string,
  /**
   * If the total row should be shorter in height
   */
  isTight: PropTypes.bool,
  /**
   * If it should show greyed out and with no total
   */
  isDimmed: PropTypes.bool,
  /**
   * Without colorful background
   */
  isSecondary: PropTypes.bool,
  /**
   * Reserved for values that are not confirmed
   */
  isProvisional: PropTypes.bool,
  /**
   * Reserved for showing when value is overdue
   */
  isWithWarning: PropTypes.bool,
  /**
   * Reserved for values that are credited to user (promo)
   */
  isPromo: PropTypes.bool,
  /**
   * If the total amount should be a part of a table
   */
  asTableRow: PropTypes.bool,
  /**
   * Number of columns in enclosing table (if used with asTableRow)
   */
  totalColumns: PropTypes.number,
  /**
   * If the component should use page side padding that is dynamic
   */
  withPagePadding: PropTypes.bool,
  /**
   * Optional className
   */
  className: PropTypes.string,
  /**
   * Optional children (if you REALLY need some item between total amount bar and footnote)
   */
  children: PropTypes.node
}

TotalAmount.defaultProps = {
  label: 'Total',
  value: 0,
  countryId: undefined,
  footnote: undefined,
  asTableRow: false,
  totalColumns: 1,
  isTight: false,
  isWithWarning: false
}

export default TotalAmount
