module.exports = createSnapshot

const pick = require('lodash/pick')
const omitBy = require('lodash/omitBy')
const isNil = require('lodash/isNil')

const getSnapshotId = require('./tools/get-snapshot-id')
const docToVanRecord = require('./tools/doc-to-van-record')
const { getShipmentType } = require('./tools/shipment-types')
const addComment = require('./shipment-add-comment')
const {isInvalidSnapshot} = require('./validate/snapshot')
const { DIRECT_ORDER_TYPES } = require('../allocation/config')
const PLANNING_TYPES = require('./tools/planning-types')
const { ORDER_SHIPMENT_SHARED_PROPS } = require('../order/constants')

const OPTIONS_TO_CLONE = [
  'orderId', 'shouldReturnProducts', 'programId', 'isGpsAuthenticated', 'gpsValidationFailComment',
  'isAutomaticReturnShipment', // we need this flag to avoid updating ledger
  'externalShipmentId', // These are for shipments from external WMS's
  ...ORDER_SHIPMENT_SHARED_PROPS
]

const cleanCounts = ({counts, status, removePaymentType, doCheckSent = true}) => {
  return Object.keys(counts).reduce((clean, batchId) => {
    if ((status === 'sent' || status === 'received') && !counts[batchId].checked && doCheckSent) {
      return clean
    }

    clean[batchId] = {
      quantity: counts[batchId].quantity,
      paymentType: counts[batchId].paymentType || DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL
    }

    if (counts[batchId].prescription) {
      clean[batchId].prescription = counts[batchId].prescription
    }

    if (removePaymentType) {
      delete clean[batchId]['paymentType']
    }

    return clean
  }, {})
}

// get options that we just want to get into the shipment
// having same name and value. Only if they're defined
const extractOptionsToClone = (options) => {
  const optionsToCloneNames = [...OPTIONS_TO_CLONE]

  const { status, parentDocId } = options

  if (parentDocId && !(['new', 'pre-advice'].includes(status))) {
    optionsToCloneNames.push('parentDocId')
  }

  const allProps = pick(options, optionsToCloneNames)
  return omitBy(allProps, isNil) // return defined picked props
}

async function createSnapshot (state, options) {
  const {
    status,
    counts,
    shipmentTypeId = 'routine',
    planningType = PLANNING_TYPES.DEFAULT,
    overrideCreatedAt,
    dryRun = false,
    removePaymentType,
    doCheckSent,
    programId,
    funderId,
    routeId
  } = options

  if (!getShipmentType(shipmentTypeId)) {
    throw new Error(`${shipmentTypeId} is not a valid shipment type`)
  }

  const optionsToClone = extractOptionsToClone(options)

  if (overrideCreatedAt) {
    optionsToClone.orderCreatedDate = overrideCreatedAt
  }

  const id = getSnapshotId(state, options)
  const doc = {
    _id: id,
    type: 'snapshot',
    version: '1.0.0',
    createdAt: overrideCreatedAt || new Date(),
    counts: dryRun ? counts : cleanCounts({counts, status, removePaymentType, doCheckSent}),
    shipmentTypeId,
    planningType,
    createdBy: state.user.name,
    ...optionsToClone
  }

  if (programId) {
    doc['programId'] = programId
  }

  if (funderId) {
    doc['funderId'] = funderId
  }

  if (routeId) {
    doc['routeId'] = routeId
  }

  const validationErrors = isInvalidSnapshot(doc)
  if (validationErrors) throw new Error(validationErrors)

  if (dryRun) return doc

  const { rev } = await state.dal.shipment.write(state, doc)
  doc._rev = rev

  const commentOptions = pick(options, 'comment', 'signature', 'name')
  const { comment, signature, name } = commentOptions

  if (comment || signature || name) {
    await addComment(state, id, comment, signature, name, doc.orderId)
    Object.assign(doc, commentOptions)
  }

  return docToVanRecord(doc, state.user.location.id)
}
