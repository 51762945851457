module.exports = newBatch

const listManufacturers = require('./batch-list-manufacturers')
const batchId = require('../../utils/batch-id')
const { isValidJSONDate } = require('../../utils/validation')
const { sanitiseBatchNo, sanitiseManufacturer } = require('../tools/index')
const { get: getProduct } = require('./../../product')

async function newBatch (state, batchData, params = {}) {
  let {
    manufacturer,
    productId,
    batchNo,
    expiry
  } = batchData

  const {
    resolveAlias = null,
    allowUpdates = false,
    updatedAlias = null,
    validateManufacturer = true
  } = params

  const isManufacturerValid = !validateManufacturer || manufacturer

  if (!(isManufacturerValid && productId && batchNo && expiry)) {
    return Promise.reject(new Error('missing mandatory keys on batch object: manufacturer, productId, batchNo, expiry'))
  }

  if (!isValidJSONDate(expiry)) {
    throw new Error('Expiry is not a valid json date')
  }

  if (resolveAlias) {
    const product = await getProduct(state, productId, {resolveAlias})
    productId = product._id
  }

  let manufacturerDoc
  if (validateManufacturer) {
    const manufacturerList = await listManufacturers(state, 'all')
    manufacturerDoc = manufacturerList.find(manufacturerItem => manufacturerItem.id === manufacturer)
    if (!manufacturerDoc) {
      throw new Error(`manufacturer unknown: ${manufacturer}`)
    }
  } else {
    manufacturerDoc = { name: manufacturer }
  }

  const now = new Date().toISOString()
  const shouldSanitizeManufacturer = validateManufacturer || batchData.manufacturer
  const sanitizedManufacturer = shouldSanitizeManufacturer ? sanitiseManufacturer(batchData.manufacturer) : batchData.manufacturer
  batchData.batchNo = sanitiseBatchNo(batchData.batchNo)
  const batch = {
    _id: batchId(Object.assign({}, batchData, {productId, manufacturer: sanitizedManufacturer})),
    type: 'batch',
    version: '1.0.0',
    createdAt: now,
    updatedAt: now,
    createdBy: (state.user && state.user.name) || 'FS backend service',
    manufacturer: manufacturerDoc.name,
    productId,
    expiry
  }

  if (batchData.batchAlias) {
    batch.alias = Object.assign({}, batchData.batchAlias)
  }

  if (resolveAlias) {
    const [aliasName] = Object.keys(resolveAlias)
    Object.assign(batch, {
      alias: {
        [aliasName]: batchData.productId.replace(/^product:/, '')
      }
    })
  }

  if (allowUpdates) {
    try {
      const existingBatch = await state.masterDataDB.get(batch._id)
      batch._rev = existingBatch._rev
      batch.alias = Object.assign({}, updatedAlias)
    } catch (err) {
      if (err.status !== 404) {
        throw err
      }
      // pass
    }
  }

  if (params.dryRun) {
    return batch
  }

  try {
    await state.masterDataDB.put(batch)
    return batch
  } catch (err) {
    if (err.status === 409) {
      return batch
    }
    throw err
  }
}
