import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import classnames from 'classnames'
import { stringify, parse } from 'query-string'

import { DeemphasizedLink, FilterInput, Page } from '@fielded/shared-ui'
import { filterByProp } from '@fielded/shared-ui/src/components/FilterInput'

import withConfig from '../../../van-shared/hoc/withConfig'
import { hasFeature } from '../../../van-shared/utils/features'

import { headerLocationFor } from '../common/utils'

import { selectPickList } from '../../../root/reducers/shipments/pick-list'
import withShipment from '../common/WithShipmentWrapper'
import withMasterData from '../common/withMasterData'
import shipmentsBreadcrumb from '../common/shipmentsBreadcrumb'
import ProductItem from '../components/ProductItem'
import ProductsSelectedButton from '../planning/ProductsSelectedButton'

const DeleteProductsContainer = ({
  config,
  shipment,
  history,
  match,
  location
}) => {
  const pickedProducts = useSelector(selectPickList)

  const search = get(location, 'search')
  const redirectLink = get(location, 'state.redirectLink')
  const snapshotId = get(match, 'params.snapshotId')
  const { products = [] } = parse(search, {arrayFormat: 'bracket'})
  const preselected = products

  const [selectedProducts, setSelectedProducts] = useState(preselected)
  const [filterTerm, setFilterTerm] = useState()

  const onSetFilterTerm = (filterTerm) => {
    setFilterTerm(filterTerm)
  }

  const onToggleProduct = (id) => {
    let newSelectedProducts = [...selectedProducts]

    if (newSelectedProducts.includes(id)) {
      newSelectedProducts = newSelectedProducts.filter(productId => productId !== id)
    } else {
      newSelectedProducts.push(id)
    }
    setSelectedProducts(newSelectedProducts)
  }

  const onClickNext = () => {
    const productsQuery = stringify({products: selectedProducts}, {arrayFormat: 'bracket'})
    history.push({
      pathname: `/shipments/pick-list/${snapshotId}/shipment-options/remove-products/confirm`,
      search: `?${productsQuery}`,
      state: { redirectLink }
    })
  }

  const productOptions = Object.keys(pickedProducts).map(id => {
    const product = pickedProducts[id]
    return {
      ...product
    }
  })
  const showProductCode = config && hasFeature(config, 'features.shipments:addProduct:showProductCode')
  const filteredProducts = filterByProp(productOptions, filterTerm, 'name')
  const destination = headerLocationFor(shipment, config)
  const shipmentLabel = shipment.statusType === 'distribution' ? 'Distribution to' : 'Arrival from'
  const link = redirectLink || `/shipments/pick-list/${snapshotId}`

  return (
    <Page>
      <Page.Header
        title='Remove products'
        breadcrumbItems={[
          shipmentsBreadcrumb,
          {
            title: `${shipmentLabel} ${destination}`,
            path: link
          }
        ]}
      />
      <Page.Intro>
        <FilterInput
          onValueChange={(value) => onSetFilterTerm(value)}
          placeholder='Filter by product name'
          value={filterTerm}
        />
      </Page.Intro>
      {filteredProducts.length > 0 ? (
        <Page.Panel spread withMargin={false}>
          <section className='product-bulk-selection'>
            <ul className='selectable-list'>
              {filteredProducts
                .map(product =>
                  <ProductItem
                    key={product.id}
                    product={pickedProducts[product.id]}
                    selected={selectedProducts.includes(product.id)}
                    showTotal
                    selectForDeletion
                    showProductCode={showProductCode}
                    onToggle={() => onToggleProduct(product.id)}
                  />
                )}
            </ul>
          </section>
        </Page.Panel>
      ) : (
        <Page.Panel>
          0 products found
        </Page.Panel>
      )}
      <Page.Footer
        className={classnames(
          'product-bulk-selection__footer',
          {'product-bulk-selection__footer--visible': selectedProducts.length > 0}
        )}
      >
        <ProductsSelectedButton
          data-qa='shipment-delete-product-save'
          selectedProducts={selectedProducts.length}
          onClickNext={onClickNext}
        />
        <DeemphasizedLink
          alignOpposite
          style={{ marginRight: 'auto' }}
          onClick={() => history.push(link)}
        >
          Cancel and go back
        </DeemphasizedLink>
      </Page.Footer>
    </Page>
  )
}

export default withConfig(
  withMasterData(
    withShipment(DeleteProductsContainer)
  )
)
