const get = require('lodash/get')
const { update } = require('./save')
const { listUsersByRoutes, get: getUser } = require('../read')

async function assignRoute (state, {driver, routeId, defaultRole}) {
  const driversWithTheRoute = await listUsersByRoutes(state, {routes: [routeId]})
  // We need this step to make sure we have the latest driver document
  const driverToAssign = await getUser(state, driver.name)
  const previousDriver = get(driversWithTheRoute, '0')
  await Promise.all([
    update(
      state,
      {
        ...driverToAssign,
        routes: [routeId]
      },
      { defaultRole }
    ),
    previousDriver
      ? update(
        state,
        {
          ...previousDriver,
          routes: []
        },
        { defaultRole }
      )
      : Promise.resolve()
  ])
}
exports.assignRoute = assignRoute
